import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { Subscription, SubscriptionsResponse } from '../models/Subscriptions'

const apiURL =  APIEndpoint(EndpointType.Subscriptions);

export const useSubscriptions = (customerID: string, refreshKey: number) => {
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
    const [subscriptionsError, setSubscriptionsError] = useState("")
    const [subscriptionsLoading, setSubscriptionsLoading] = useState(false)
    
    useEffect(() => {
        if (customerID == "") {
            return
        }

        setSubscriptionsLoading(true)
        setSubscriptionsError("")
        httpGet(apiURL + "/customer/" + customerID)
            .then((data) => {
                const subscriptionsResponse = data as SubscriptionsResponse;
                if (subscriptionsResponse.status === 'error') {
                    setSubscriptionsError(subscriptionsResponse.errorMessage)
                    setSubscriptions([])
                } else {
                    if (subscriptionsResponse.subscriptions != undefined) {
                        setSubscriptions(subscriptionsResponse.subscriptions)
                    } else {
                        setSubscriptions([])
                    }
                }
            })
            .catch((error) => setSubscriptionsError(error))
            .finally(() => setSubscriptionsLoading(false))

    }, [customerID, refreshKey]);

    return { subscriptions, subscriptionsLoading, subscriptionsError };
};