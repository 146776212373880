import * as React from 'react';
import Typography from '@mui/material/Typography';

// interface Props {
  
// }

export default function PropertyDetails() {
  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      Property Details
    </Typography>
  );
}
