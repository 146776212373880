import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import Copyright from "../components/CopyRight";
import * as React from "react";
import Title from '../components/Title';
import LoadingSpinner from "../components/LoadingSpinner";
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import ErrorFallBack from "../components/ErrorFallBack";
import { DataGridPremium, GridToolbar, GridColDef, GridRenderCellParams, GridAggregationModel } from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import { useZipCodeCounts } from "../hooks/useZipCodeCounts";
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";
import { FormControlLabel, Switch} from "@mui/material";
import { useProductsCounts } from "../hooks/useProductsCounts";
import { ZipCodeCount } from "../models/ZipCodeCountsResponse";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspotte

const ZipCodeCounts = () => {
  const [zipCodes, setZipCodes] = useState("");
  const [zipCodeValue, setZipCodeValue] = useState("");
  const [city, setCity] = useState("");
  const [county, setCounty] = useState("");
  const [state, setState] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [countyValue, setCountyValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const { zipCodeCounts, zipCodeLoading, zipCodeError } = useZipCodeCounts(zipCodes, city, county, state, true, 0, "");
  const navigate = useNavigate();
  const { products, productsLoading } = useProductsCounts(1)
  const [filterDNC, setFilterDNC] = useState(false);
  const [filterPhonesEmail, setFilterPhonesEmail] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [listCounts, setListCounts] = React.useState<any[]>([]);
  const [aggregationModel, setAggregationModel] = React.useState<GridAggregationModel>({});
  
  useEffect(() => {
    // Log Pageview with Hubspot
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', '/zipcodecounts']);
    _hsq.push(['trackPageView']);
  }, []);

  useEffect(() => {
    assembleTableData()
  }, [filterDNC, filterPhonesEmail]);

  const zipCodeChanged = (event: React.ChangeEvent<{ value: string }>) => {
    setZipCodeValue(event.target.value)
    setCityValue("")
    setCountyValue("")
    setStateValue("")
  }

  const cityChanged = (event: React.ChangeEvent<{ value: string }>) => {
    setCityValue(event.target.value)
    setCountyValue("")
    setZipCodeValue("")
  }

  const countyChanged = (event: React.ChangeEvent<{ value: string }>) => {
    setCountyValue(event.target.value)
    setCityValue("")
    setZipCodeValue("")
  }

  const stateChanged = (event: SelectChangeEvent) => {
    setStateValue(event.target.value)
    setZipCodeValue("")
  }

  const SearchSubmit = () => {
    setCity(cityValue);
    setZipCodes(zipCodeValue);
    setCounty(countyValue)
    setState(stateValue)
  }

  const navigateToZip = (zipCode: string) => {
    navigate("/leadsstore?zip=" + zipCode)
  }

  const dncFilterChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilterDNC(checked)
  }

  const phonesEmailFilterChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilterPhonesEmail(checked)
  }

  useEffect(() => {
    assembleTableData()
  }, [zipCodeCounts]);

  useEffect(() => {
    initAggregationModel()
  }, [products])

  function getColumns() {
    const cols: GridColDef[] = [
      { field: 'zipCode', headerName: 'Zip Code', width: 100, headerClassName: 'MuiDataGrid-columnHeaders', renderCell: (params: GridRenderCellParams) => {
        return         <Link
            component="button"
            variant="body2"
            onClick={() => {
              navigateToZip(params.value);
            }}
            >
            {params.value}
          </Link>
      } },
      { field: 'cityCap', headerName: 'City', width: 125},
      { field: 'countyDisplayName', headerName: 'County', width: 100 },
      { field: 'state', headerName: 'State', width: 60 }
    ]

    products.forEach(function (prod) {
      const name = prod.name.replaceAll(" Leads", "")
      cols.push({
        field: name.replaceAll("-", "").replaceAll(" ", ""),
        headerName: name,
        type: 'number'
      })
    })

    return cols
  }

  function assembleTableData() {
    const newZipCounts: unknown[] = [];
    zipCodeCounts.forEach(function (zipCount) {
      const obj: {[k: string]: unknown} = {};
      // Base Fields
      obj.zipCode = zipCount.zipCode,
      obj.cityCap = zipCount.cityCap,
      obj.countyDisplayName = zipCount.countyDisplayName,
      obj.state = zipCount.state

      // Dynamic Count Fields
      products.forEach(function (prod) {
        let lName = prod.name.replaceAll(" Leads", "")
        lName = lName.replaceAll(" ", "")
        lName = lName.replaceAll("-", "")
        obj[lName] = getListCount(zipCount, prod.name)
      })

      newZipCounts.push(obj)
    }) 

    setListCounts(newZipCounts)
  }

  function getListCount(zipCount: ZipCodeCount, listName: string) :number {
    const audienceID = getAudienceID(listName)

    if (zipCount.listCounts == null) {
      return 0
    }
    
    for (const listCount of zipCount.listCounts) {
      if (listCount.audienceID == audienceID) {
        return listCount.count
      }
    }

    return 0
  }

  function getAudienceID(listName: string) :number {
   for (const product of products) {
      if (product.name == listName) {
        if (!filterDNC && !filterPhonesEmail) {
          return product.base
        } else if (filterDNC && !filterPhonesEmail) {
          return product.noDNC
        } else if (!filterDNC && filterPhonesEmail) {
          return product.phonesEmails
        } else {
          return product.phonesEmailsNoDNC
        }
      }
    }

    return 0
  }

  function initAggregationModel() {
    const gridAggregationModel: GridAggregationModel = {
    }

    products.forEach(function (prod) {
      let name = prod.name.replaceAll(" Leads", "")
      name = name.replaceAll("-", "").replaceAll(" ", "")
      name = name.replaceAll(" ", "")

      gridAggregationModel[name] = 'sum'
    })

    setAggregationModel(gridAggregationModel)
  }

  const generateExportFileName = () => {
    if (city != "" && state != "") {
      return city + "_" + state + getFilenameFeature()
    }

    if (county != "" && state != "") {
      return county + "_" + state + getFilenameFeature()
    }

    if (zipCodes != "") {
      let cleanZips = zipCodes.replaceAll(",", "_")
      cleanZips = cleanZips.replaceAll(" ", "_")
      cleanZips = cleanZips.replaceAll("/", "_")
      cleanZips = cleanZips.replaceAll("-", "_")

      return cleanZips + getFilenameFeature()
    }

    return "ListCounts" + getFilenameFeature()
  }

  function getFilenameFeature() {
    if (!filterDNC && !filterPhonesEmail) {
      return ""
    } else if (filterDNC && !filterPhonesEmail) {
      return "_NoDNC"
    } else if (!filterDNC && filterPhonesEmail) {
      return "_AllEmails"
    } else {
      return "_AllEmailsNoDNC"
    }
  }
  
  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Title>Zip Code List Counts</Title>
            </Stack>
            <Grid item xs={12}>
              <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}>
              </Box>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }} >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                  item
                  xs={12}
                >
                    <Stack direction="row" spacing={2} sx={{marginBottom:1}}>
                        <TextField id="outlined-basic" value={zipCodeValue} label="Zip Codes" variant="outlined" size="small" onChange={zipCodeChanged} sx={{width:300}} />
                        <TextField id="outlined-basic" value={cityValue} label="City" variant="outlined" size="small" onChange={cityChanged} />
                        <TextField id="outlined-basic" value={countyValue} label="County" variant="outlined" size="small" onChange={countyChanged} />
                        <FormControl sx={{width: 100}}>
                              <InputLabel id="lead-type-select-label" sx={{marginTop:0}}>State</InputLabel>
                          <Select value={stateValue} id="outlined-basic" label="State" variant="outlined" size="small" onChange={stateChanged} >
                            <MenuItem value={"AK"}>AK</MenuItem>
                            <MenuItem value={"AL"}>AL</MenuItem>
                            <MenuItem value={"AR"}>AR</MenuItem>
                            <MenuItem value={"AZ"}>AZ</MenuItem>
                            <MenuItem value={"CA"}>CA</MenuItem>
                            <MenuItem value={"CO"}>CO</MenuItem>
                            <MenuItem value={"CT"}>CT</MenuItem>
                            <MenuItem value={"DC"}>DC</MenuItem>
                            <MenuItem value={"DE"}>DE</MenuItem>
                            <MenuItem value={"FL"}>FL</MenuItem>
                            <MenuItem value={"GA"}>GA</MenuItem>
                            <MenuItem value={"HI"}>HI</MenuItem>
                            <MenuItem value={"IA"}>IA</MenuItem>
                            <MenuItem value={"ID"}>ID</MenuItem>
                            <MenuItem value={"IL"}>IL</MenuItem>
                            <MenuItem value={"IN"}>IN</MenuItem>
                            <MenuItem value={"KS"}>KS</MenuItem>
                            <MenuItem value={"KY"}>KY</MenuItem>
                            <MenuItem value={"LA"}>LA</MenuItem>
                            <MenuItem value={"MA"}>MA</MenuItem>
                            <MenuItem value={"MD"}>MD</MenuItem>
                            <MenuItem value={"ME"}>ME</MenuItem>
                            <MenuItem value={"MI"}>MI</MenuItem>
                            <MenuItem value={"MN"}>MN</MenuItem>
                            <MenuItem value={"MO"}>MO</MenuItem>
                            <MenuItem value={"MS"}>MS</MenuItem>
                            <MenuItem value={"MT"}>MT</MenuItem>
                            <MenuItem value={"NC"}>NC</MenuItem>
                            <MenuItem value={"ND"}>ND</MenuItem>
                            <MenuItem value={"NE"}>NE</MenuItem>
                            <MenuItem value={"NH"}>NH</MenuItem>
                            <MenuItem value={"NJ"}>NJ</MenuItem>
                            <MenuItem value={"NM"}>NM</MenuItem>
                            <MenuItem value={"NV"}>NV</MenuItem>
                            <MenuItem value={"NY"}>NY</MenuItem>
                            <MenuItem value={"OH"}>OH</MenuItem>
                            <MenuItem value={"OK"}>OK</MenuItem>
                            <MenuItem value={"OR"}>OR</MenuItem>
                            <MenuItem value={"PA"}>PA</MenuItem>
                            <MenuItem value={"RI"}>RI</MenuItem>
                            <MenuItem value={"SC"}>SC</MenuItem>
                            <MenuItem value={"SD"}>SD</MenuItem>
                            <MenuItem value={"TN"}>TN</MenuItem>
                            <MenuItem value={"TX"}>TX</MenuItem>
                            <MenuItem value={"UT"}>UT</MenuItem>
                            <MenuItem value={"VA"}>VA</MenuItem>
                            <MenuItem value={"VT"}>VT</MenuItem>
                            <MenuItem value={"WA"}>WA</MenuItem>
                            <MenuItem value={"WI"}>WI</MenuItem>
                            <MenuItem value={"WV"}>WV</MenuItem>
                            <MenuItem value={"WY"}>WY</MenuItem>
                          </Select>
                        </FormControl>
                        <Button variant="contained" color="primary" sx={{ width: 150, marginTop: "8px" }} onClick={SearchSubmit}>Search</Button>
                    </Stack>
                </Grid>
                <Stack direction='row'>
                        <FormControlLabel control={<Switch onChange={dncFilterChanged} />} label="Remove DNC" />
                        <FormControlLabel control={<Switch onChange={phonesEmailFilterChanged} />} label="Require Emails" />
                </Stack>
                <React.Fragment>
                  <Box sx={{}}>
                    {zipCodeLoading && productsLoading
                        ? <LoadingSpinner title="Loading data..." textColor="#0352fc" />
                        : zipCodeError
                        ? <ErrorFallBack message={zipCodeError}/>
                        : 
                        <DataGridPremium
                          getRowId={(row) => row.zipCode}
                          rows={listCounts}
                          columns={getColumns()}
                          loading={zipCodeLoading}
                          aggregationModel={aggregationModel}
                          initialState={{
                            sorting: {
                              sortModel: [{ field: 'zipCode', sort: 'asc' }],
                            }
                          }}
                          slots={{ toolbar: GridToolbar }}
                          slotProps={{ toolbar: { 
                              csvOptions: { fileName: generateExportFileName() }, 
                              excelOptions: { fileName: generateExportFileName() },
                              printOptions: { fileName: generateExportFileName() }
                          } }}
                        />
                    }
                  </Box>
                </React.Fragment>
              </Paper>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </Container>
  );
};

export default ZipCodeCounts;