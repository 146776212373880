import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Button, Grid } from '@mui/material';
import queryString from "query-string"
import { APIEndpoint, EndpointType, httpPutNoAuth } from '../utils/apiService';
import { OptOutResponse } from '../models/OptOut';

export default function OptOut() {
    const [userID, setUserID] = React.useState<string>("");
    const [unsubscribed, setUnsubscribed] = React.useState<boolean>(false);

    React.useEffect(() => {
        const queryParams = queryString.parse(window.location.search);

        if (queryParams.id) {
            setUserID(queryParams.id as string);
        }
        console.log(queryParams);
    }, []);

    const handleUnsubscribe = () => {
        const apiURL = APIEndpoint(EndpointType.OptOut) + `?id=${userID}`;
        httpPutNoAuth(apiURL, JSON.stringify(""))
            .then((data) => {
                const checkoutResponse = data as OptOutResponse;

                if (checkoutResponse.status == 'error') {
                    console.log("Error opting-out: " + checkoutResponse.errorMessage)
                    alert("Error opting-out: " + checkoutResponse.errorMessage + ". Please try again or contact info@theshare.group for assistance.")
                } else {
                    setUnsubscribed(true);
                }
            })
            .catch((error) => {
                console.log("opt-out: " + error.message)
            });
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
        >
            <Grid item xs={3}>
                <Typography variant="h4" color="primary" gutterBottom>We are sorry to see go</Typography>

                {unsubscribed
                    ? <Typography component="p" variant="body1" color="primary" gutterBottom>You have been unsubscribed</Typography>
                    : <Button variant="contained" color="primary" onClick={handleUnsubscribe}>Unsubscribe</Button>
                }
            </Grid>


        </Grid>
    );
}
