import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { DataEnhancement, DataEnhancementResponse } from '../models/DataEnhancement';

const apiURL =  APIEndpoint(EndpointType.DataEnhancement);

export const useDataEnhancement = (customerID: string, refreshKey: number) => {
    const [dataEnhancements, setDataEnhancements] = useState<DataEnhancement[]>([])
    const [dataEnhancementsError, setDataEnhancementsError] = useState("")
    const [dataEnhancementsLoading, setDataEnhancementsLoading] = useState(false)
    
    useEffect(() => {
        if (!customerID || customerID == "" || refreshKey == 0) {
            return;
        }

        setDataEnhancements([])
        setDataEnhancementsLoading(true)
        setDataEnhancementsError("")
        httpGet(apiURL + "/" + customerID)
            .then((data) => {
                const response = data as DataEnhancementResponse;
                
                if (response.status == "error") {
                    setDataEnhancementsError(response.errorMessage)
                } else {
                    setDataEnhancements(response.enhancements);
                }
            })
            .catch((error) => setDataEnhancementsError(error))
            .finally(() => setDataEnhancementsLoading(false))

    }, [customerID, refreshKey]);

    return { dataEnhancements, dataEnhancementsLoading, dataEnhancementsError };
};