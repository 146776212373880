import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import * as React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Copyright = (props: any) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      ©
      {new Date().getFullYear()}
      &nbsp;
      <Link color="inherit" href="https://www.theshare.group/">
      The Share Group
      </Link>
      &nbsp;
      All rights Reserved.

    </Typography>
  );
};

export default Copyright;