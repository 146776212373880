import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { ValuesResponse } from '../models/ValuesResponse';

const apiURL =  APIEndpoint(EndpointType.Values);

export const useValues = (name: string, refreshKey: number) => {
    const [nameValue, setNameValue] = useState("")
    const [nameValueError, setError] = useState("")
    const [nameValueLoading, setLoading] = useState(false)

    useEffect(() => {        
        setLoading(true)
        setError("")
        httpGet(apiURL + '/' + name)
            .then((data) => {
                const valuesResponse = data as ValuesResponse;

                if (valuesResponse.status == "error") {
                    setError(valuesResponse.errorMessage)
                    setNameValue("")
                    return
                }
                
                setNameValue(valuesResponse.value);
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false))

        setLoading(false)
    }, [name, refreshKey]);

    return { nameValue, nameValueLoading, nameValueError };
};