import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Title from '../components/Title';
import Copyright from "../components/CopyRight";
import * as React from "react";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

const FAQ = () => {

  useEffect(() => {
    // Log Pageview with Hubspot
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', '/FAQ']);
    _hsq.push(['trackPageView']);
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Title>Frequently Asked Questions</Title>
              <Grid item xs={12}>
              <Stack>
                  <Grid sx={{marginBottom: 2}}>
                      <Typography sx={{fontWeight: "bold"}}>Q: I know a customer exists...why aren't they appearing on the 'Place New Order' page?</Typography>
                      <Typography sx={{}}>A: Until a user actually creates an account in the portal, they will not appear in the list. This is why orders for new customers are placed in a 'pending' state.</Typography>
                  </Grid>
                  <Grid sx={{marginBottom: 2}}>
                      <Typography sx={{fontWeight: "bold"}}>Q: Why do some order go to 'pending' and others go directly to 'completed'?</Typography>
                      <Typography sx={{}}>A: Until a user actually creates an account in the portal, their orders will be located in the 'pending' list. If a user is already registered, their orders will go straight to 'completed'.</Typography>
                  </Grid>
                  <Grid sx={{marginBottom: 2}}>
                      <Typography sx={{fontWeight: "bold"}}>Q: Why does the '# Leads' column have two numbers (Example: 1567/1800)?</Typography>
                      <Typography sx={{}}>A: There are situations where there are not enough leads to satisfy the requested number of leads in the order. In this situation, the first number displayed is the 'Desired' quanity and the second number is the 'Actual' quantity fulfilled for the order.</Typography>
                  </Grid>
              </Stack>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </Container>
  );
};

export default FAQ;
