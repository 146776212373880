import * as React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
}

type CopyFn = (text: string) => Promise<boolean> // Return success

export default function CopyToClipboard(props: Props) {

    const copy: CopyFn = async text => {
        if (!navigator?.clipboard) {
          console.warn('Clipboard not supported')
          return false
        }
    
        // Try to save to clipboard then save it in the state if worked
        try {
          await navigator.clipboard.writeText(text)
          return true
        } catch (error) {
          console.warn('Copy failed', error)
          return false
        }
      }

  return (
    <IconButton onClick={() => copy(props.value)}>
        <ContentCopyIcon style={{ fontSize: 14 }} />
    </IconButton>
  );
}
