import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Copyright from "../components/CopyRight";
import * as React from "react";
import Title from '../components/Title';
import { useEffect } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

const CheckoutCancelled = () => {

  useEffect(() => {
    // Log Pageview with Hubspot
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', '/checkoutcancelled' + location.search]);
    _hsq.push(['trackPageView']);
  }, []);

    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Title>Checkout Cancelled</Title>
              {/* Search */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  Forgot to add something to your cart? Shop around and then come back to pay.
                </Paper>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Copyright sx={{ pt: 4 }} />
      </Container>
    );
};
  
  export default CheckoutCancelled;