import * as React from 'react';
import { Storefront } from '../models/StorefrontResponse';
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

interface Props {
    quantity: number
    leadBreakAmount: string
    cpl: number
    name: string
    title: string
    subtitle: string
    active: boolean
    storefront: Storefront
    marginRight?: number
    marginLeft?: number
    totalDials: string
    listingsPerMonth: string
    purchaseClickedCallback(name: string): void
  }

  export default function WalletPriceOption(props: Props) {

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    
    const roundedFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const savings = () => {
        if (props.active) {
            return roundedFormatter.format((.2 * props.quantity) - (props.cpl * props.quantity))
        }

        switch (props.subtitle) {
            case "GETTING STARTED":
                return roundedFormatter.format(0);
            case "RAMPING UP":
                return roundedFormatter.format(50);
            case "** MOST POPULAR **":
                return roundedFormatter.format(200);
            case "PROFESSIONAL/TEAM":
                return roundedFormatter.format(600);
        }
    }

    const cost = () => {
        if (props.active) {
            return "Cost: " + roundedFormatter.format(props.cpl * props.quantity)
        }

        switch (props.subtitle) {
            case "GETTING STARTED":
                return "Starting at $300";
            case "RAMPING UP":
                return "Starting at $450";
            case "** MOST POPULAR **":
                return "Starting at $800";
            case "PROFESSIONAL/TEAM":
                return "Starting at $1,400";
        }
    }

    const byNowClick = () => {
        props.purchaseClickedCallback(props.name)
    }

    const greenCheckCircle = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" ><path fill="#6ECC69" d="M50,2.5000033c-12.5977974,0-24.6798763,5.0045123-33.5873451,13.9126549  C7.5045118,25.3208027,2.4999995,37.4026566,2.4999995,50s5.0045123,24.6798782,13.9126549,33.5873413  C25.3207989,92.495491,37.4026566,97.5,50,97.5s24.6798782-5.004509,33.5873413-13.9126587  C92.495491,74.6791992,97.5,62.5973473,97.5,50s-5.004509-24.6798763-13.9126587-33.5873451  C74.6791992,7.5045123,62.5973473,2.5,50,2.5V2.5000033z M75.408226,31.9548092L49.0183334,68.8988495v0.000885  c-0.9021263,1.2626038-2.3139534,2.065773-3.8602066,2.1947708c-0.1475563,0.0105972-0.291584,0.0158997-0.4356041,0.0158997  c-1.4004517,0.000885-2.7425842-0.5557556-3.7312737-1.5462341L25.1577644,53.73069  c-1.3138618-1.3368416-1.8192673-3.2700691-1.3288822-5.0787048c0.4912701-1.8086586,1.904089-3.2214355,3.7127266-3.7118225  c1.8086567-0.4903831,3.7419052,0.0150223,5.0787029,1.3279991l11.4288368,11.4263458L66.8154373,25.821701  c1.0956345-1.5347633,2.9264755-2.3689137,4.8038788-2.1868305c1.8767014,0.1811314,3.5148087,1.3500977,4.2958527,3.06604  c0.7819519,1.7167568,0.5884628,3.718832-0.507164,5.2537785L75.408226,31.9548092z"/></svg>
        )
    }

    return (
        <Box component={Paper} sx={{marginBottom:2, marginRight:props.marginRight, marginLeft:props.marginLeft, backgroundColor:"#ffffff", width:280, height:230, borderRadius:"5%", borderColor:"black"}}>
            <Typography  variant="body2" align="center" sx={{ pt:2, color:"#2626FE"}}>{props.subtitle}</Typography>
            <Typography variant="h5" align="center" sx={{mt:-.25, mb:1, color:"#000000"}}>{props.title}</Typography>
            <Stack sx={{color:"#000000", ml:1, mr:1}}>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{mb:.25}}>
                    <Box sx={{width:17, height:17, ml:1, mr:1}}>{greenCheckCircle()}</Box>
                    <Stack direction="row" spacing={4} sx={{}}>
                        <Typography variant="body2" sx={{width:120}} align='left'>Price Per Lead</Typography>
                        <Typography variant="body2">{formatter.format(props.cpl)}/per</Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{mb:.25}}>
                <Box sx={{width:17, height:17, ml:1, mr:1}}>{greenCheckCircle()}</Box>
                    <Stack direction="row" spacing={4}>
                        <Typography variant="body2" sx={{width:120}} align='left'>Savings</Typography>
                        <Typography variant="body2">{savings()}</Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="flex-start">
                <Box sx={{width:17, height:17, ml:1, mr:1}}>{greenCheckCircle()}</Box>
                    <Stack direction="row" spacing={4}>
                        <Typography variant="body2" sx={{width:120}} align='left'>Average Listings</Typography>
                        <Typography variant="body2" >{props.listingsPerMonth}</Typography>
                    </Stack>
                </Stack>
                <Stack sx={{mt:1, mb:.5}}>
                    <Typography variant="h5">{cost()}</Typography>
                </Stack>
            </Stack>
            { props.active &&
                <Button variant="contained" sx={{marginBottom:2, width:200, backgroundColor:"#FE5C35", color:"#FFFFFF"}} onClick={byNowClick}>Buy Now</Button>
            }
            
        </Box>
    );
  }