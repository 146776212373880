import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { BarChartSeriesData, PeriodTimeRange, QueryApiLogsCountResponse } from '../models/APILogs';

const apiURL =  APIEndpoint(EndpointType.Query) + "/apilogs";

export const useAPILogCounts = (companyID: string, startDate: string, endDate: string, period: string, refresh: number) => {
    const [logCounts, setLogCount] = useState<BarChartSeriesData[]>()
    const [periodLabels, setPeriodLabels] = useState<string[]>([])
    const [timeRanges, setTimeRanges] = useState<PeriodTimeRange[]>([])
    const [logCountsError, setLogCountsError] = useState("")
    const [logCountsLoading, setLogCountsLoading] = useState(false)
    
    useEffect(() => {
        if (companyID === "" || startDate === "" || endDate === "" || period === "") {
            return
        }

        setLogCountsLoading(true)
        setLogCountsError("")
        const url = `${apiURL}?company_id=${companyID}&start_date=${startDate}&end_date=${endDate}&period=${period}&get_counts=true`
        httpGet(url)
            .then((data) => {
                const response = data as QueryApiLogsCountResponse;
                
                if (response.status == "error") {
                    setLogCountsError(response.errorMessage)
                    setLogCount([])
                } else {
                    if (response.seriesData && response.seriesData.length > 0) {
                        setLogCount(response.seriesData);
                        setPeriodLabels(response.periodLabels)
                        setTimeRanges(response.timeRanges)
                    } else {
                        setLogCount([])
                    }
                }
            })
            .catch((error) => setLogCountsError(error))
            .finally(() => setLogCountsLoading(false))

    }, [companyID, startDate, endDate, period, refresh]);

    return { logCounts, logCountsLoading, logCountsError, periodLabels, timeRanges };
};
