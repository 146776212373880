import * as React from 'react';
import Title from './Title';
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useAnnouncements } from '../hooks/useAnnouncements';
import { useState, useEffect } from 'react';

interface Props {
  isDarkMode: boolean
}

export default function Announcements(props: Props) {
  const { announcements } = useAnnouncements(1)
  const [linkClass, setLinkClass] = useState("link_lightmode_under");

  useEffect(() => {
      if (props.isDarkMode) {
          setLinkClass("link_darkmode_under")
      } else {
          setLinkClass("link_lightmode_under")
      }
  }, );

  return (
    <React.Fragment>
      <Title>Announcements</Title>
      <Grid>
        <Table size="small">
            <TableBody>
                {announcements.map((row) => (
                    <TableRow key={row.description}>
                        <TableCell>
                          <table>
                            <tbody>
                                <tr>
                                  <td>
                                    <IconButton size="medium" type="button">
                                      <InfoIcon color="primary" />
                                    </IconButton>
                                  </td>
                                  <td>{row.description}&nbsp;<a href={row.link} target="_blank" className={linkClass}>More Info</a></td>
                                </tr>
                              </tbody>
                          </table>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
      </Grid>
    </React.Fragment>
  );
}
