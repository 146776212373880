import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { Zip4Response } from '../models/RadiusCounts';


const apiURL =  APIEndpoint(EndpointType.RadiusCounts);

export const useZip4 = (point: number[]) => {
    const [zip4, setZip4] = useState("")
    const [zip5, setZip5] = useState("")
    const [zip4Error, setError] = useState("")
    const [zip4Loading, setLoading] = useState(false)
    
    useEffect(() => {
        if (point.length != 2) {
            return
        }

        if (point[0] === 0 || point[1] === 0) {
            return
        }

        setLoading(true)
        setError("")
        httpGet(apiURL + "/zip4?point=" + point.join(","))
            .then((data) => {
                const response = data as Zip4Response;
                if (response.status === 'error') {
                    setError(response.errorMessage!)
                    setZip4("")
                    setZip5("")
                } else {
                    if (response.zip4 != undefined && response.zip != undefined) {
                        setZip4(response.zip4)
                        setZip5(response.zip)
                    } else {
                        setZip4("")
                        setZip5("")
                    }
                }
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false))

    }, [point]);

    return { zip4, zip5, zip4Loading, zip4Error };
};