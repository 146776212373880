import {useState, useEffect} from 'react';
import { APIEndpoint, EndpointType, httpGetNoAuth } from '../utils/apiService'
import { ZipCodeCount, ZipCodeCountResponse } from '../models/ZipCodeCountsResponse';

const apiURL =  APIEndpoint(EndpointType.ZipCodeCounts);

export const useZipCodeCounts = (zipCodes: string, city: string, county: string, state: string, hideBlanks: boolean, refreshKey: number, customerID: string) => {
    const [zipCodeCounts, setMyZipCodeCounts] = useState<ZipCodeCount[]>([])
    const [zipCodeError, setError] = useState("")
    const [zipCodeLoading, setLoading] = useState(false)

    const parseZips = (zips: string) => {
        // Replace commas with spaces and split the string into an array
        const zipCodesArray = zips.replace(/,/g, ' ').split(/\s+/);

        // Remove any empty strings from the array
        const filteredZipCodes = zipCodesArray.filter((zipCode) => zipCode.trim() !== '');

        return filteredZipCodes.join(",")
    }

    useEffect(() => {
        let params: string[] = []

        if (zipCodes) {
            params.push('zips=' + parseZips(zipCodes));
        }

        if (city) {
            params = []
            params.push('city=' + city.toLocaleLowerCase().trim());
            params.push('state=' + state);
        }

        if (county) {
            params = []
            params.push('county=' + county.toLocaleLowerCase().trim());
            params.push('state=' + state);
        }

        if (customerID) {
            params.push('customer_id=' + customerID);
        }

        if (params.length == 0) {
            const emptyCount: ZipCodeCount[] = [];
            setMyZipCodeCounts(emptyCount);
            return;
        }

        if (hideBlanks) {
            params.push('hideblanks=true')
        }

        // build the querystring
        const queryString = "?" + params.join("&")
        
        setLoading(true)
        setError("")
        httpGetNoAuth(apiURL + queryString)
            .then((data) => {
                const zipCodeCountsResponse = data as ZipCodeCountResponse;
                
                if (zipCodeCountsResponse.status === 'error') {
                    setError(zipCodeCountsResponse.errorMessage)
                    setMyZipCodeCounts([])
                } else {
                    if (zipCodeCountsResponse.zipCodeCounts) {
                        setMyZipCodeCounts(zipCodeCountsResponse.zipCodeCounts);
                    } else {
                        const emptyCount: ZipCodeCount[] = []
                        setMyZipCodeCounts(emptyCount)
                    }
                }
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false))

    }, [zipCodes, city, county, state, refreshKey]);

    return { zipCodeCounts, zipCodeLoading, zipCodeError };
};