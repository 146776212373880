// pages
import DashboardLayout from "./components/DashboardLayout";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import ForgotPassword  from "./pages/ForgotPassword";
import forgotPasswordSubmit from "./pages/ForgotPasswordSubmit";
import ChangePassword from "./pages/ChangePassword";

// other
import {FC} from "react";
import CheckoutSuccess from "./pages/CheckoutSuccess";
import CheckoutCancelled from "./pages/CheckoutCancelled";
import OptOut from "./pages/OptOut";


// interface
interface Route {
    key: string,
    title: string,
    path: string,
    enabled: boolean,
    component: FC<object>
}


export const routes: Array<Route> = [
    {
        key: 'signin-route',
        title: 'Sign In',
        path: '/signin',
        enabled: true,
        component: SignIn
    },
    {
        key: 'signup-route',
        title: 'Sign Up',
        path: '/signup',
        enabled: true,
        component: SignUp
    },
    {
        key: 'dashboard-route',
        title: 'Home',
        path: '*',
        enabled: true,
        component: DashboardLayout
    },
    {
        key: 'forgotpassword-route',
        title: 'Forgot Password',
        path: '/forgotpassword',
        enabled: true,
        component: ForgotPassword
    },
    {
        key: 'forgotpasswordsubmit-route',
        title: 'Forgot Password Submit',
        path: '/forgotpasswordsubmit',
        enabled: true,
        component: forgotPasswordSubmit
    },
    {
        key: 'changepassword-route',
        title: 'Change Password',
        path: '/changepassword',
        enabled: true,
        component: ChangePassword
    },
    {
        key: 'checkoutSuccess-route',
        title: 'Checkout Success',
        path: '/checkoutsuccess',
        enabled: true,
        component: CheckoutSuccess
    },
    {
        key: 'checkoutCancelled-route',
        title: 'Checkout Cancelled',
        path: '/checkoutcancelled',
        enabled: true,
        component: CheckoutCancelled
    },
    {
        key: 'shop-route',
        title: 'Shop',
        path: '/shop',
        enabled: true,
        component: DashboardLayout
    },
    {
        key: 'optout-route',
        title: 'Opt-Out',
        path: '/optout',
        enabled: true,
        component: OptOut
    },
]