import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { SuppressionList, SuppressionListResponse } from '../models/Suppression';

const apiURL =  APIEndpoint(EndpointType.Suppressions);

export const useSuppressions = (referenceID: string, refreshKey: number) => {
    const [suppressions, setSuppressions] = useState<SuppressionList[]>([])
    const [suppressionsError, setSuppressionsError] = useState("")
    const [suppressionsLoading, setSuppressionsLoading] = useState(false)

    useEffect(() => {
        // build the querystring
        const queryString = "?reference-id=" + referenceID 

        setSuppressionsLoading(true)
        setSuppressionsError("")
        httpGet(apiURL + queryString)
            .then((data) => {
                const response = data as SuppressionListResponse;
                
                if (response.status == "error") {
                    setSuppressionsError(response.errorMessage)
                    setSuppressions([])
                    return
                }
                
                if (response.suppressions == undefined) {
                    setSuppressions([])
                    return
                }
                setSuppressions(response.suppressions)
            })
            .catch((error) => setSuppressionsError(error))
            .finally(() => setSuppressionsLoading(false))

    }, [referenceID, refreshKey]);

    return { suppressions, suppressionsLoading, suppressionsError };
};