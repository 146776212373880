import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { ShortOrder, ShortOrdersResponse } from '../models/OrdersResponse'

const apiURL =  APIEndpoint(EndpointType.PendingOrders);

export const usePendingOrders = (pageKey: string, refreshKey: number) => {
    const [orders, setMyOrders] = useState<ShortOrder[]>([])
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState("")

    useEffect(() => {
        const params: string[] = []

        if (pageKey) {
            params.push('?page=' + pageKey);
        }

        // build the querystring
        let queryString = ""

        if (params.length > 0) {
            for (const param of params) {
                queryString += param + "&"
            }
        }
        
        setLoading(true)
        setError("")
        httpGet(apiURL + queryString)
            .then((data) => {
                const shortOrdersResponse = data as ShortOrdersResponse;
                
                if (shortOrdersResponse.status == "error") {
                    setError(shortOrdersResponse.errorMessage)
                    setMyOrders([])
                    return
                }
                
                if (shortOrdersResponse.orders) {
                    setMyOrders(shortOrdersResponse.orders);
                } else {
                    const emptyOrder: ShortOrder[] = []
                    setMyOrders(emptyOrder)
                }
                
                setPage(shortOrdersResponse.page)
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false))

    }, [pageKey, refreshKey]);

    return { orders, page, loading, error };
};