import * as React from "react";

import { useState, useEffect } from 'react';

import Snackbar from "@mui/material/Snackbar";
import { Storefront } from "../models/StorefrontResponse";
import { Alert, Box, Button, Container, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, LinearProgress, Link, MenuItem, Modal, Paper, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { User } from "../models/UsersResponse";
import { useFileUpload } from "../hooks/useFileUpload";
import { Auth } from "aws-amplify";
import { APIEndpoint, EndpointType, httpPut } from "../utils/apiService";
import { DataEnhancement, DataEnhancementResponse, EnhancementStatus, FileUploadDetail } from "../models/DataEnhancement";
import { GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { format, parse } from 'date-format-parse';
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorFallBack from "../components/ErrorFallBack";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import NoData from "../components/NoData";
import DataEnhancementDetails from "../components/DataEnhancementDetails";
import { useDataEnhancement } from "../hooks/useDataEnhancement";
import { Product } from "../models/Products";
import { useProductsAll } from "../hooks/useProductsAll";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/Download";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

interface Props {
    storefront: Storefront
    customer: User | undefined
}

const LeadEnhancement = (props: Props) => {
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMsg, setSnackbarMsg] = useState("")
    const [uploadFormData, setUploadFormData] = useState<FormData>(new FormData())
    const [startUpload, setStartUpload] = useState(false)
    const [accessToken, setAccessToken] = useState("")
    const [refreshToken, setRefreshToken] = useState(1)
    const [dataEnhancement, setDataEnhancement] = useState<DataEnhancement>()
    const [openDetails, setOpenDetails] = useState(false)
    const [enhancementProduct, setEnhancementProduct] = useState<Product>({} as Product)
    const [enhancementPending, setEnhancementPending] = useState(false)
    const [fileUploadLimitWarning, setFileUploadLimitWarning] = useState("")
    const [includeDNC, setIncludeDNC] = useState(true)

    const [firstNameMap, setFirstNameMap] = useState("Not Used")
    const [lastNameMap, setLastNameMap] = useState("Not Used")
    const [addressMap, setAddressMap] = useState("Not Used")
    const [address2Map, setAddress2Map] = useState("Not Used")
    const [zipMap, setZipMap] = useState("Not Used")
    const [phoneMap, setPhoneMap] = useState("Not Used")
    const [emailMap, setEmailMap] = useState("Not Used")
    const [warningMessage, setWarningMessage] = useState("")

    // Hooks
    const { uploadProgress, uploadError, uploadCompleted, uploadDataFileSummary } = useFileUpload(uploadFormData, startUpload, props.customer?.id || "", accessToken)
    const { dataEnhancements, dataEnhancementsLoading, dataEnhancementsError } = useDataEnhancement(props.customer?.id || "", refreshToken)
    const { productsAll } = useProductsAll("", 1)

    const columns = [
        {
            field: 'id', headerName: 'ID', width: 147, renderCell: (params: GridRenderCellParams) => {
                return <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        setDataEnhancement(params.row as DataEnhancement);
                        setOpenDetails(true);
                    }}
                >
                    {params.value}
                </Link>
            }
        },
        {
            field: 'createdAt', headerName: 'Date', width: 85, valueGetter: (params: GridValueGetterParams) => {
                return getShortDate(params.value)
            },
        },
        { field: 'status', headerName: 'Status', width: 160 },
        { field: 'orderID', headerName: 'Order #', width: 160 },
        {
            field: 'fileUploadDetails', headerName: 'Uploaded Filename', width: 240, valueGetter: (params: GridValueGetterParams) => {
                const fileUploadDetails = params.value as FileUploadDetail[];
                if (fileUploadDetails && fileUploadDetails.length > 0) {
                    return fileUploadDetails[0].submittedFilename
                }
                return ""
            },
        },
        {
            field: 'enhancementResult', headerName: 'Summary', width: 240, valueGetter: (params: GridValueGetterParams) => {
                const de = params.row as DataEnhancement;
                if (de) {
                    return createSummary(de)
                }
                return ""
            },
        },
        {
            field: 'customerID', headerName: 'Actions', width: 150, renderCell: (params: GridRenderCellParams) => {
                const de = params.row as DataEnhancement;
                if (de) {
                    if (de.status === EnhancementStatus.COMPLETED) {
                        return <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                                setDataEnhancement(de);
                                setOpenDetails(true);
                            }}
                        >
                            Buy
                        </Button>
                    } else if (de.status === EnhancementStatus.PENDING_APPROVAL) {
                        return <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                                setDataEnhancement(de);
                                if (de.fileUploadDetails && de.fileUploadDetails.length > 0) {
                                    approveEnhancement(de.fileUploadDetails[0])
                                }
                            }}
                        >
                            Approve
                        </Button>
                    }
                    return <Stack direction="row" spacing={2}><Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            setDataEnhancement(de);
                            setOpenDetails(true);
                        }}
                    >
                        View
                    </Button>
                        {params.row.enhancementResult && params.row.enhancementResult.s3Uri != "" &&
                            <Button href={`${createUrl(params.row.enhancementResult.s3Uri)}`} download={"TSG Order"} size="small" >
                                <DownloadIcon color="primary" />
                            </Button>}
                    </Stack>
                }
            },
        },
    ]

    useEffect(() => {
        // Log Pageview with Hubspot
        const _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', '/lead-enhancement' + location.search]);
        _hsq.push(['trackPageView']);
        getAccessToken()
    }, []);

    useEffect(() => {
        if (productsAll && productsAll.length > 0) {
            const enhancementProduct = productsAll.find(product => product.productType === "data_enhancement")
            if (enhancementProduct) {
                setEnhancementProduct(enhancementProduct)
            }
        }
    }, [productsAll])

    useEffect(() => {
        if (uploadDataFileSummary && uploadDataFileSummary.length > 0) {
            setLookupFields(uploadDataFileSummary[0])
        }
    }, [uploadDataFileSummary])

    useEffect(() => {
        const interval = setInterval(() => {
            if (dataEnhancements && dataEnhancements.length > 0) {
                let doRefresh = false
                for (const de of dataEnhancements) {
                    if (de.status === EnhancementStatus.APPROVED || de.status === EnhancementStatus.IN_PROGRESS || de.status === EnhancementStatus.NOT_STARTED) {
                        doRefresh = true
                    }
                }

                if (doRefresh) {
                    setRefreshToken(refreshToken + 1)
                } 
            }
        }, 30000);

        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        if (uploadError) {
            setSnackbarMsg(uploadError)
            setOpenSnackbar(true)
        }
        setStartUpload(false)
    }, [uploadError])

    useEffect(() => {
        if (uploadCompleted && !uploadError) {
            setSnackbarMsg("File upload completed")
            setOpenSnackbar(true)
        }
        setStartUpload(false)
    }, [uploadCompleted])

    const uploadButtonClicked = () => {
        setStartUpload(true)
        setEnhancementPending(true)
        setWarningMessage("")
    }

    function getShortDate(dateString: string) {
        const dt = parse(dateString, 'YYYY-MM-DDTHH:mm:ss');
        return format(dt, "MM/DD/YY")
    }

    function createUrl(s3Uri: string) {
        let url = s3Uri.replace("s3://", "https://")
        url = url.replace("orders-thesharegroup-dev", "orders-thesharegroup-dev.s3.us-west-2.amazonaws.com")
        url = url.replace("orders-thesharegroup-prod", "orders-thesharegroup-prod.s3.us-west-2.amazonaws.com")
        return url
    }

    const createSummary = (de: DataEnhancement): string => {
        if (de.status === EnhancementStatus.COMPLETED) {
            return `Processed: ${de.enhancementResult.processedCount}, Enhanced: ${de.enhancementResult.enhancedCount}`
        } else if (de.status === EnhancementStatus.ERROR) {
            return "An error occurred and is being investigated"
        } else {
            if (de.statusInfo === "" && de.fileUploadDetails && de.fileUploadDetails.length > 0) {
                return "Est. Processing Time: " + Math.round((de.fileUploadDetails[0].records * 2.5 / 60) + 3) + " mins"
            }
            return de.statusInfo
        }
        return ""
    }

    async function getAccessToken() {
        // Get the access token
        const currentSession = Auth.currentSession()
        const token = (await currentSession).getAccessToken()
        const accessToken = token.getJwtToken()
        setAccessToken(accessToken)
    }

    const approveEnhClicked = () => {
        if (!uploadDataFileSummary) {
            return
        }

        if (uploadDataFileSummary.length > 0) {
            approveEnhancement(uploadDataFileSummary[0])
        }
    }

    const getLookupFields = (): Record<string, number> => {
        const mappedFields: Record<string, number> = {};

        if (firstNameMap !== "Not Used") {
            mappedFields["firstName"] = getFieldIndex(firstNameMap)
        }

        if (lastNameMap !== "Not Used") {
            mappedFields["lastName"] = getFieldIndex(lastNameMap)
        }

        if (addressMap !== "Not Used") {
            mappedFields["address"] = getFieldIndex(addressMap)
        }

        if (address2Map !== "Not Used") {
            mappedFields["address2"] = getFieldIndex(address2Map)
        }

        if (zipMap !== "Not Used") {
            mappedFields["zip"] = getFieldIndex(zipMap)
        }

        if (phoneMap !== "Not Used") {
            mappedFields["phone"] = getFieldIndex(phoneMap)
        }

        if (emailMap !== "Not Used") {
            mappedFields["email"] = getFieldIndex(emailMap)
        }

        return mappedFields
    }

    const getFieldIndex = (fieldName: string): number => {
        if (uploadDataFileSummary && uploadDataFileSummary.length > 0) {
            const fud = uploadDataFileSummary[0]
            for (let i = 0; i < fud.fields.length; i++) {
                if (fud.fields[i] === fieldName) {
                    return i
                }
            }
        }
        return -1
    }

    const approveEnhancement = (fud: FileUploadDetail) => {
        if (!fud) {
            return
        }

        fud.lookupFields = getLookupFields()
        console.log("lookup fields: ", fud.lookupFields)

        if (fud.lookupFields["address"] === undefined || fud.lookupFields["zip"] === undefined) {
            setWarningMessage("Address and Zip Code are required fields")
            console.log("warning: address and zip are required", fud.lookupFields)
            return
        }

        const estimatedTime = Math.round((fud.records * 2.5 / 60) + 3) + " mins"
        const confirmationMsg = "Please confirm you would like to enhance this data. This process will take about " + estimatedTime + " to complete the enhancement. Once completed, you will be prompted to purchase the results."
        if (!window.confirm(confirmationMsg)) {
            return
        }

        fud.includeDNC = !includeDNC

        const apiURL = APIEndpoint(EndpointType.DataEnhancement) + "/" + fud.dataEnhancementID + "?approve=true"
        httpPut(apiURL, JSON.stringify(fud))
            .then((data) => {
                const approveEnhancement = data as DataEnhancementResponse;
                if (approveEnhancement.status === "success") {
                    setSnackbarMsg("Data enhancement approved")
                    setOpenSnackbar(true)
                    setRefreshToken(refreshToken + 1)
                } else if (approveEnhancement.status === "error") {
                    setSnackbarMsg(approveEnhancement.errorMessage)
                    setOpenSnackbar(true)
                }
                setEnhancementPending(false)
            })
            .catch((error) => {
                console.log("approve enhancement error: " + error.message)
            });
    }

    const uploadFilenameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileUploadLimitWarning("")
        const files = event.target.files;
        if (files) {
            const file = files[0];

            // Verify the file is less than 5MB
            const size = (file.size);
            console.log("Upload file size: " + size)

            if (size > 4500000) {
                setFileUploadLimitWarning("File is too large! Size must be less than 4.5MB")
                return
            }

            const formData = new FormData();
            formData.append('file', file);
            setUploadFormData(formData);
        }
    }

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const setLookupFields = (fud: FileUploadDetail) => {
        for (const [key, value] of Object.entries(fud.lookupFields)) {
            switch (key) {
                case "firstName":
                    setFirstNameMap(fud.fields[value])
                    break;
                case "lastName":
                    setLastNameMap(fud.fields[value])
                    break;
                case "address":
                    setAddressMap(fud.fields[value])
                    break;
                case "address2":
                    setAddress2Map(fud.fields[value])
                    break;
                case "zip":
                    setZipMap(fud.fields[value])
                    break;
                case "phone":
                    setPhoneMap(fud.fields[value])
                    break;
                case "email":
                    setEmailMap(fud.fields[value])
                    break;
            }
        }

    }

    const snackbarAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const detailsStyle = {
        position: 'absolute',
        top: '47%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: props.storefront.activeColors.addProductForm.formBackground,
        border: '1px solid #5c5c5e',
        boxShadow: 24,
        p: 1,
    };

    const handleDetailsClose = () => setOpenDetails(false);

    const handleDNCChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIncludeDNC(event.target.checked);
      };

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                        <Stack direction="column" justifyContent="space-between" alignItems="flex-start" spacing={1}>
                            <Typography variant="h6" sx={{ color: props.storefront.activeColors.mainPage.pageText }}>Lead Enhancement</Typography>
                            <Typography variant="body1" sx={{ color: props.storefront.activeColors.mainPage.pageText }}>Have existing data you'd like to add Phones, Emails, Addresses or other data to? Upload your file below to get started!</Typography>
                            <Typography variant="body1" sx={{ color: props.storefront.activeColors.mainPage.pageText, fontWeight: "bold" }}>File Requirements:</Typography>
                            <Typography variant="body1" sx={{ pl: 2, color: props.storefront.activeColors.mainPage.pageText }}>&bull; Supported file types: .csv, .xls, .xlsx</Typography>
                            <Typography variant="body1" sx={{ pl: 2, color: props.storefront.activeColors.mainPage.pageText }}>&bull; Column names must include "address" & "zip" or "address", "city" & "state"</Typography>
                            <Typography variant="body1" sx={{ pl: 2, color: props.storefront.activeColors.mainPage.pageText }}>&bull; Best results are achieved if the person's last name is provided</Typography>
                            <Typography variant="body1" sx={{ pl: 2, color: props.storefront.activeColors.mainPage.pageText }}>&bull; Maximum file size is 4.5MB</Typography>
                        </Stack>
                        <Grid item xs={12}>
                            <Paper sx={{ paddingTop: 2, display: "flex", flexDirection: "column" }}>
                                <Grid
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                    }}
                                    item
                                    xs={12}
                                >

                                </Grid>

                                <FormControl>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <TextField type="file" onChange={uploadFilenameChanged} inputProps={{ accept: ".csv,.xls,.xlsx" }} sx={{ width: 600 }} />
                                        <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} onClick={uploadButtonClicked}>Upload</Button>
                                    </Stack>
                                    {fileUploadLimitWarning != "" && <Alert variant="standard" severity="error" sx={{ ml: 1, mr: 1, mt: 1 }}>{fileUploadLimitWarning}</Alert>}
                                </FormControl>
                                {uploadCompleted
                                    ? <Grid sx={{ mt: 1 }}>
                                        {enhancementPending && uploadDataFileSummary && uploadDataFileSummary.map((fileUploadDetail) => (
                                            <Grid key={fileUploadDetail.id}>
                                                {fileUploadDetail.error && fileUploadDetail.error != ""
                                                    ? <Alert variant="standard" severity="error">
                                                        <Stack>
                                                            <Typography>'{fileUploadDetail.submittedFilename}' upload error: {fileUploadDetail.error}</Typography>
                                                            <Typography>Fields: {fileUploadDetail.fields.join(", ")}</Typography>
                                                        </Stack>
                                                    </Alert>
                                                    : <Alert variant="standard" severity="success">
                                                        <Stack>
                                                            <Typography>'{fileUploadDetail.submittedFilename}' uploaded successfully!</Typography>
                                                            <Typography>Records found: {fileUploadDetail.records}</Typography>

                                                            <Grid>
                                                                <Typography variant="h6">Field Mapping:</Typography>
                                                                <Typography variant="body1">In order to start the Lead Enhancement process, fields must be mapped so we know which ones to use. Address and Zip are the only required fields.</Typography>
                                                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ mt: 2, pb: 1 }}>
                                                                    <Typography variant="body1" sx={{ width: 100 }}>First Name:</Typography>
                                                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                                                        <Select labelId="transType" label="Fields" onChange={(e => setFirstNameMap(e.target.value))} size="small" value={firstNameMap}>
                                                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                                                            {fileUploadDetail && fileUploadDetail.fields.map((fm, index) => (
                                                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </Stack>
                                                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                                                    <Typography variant="body1" sx={{ width: 100 }}>Last Name:</Typography>
                                                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                                                        <Select labelId="transType" label="Fields" onChange={(e => setLastNameMap(e.target.value))} size="small" value={lastNameMap}>
                                                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                                                            {fileUploadDetail && fileUploadDetail.fields.map((fm, index) => (
                                                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </Stack>
                                                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                                                    <Typography variant="body1" sx={{ width: 100 }}>Address:</Typography>
                                                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                                                        <Select labelId="transType" label="Fields" onChange={(e => setAddressMap(e.target.value))} size="small" value={addressMap}>
                                                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                                                            {fileUploadDetail && fileUploadDetail.fields.map((fm, index) => (
                                                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </Stack>
                                                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                                                    <Typography variant="body1" sx={{ width: 100 }}>Address 2:</Typography>
                                                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                                                        <Select labelId="transType" label="Fields" onChange={(e => setAddress2Map(e.target.value))} size="small" value={address2Map}>
                                                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                                                            {fileUploadDetail && fileUploadDetail.fields.map((fm, index) => (
                                                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </Stack>
                                                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                                                    <Typography variant="body1" sx={{ width: 100 }}>Zip Code:</Typography>
                                                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                                                        <Select labelId="transType" label="Fields" onChange={(e => setZipMap(e.target.value))} size="small" value={zipMap}>
                                                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                                                            {fileUploadDetail && fileUploadDetail.fields.map((fm, index) => (
                                                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </Stack>
                                                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                                                    <Typography variant="body1" sx={{ width: 100 }}>Phone:</Typography>
                                                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                                                        <Select labelId="transType" label="Fields" onChange={(e => setPhoneMap(e.target.value))} size="small" value={phoneMap}>
                                                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                                                            {fileUploadDetail && fileUploadDetail.fields.map((fm, index) => (
                                                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </Stack>
                                                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                                                    <Typography variant="body1" sx={{ width: 100 }}>Email:</Typography>
                                                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                                                        <Select labelId="transType" label="Fields" onChange={(e => setEmailMap(e.target.value))} size="small" value={emailMap}>
                                                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                                                            {fileUploadDetail && fileUploadDetail.fields.map((fm, index) => (
                                                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </Stack>
                                                                {warningMessage != "" && <Alert variant="standard" severity="error" sx={{ ml: 1, mr: 1 }}>{warningMessage}</Alert>}
                                                                {/* <Grid container direction="row" alignItems="center" justifyContent="center" sx={{ mt: 1, pb: 1 }}>
                                                                    <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} onClick={submitButtonClicked}>Complete Submission</Button>
                                                                </Grid> */}
                                                            </Grid>
                                                            <Box textAlign="center">
                                                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                                                    <Typography sx={{fontWeight:"bold"}}>Do Not Call:</Typography>
                                                                    <FormGroup>
                                                                        <FormControlLabel sx={{}} control={<Switch checked={includeDNC} onChange={handleDNCChange} />} label="Include phones on DNC" />
                                                                    </FormGroup>
                                                                </Stack>
                                                                <Button sx={{ mt: 2, width: "340px" }} variant="contained" onClick={approveEnhClicked}>Approve & Begin Data Enhancement</Button>
                                                            </Box>
                                                        </Stack>
                                                    </Alert>

                                                }
                                            </Grid>
                                        ))}
                                    </Grid>
                                    : <Grid>
                                        {uploadProgress > 0 &&
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <Typography variant="body1" sx={{ width: 180 }}>Uploading file...</Typography>
                                                <Box sx={{ width: '100%', mr: 1 }}>
                                                    <LinearProgress variant="determinate" value={uploadProgress} />
                                                </Box>
                                            </Stack>
                                        }
                                    </Grid>
                                }

                                {dataEnhancementsLoading &&
                                    <LoadingSpinner title="Loading Enhancements..." textColor={props.storefront.activeColors.mainPage.pageText} />
                                }
                                {dataEnhancements && dataEnhancements.length > 0
                                    ? <Grid height={500} sx={{ mt: 3, mb: 5 }}>
                                        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
                                            <Typography variant="h5">Active Enhancements:</Typography>
                                            <IconButton size="medium" type="button" onClick={() => setRefreshToken(refreshToken + 1)} sx={{ height: 40, marginTop: 1 }} >
                                                <RefreshIcon color="primary" />
                                            </IconButton>
                                        </Stack>
                                        {dataEnhancementsLoading
                                            ? <LoadingSpinner title="Loading Orders..." textColor="#0352fc" />
                                            : dataEnhancementsError
                                                ? <ErrorFallBack message={dataEnhancementsError} />
                                                :
                                                <DataGridPremium
                                                    rows={dataEnhancements}
                                                    columns={columns}
                                                    initialState={{
                                                        sorting: {
                                                            sortModel: [{ field: 'createdAt', sort: 'desc' }],
                                                        },
                                                    }}
                                                    slots={{
                                                        noRowsOverlay: NoData
                                                    }}
                                                />
                                        }
                                    </Grid>
                                    : <Grid sx={{ mt: 3 }}>
                                        <Typography variant="body1">After the purchase of a lead enhancement, it will appear in 'My Purchases' where it can be downloaded</Typography>
                                    </Grid>
                                }
                            </Paper>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid >
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMsg}
                action={snackbarAction}
            />
            <Modal
                open={openDetails}
                onClose={handleDetailsClose}
            >
                <Box sx={detailsStyle}>
                    <DataEnhancementDetails storefront={props.storefront} dataEnhancement={dataEnhancement} customer={props.customer} product={enhancementProduct} close={() => { setOpenDetails(false) }} />
                </Box>
            </Modal>
        </Container >
    );
};

export default LeadEnhancement;