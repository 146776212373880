import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { DataSourceResponse, QueryDataSource } from '../models/DataSource';

const apiURL =  APIEndpoint(EndpointType.Query);

export const useDataSource = (dataSourceID: string) => {
    const [dataSource, setDataSource] = useState<QueryDataSource>()
    const [dataSourceError, setDataSourceError] = useState("")
    const [dataSourceLoading, setDataSourceLoading] = useState(false)
    
    useEffect(() => {
        if (!dataSourceID || dataSourceID == "0") {
            return;
        }

        // The Absentee data source is not returned by the API correctly, so we need to add it manually
        if (dataSourceID == "50") {
            setDataSource({id: 50, name: "Absentee", size: 0, fields: [
                {key: "pzip", range: false, values: {}, name: ""},
                ],
                dedupes: [{field: "address_zip", name: "One per address"}]
            },)
            return;
        }

        setDataSourceLoading(true)
        setDataSourceError("")
        httpGet(apiURL + "/datasources/" + dataSourceID)
            .then((data) => {
                const response = data as DataSourceResponse;
                
                if (response.status == "error") {
                    setDataSourceError(response.errorMessage)
                    setDataSource({id: 0, name: "", size: 0})
                } else {
                    response.dataSource.fields?.sort((a, b) => a.key.localeCompare(b.key))
                    setDataSource(response.dataSource);
                }
            })
            .catch((error) => setDataSourceError(error))
            .finally(() => setDataSourceLoading(false))

    }, [dataSourceID]);

    return { dataSource, dataSourceLoading, dataSourceError };
};