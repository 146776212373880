import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import * as React from "react";
import Title from '../components/Title';
import { useSubscriptions } from '../hooks/useSubscriptions';
import { useState, useEffect } from 'react';
import useAuth from "../hooks/useAuth";
import { format, parse } from 'date-format-parse';
import { DataGridPremium, GridValueGetterParams, GridRowId } from '@mui/x-data-grid-premium';
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorFallBack from "../components/ErrorFallBack";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { httpPut, APIEndpoint, EndpointType } from "../utils/apiService";
import { SubscriptionsResponse, SubscriptionFulfillment } from "../models/Subscriptions";
import { styled } from '@mui/material/styles';
import { Storefront } from "../models/StorefrontResponse";
import { Typography } from "@mui/material";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

interface Props {
  storefront: Storefront
}

const MySubscriptions = (props: Props) => {
    const [customerID, setCustomerID] = useState("");
    const [leadType, setLeadType] = useState("");
    const [zipCodes, setZipCodes] = useState("");
    const [refreshKey, setRefreshKey] = useState(0);
    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
    const { subscriptions, subscriptionsLoading, subscriptionsError } = useSubscriptions(customerID, refreshKey)
    const { user  } = useAuth();
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMsg, setSnackbarMsg] = useState("")
    const [showUpdateWarning, setShowUpdateWarning] = useState("none")
    const [updateWarningMsg, setCUpdateWarningMsg] = useState("")
    const [showEdit, setShowEdit] = useState("none")
    const [selectedSubID, setSelectedSubID] = useState("")

    const columns = [
        { field: 'createdAt', headerName: 'Start Date', width: 85, valueGetter: (params: GridValueGetterParams) => {
            return getShortDate(params.value)
          }, },
        { field: 'status', headerName: 'Status', editable: false, width: 85 },
        { field: 'quantity', headerName: 'Quantity', editable: false, width: 85 },
        { field: 'fulfillments', headerName: 'Next Fulfillment', width: 140, valueGetter: (params: GridValueGetterParams) => {
            return getNextFulfillmentDate(params.value)
          }, },
        { field: 'leadTypes', headerName: 'Lead Type', width: 125 },
        { field: 'zipCodes', headerName: 'Zip Codes', flex:1 },
    
    ]

    useEffect(() => {
        setCustomerID(user.username);
    })

    useEffect(() => {
      // Log Pageview with Hubspot
      const _hsq = window._hsq = window._hsq || [];
      _hsq.push(['setPath', '/mysubscriptions']);
      _hsq.push(['trackPageView']);
    }, []);

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackbar(false);
      };

    const snackbarAction = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
    );

    function getShortDate(dateString: string | undefined) {
        if (dateString != undefined) {
          const dt = parse(dateString, 'YYYY-MM-DDTHH:mm:ss');
          return format(dt, "MM/DD/YY")
        }
    }

    function getNextFulfillmentDate(fulfillments: SubscriptionFulfillment[] | undefined) {
        if (fulfillments != undefined && fulfillments?.length > 0) {
            return getNextMonth(fulfillments[fulfillments.length-1].dateFulfilled)
        }
    }

    function getNextMonth(dateString: string): string {
        const date = new Date(dateString);
        date.setMonth(date.getMonth() + 1);
    
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
    
        return `${month}/${day}/${year}`;
    }

    function editSubscription() {
        if (selectionModel.length == 0) {
            return
        }

        for (const item of selectionModel) {
            for (const subscription of subscriptions) {
                if (subscription.id === item) {
                    setSelectedSubID(item)
                    setLeadType(subscription.leadTypes[0])
                    setZipCodes(subscription.zipCodes.join(', '))
                    setShowEdit('flex')
                }
            }
        }

        setShowUpdateWarning("none")
    }

    const leadTypeChanged = (event: SelectChangeEvent) => {
        setLeadType(event.target.value as string)
      }

    const zipCodesChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setZipCodes(event.target.value)
        setShowUpdateWarning("none")
    }

    function trimCommasFromString(str: string): string {
        let trimmedStr = str;
        while (trimmedStr.charAt(0) === ',') {
          trimmedStr = trimmedStr.slice(1);
        }
      
        while (trimmedStr.charAt(trimmedStr.length - 1) === ',') {
          trimmedStr = trimmedStr.slice(0, -1);
        }
      
        return trimmedStr;
    }

    function createZipCodeArray(): string[] {
        const zipCodesClean = trimCommasFromString(zipCodes.replaceAll(' ', ''))
        const zips = zipCodesClean.split(',')

        if (zips.length === 0) {
            setCUpdateWarningMsg('At least one zip code must be specified. All zip codes must be separated by commas')
            setShowUpdateWarning("inline")
            return []
        }

        for (const zip of zips) {
            if (zip.trim().length != 5) {
                setCUpdateWarningMsg('Only 5-digit zip codes are allowed. All zip codes must be separated by commas')
                setShowUpdateWarning("inline")
                return []
            }
        }

        return zips
    }

    function saveSubscription() {
      const zips = createZipCodeArray()
        if (zips.length === 0) {
            return
        }

        for (const subscription of subscriptions) {
            if (subscription.id === selectedSubID) {
                subscription.zipCodes = zips
                subscription.leadTypes = [leadType]
                const apiURL =  APIEndpoint(EndpointType.Subscriptions)
                httpPut(apiURL, JSON.stringify(subscription))
                .then((data) => {
                    const subscriptionResponse = data as SubscriptionsResponse;
                    
                    if (subscriptionResponse.status == 'error') {
                        console.log("Error updating subscription: " + subscriptionResponse.errorMessage)
                        setSnackbarMsg("Subscription updated failed! " + subscriptionResponse.errorMessage)
                        setOpenSnackbar(true)
                    } else {
                      setSnackbarMsg("Subscription updated successfully!")
                      setOpenSnackbar(true)
                      setRefreshKey(refreshKey+1)
                      setShowEdit('none')
                      setZipCodes("")
                      setLeadType('absentee')
                    }
                })
                .catch((error) => { 
                  setSnackbarMsg(error.message)
                  setOpenSnackbar(true)
                });
            }
        }
    }

    const StyledGridOverlay = styled('div')(({ theme }) => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
      },
      '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
      },
      '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
      },
      '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
      },
      '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
      },
    }));

    function CustomNoRowsOverlay() {
      return (
        <StyledGridOverlay>
          <Box width={100}>
            <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 64 80" x="0px" y="0px">
              <polygon points="13.828 6.758 11 9.586 8.172 6.758 6.758 8.172 9.586 11 6.758 13.828 8.172 15.242 11 12.414 13.828 15.242 15.242 13.828 12.414 11 15.242 8.172 13.828 6.758" fill="#797a7a"/>
                <rect x="28.5" y="31" width="2" height="2" fill="#797a7a"/>
                <rect x="33.5" y="31" width="2" height="2" fill="#797a7a"/>
                <rect x="38.5" y="31" width="2" height="2" fill="#797a7a"/>
                <rect x="28.5" y="35" width="2" height="2" fill="#797a7a"/>
                <rect x="23.5" y="35" width="2" height="2" fill="#797a7a"/>
                <rect x="33.5" y="35" width="2" height="2" fill="#797a7a"/>
                <rect x="28.5" y="39" width="2" height="2" fill="#797a7a"/>
                <rect x="23.5" y="39" width="2" height="2" fill="#797a7a"/>
                <rect x="33.5" y="39" width="2" height="2" fill="#797a7a"/>
                <rect x="38.5" y="35" width="2" height="2" fill="#797a7a"/>
                <path d="M32,1A30.933,30.933,0,0,0,18.314,4.2,9.985,9.985,0,1,0,4.2,18.314a30.992,30.992,0,0,0,47.7,37.453l-1.285-1.534A28.987,28.987,0,0,1,5.827,19.542,9.979,9.979,0,0,0,19.542,5.827a28.98,28.98,0,0,1,36.73,42.02l-.116-2.985-2,.078.238,6.1a1,1,0,0,0,1.377.887l5.649-2.311-.758-1.851-2.649,1.083A30.991,30.991,0,0,0,32,1ZM11,19a8,8,0,1,1,8-8A8.009,8.009,0,0,1,11,19Z" fill="#797a7a"/>
                <path d="M27,24V20H25v1H22a3,3,0,0,0-3,3V43a3,3,0,0,0,3,3H39a1,1,0,0,0,.707-.293l5-5A1,1,0,0,0,45,40V24a3,3,0,0,0-3-3H40v2h2a1,1,0,0,1,1,1v2H21V24a1,1,0,0,1,1-1h3v1ZM40,42.586V41h1.586ZM43,28V39H39a1,1,0,0,0-1,1v4H22a1,1,0,0,1-1-1V28Z" fill="#797a7a"/>
                <polygon points="38 24 38 20 36 20 36 21 29 21 29 23 36 23 36 24 38 24" fill="#797a7a"/>
              </svg>
            </Box>
          <Box sx={{ mt: 1, color:"#797a7a" }}>No Subscriptions</Box>
        </StyledGridOverlay>
      );
    }

    return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
                <React.Fragment>
                    <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={2}
                            sx={{marginBottom: 1}}
                        >
                        <Typography variant="h6" sx={{color: props.storefront.activeColors.mainPage.pageText}}>My Subscriptions</Typography>
                        <Button size="small" variant="outlined" onClick={editSubscription} sx={{marginLeft: 1}}>
                            <EditIcon color="primary" />Edit Subscription
                        </Button>
                    </Stack>
                    <Grid height={600}>
                        {subscriptionsLoading
                        ? <LoadingSpinner title="Loading subscriptions..." textColor="#0352fc"/>
                        : subscriptionsError
                        ? <ErrorFallBack message={subscriptionsError} />
                        :
                        <DataGridPremium
                        rows={subscriptions}
                        columns={columns}
                        hideFooterSelectedRowCount
                        hideFooter
                        checkboxSelection
                        rowSelectionModel={selectionModel}
                        slots={{
                          noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                        }}
                        sx={{"& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                            display: "none"
                          }}}
                        pageSizeOptions={[5, 10, 25, 50]}
                        onRowSelectionModelChange={(ids) => {
                            if (ids.length > 1) {
                                const selectionSet = new Set(selectionModel);
                                const result = ids.filter((s) => !selectionSet.has(s));
                      
                                setSelectionModel(result);
                              } else {
                                setSelectionModel(ids);
                                setShowEdit('none')
                              }
                          }}
                    />
                        }
                    </Grid>
                    </React.Fragment>
          </Paper>
          <Paper               sx={{
                p: 2,
                display: showEdit,
                flexDirection: "column", marginTop: 2
              }}>
            <Grid item xs={12}>
                <Title>Edit Subscription</Title>
                <FormControl sx={{width: 200, marginTop:2}} required>
                              <InputLabel id="lead-type-select-label">Lead Type</InputLabel>
                              <Select labelId="lead-type-select-label" size="small" 
                                  id="lead-type-select"
                                  value={leadType}
                                  label="Lead Type"
                                  onChange={leadTypeChanged}>
                                <MenuItem value={'absentee'}>Absentee</MenuItem>
                                <MenuItem value={'distressed'}>Distressed</MenuItem>
                                <MenuItem value={'divorced'}>Divorced</MenuItem>
                                <MenuItem value={'downsizer'}>Downsizer</MenuItem>
                                <MenuItem value={'likelyToSell'}>Likely to Sell</MenuItem>
                              </Select>
                </FormControl>                
                <TextField id="outlined-basic" label="Zip Codes" multiline rows={3} variant="outlined" size="small" required onChange={zipCodesChanged} value={zipCodes} fullWidth sx={{marginTop: 3}} />
                <Button variant="contained" color="primary" onClick={saveSubscription} sx={{marginTop: 3}} >Update Subscription</Button>
                <Box display={showUpdateWarning}>
                      <Alert severity="warning">{updateWarningMsg}</Alert>
                </Box>
            </Grid>
          </Paper>
        </Grid>
        <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={snackbarMsg}
            action={snackbarAction}
        />
      </Grid>
      {/* <Footer position="fixed" storefrontName={props.storefront.storeName} /> */}
    </Container>
  );
  }
  
export default MySubscriptions;