import {useState, useEffect} from 'react';
import { APIEndpoint, EndpointType, httpGetNoAuth } from '../utils/apiService'
import { StorefrontResponse, Storefront } from '../models/StorefrontResponse';

const apiURL =  APIEndpoint(EndpointType.Storefront);

export const useStorefrontManager = (name: string) => {
    const [storefronts, setStorefronts] = useState<Storefront[]>([])
    const [storefrontError, setError] = useState("")
    const [storefrontLoading, setLoading] = useState(false)

    useEffect(() => {        
        if (name != '') {
            setLoading(true)
            setError("")
            httpGetNoAuth(apiURL + '/' + name)
                .then((data) => {
                    const storefrontResponse = data as StorefrontResponse;
                    
                    if (storefrontResponse.status == "error") {
                        setError(storefrontResponse.errorMessage)
                        setStorefronts([])
                        return
                    }
                    
                    if (storefrontResponse.storefronts) {
                        // Change the activeColors in each storefront to the lightMode
                        for (const sf of storefrontResponse.storefronts) {
                            sf.activeColors = sf.lightMode
                        }
                        
                        setStorefronts(storefrontResponse.storefronts);
                        if (storefrontResponse.errorMessage != "") {
                            setError(storefrontResponse.errorMessage)
                        }
                    } else {
                        const empty: Storefront[] = []
                        setStorefronts(empty)
                        setError(storefrontResponse.errorMessage)
                    }

                })
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        }
    }, [name]);

    return { storefronts, storefrontLoading, storefrontError };
};