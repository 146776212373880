import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { WalletBalance, WalletBalanceResponse } from '../models/Wallet';


const apiURL =  APIEndpoint(EndpointType.WalletBalance);

export const useWalletBalance = (customerID: string, refreshKey: number) => {
    const [walletBalance, setWalletBalance] = useState<WalletBalance>()
    const [walletBalanceError, setWalletBalanceError] = useState("")
    const [walletBalanceLoading, setWalletBalanceLoading] = useState(false)
    
    useEffect(() => {
        if (customerID == "") {
            return
        }

        const wallBal: WalletBalance = {
            balance: 0
        }
        setWalletBalance(wallBal)
        setWalletBalanceLoading(true)
        setWalletBalanceError("")
        httpGet(apiURL + "/" + customerID)
            .then((data) => {
                const response = data as WalletBalanceResponse;
                if (response.status === 'error') {
                    setWalletBalanceError(response.errorMessage!)
                    setWalletBalance({})
                } else {
                    if (response.balance != undefined) {
                        setWalletBalance(response.balance)
                    } else {
                        setWalletBalance(wallBal)
                    }
                }
            })
            .catch((error) => setWalletBalanceError(error))
            .finally(() => setWalletBalanceLoading(false))

    }, [customerID, refreshKey]);

    return { walletBalance, walletBalanceLoading, walletBalanceError };
};