import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { OrderGeoJsonResponse, GeoJSON } from '../models/orderGeoJsonResponse';

const apiURL =  APIEndpoint(EndpointType.Orders);

export const useGeoJsonVisualizer = (orderID: string, dataRequestID: string, refreshKey: number) => {
    const [geoJSON, setGeoJSON] = useState<GeoJSON[]>([])
    const [geoJSONError, setError] = useState("")
    const [geoJSONLoading, setLoading] = useState(false)
    
    useEffect(() => {
        if (!orderID) return;
        if (!dataRequestID) return;
        const url = apiURL + "/" + orderID + "?geojson=true&datarequestid="+dataRequestID

        setLoading(true)
        httpGet(url)
            .then((data) => {
                try {
                    const response = data as OrderGeoJsonResponse;

                    if (response.status === 'error') {
                        setError(response.errorMessage)
                        setGeoJSON([])
                    } else {
                        if (response.geoJson.features == undefined) {
                            response.geoJson.features = []
                        } else {
                            setGeoJSON(response.geoJson.features);
                        }
                    }
                }
                catch(error) {
                    setGeoJSON([])
                    setError("No orders were found")
                }
            })
            .catch((error) => {
                setGeoJSON([])
                setError(error)
            })
            .finally(() => setLoading(false))

    }, [dataRequestID, refreshKey]);

    return { geoJSON, geoJSONLoading, geoJSONError };
};