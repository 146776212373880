import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { DataSourcesResponse, QueryDataSource } from '../models/DataSource';

const apiURL =  APIEndpoint(EndpointType.Query) + "/datasources";

export const useDataSources = (refreshKey: number) => {
    const [dataSources, setDataSources] = useState<QueryDataSource[]>()
    const [dataSourcesError, setDataSourcesError] = useState("")
    const [dataSourcesLoading, setDataSourcesLoading] = useState(false)
    
    useEffect(() => {
        setDataSourcesLoading(true)
        setDataSourcesError("")
        httpGet(apiURL)
            .then((data) => {
                const response = data as DataSourcesResponse;
                
                if (response.status == "error") {
                    setDataSourcesError(response.errorMessage)
                    setDataSources([])
                } else {
                    if (response.dataSources && response.dataSources.length > 0) {
                        const dataSources = response.dataSources as QueryDataSource[]

                        // Add the Absentee Data Source to the list since it is not returned by the API
                        const absenteeDataSource: QueryDataSource = { id: 30, name: "Absentee", size: 0 }
                        dataSources.push(absenteeDataSource)
                        dataSources.sort((a, b) => a.name.localeCompare(b.name))
                        setDataSources(dataSources);
                    } else {
                        setDataSources([])
                    }
                }
            })
            .catch((error) => setDataSourcesError(error))
            .finally(() => setDataSourcesLoading(false))

    }, [refreshKey]);

    return { dataSources, dataSourcesLoading, dataSourcesError };
};
