import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Copyright from "../components/CopyRight";
import * as React from "react";
import Title from '../components/Title';
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useEffect } from 'react';
//import Questionnaire from "../components/Questionnaire";
import useAuth from "../hooks/useAuth";
import { Typography } from "@mui/material";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

const CheckoutSuccess = () => {
  useEffect(() => {
  localStorage.removeItem("cart")
  console.log("shopping cart removed from local storage")
}, []);

  useEffect(() => {
    // Log Pageview with Hubspot
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', '/checkoutsuccess']);
    _hsq.push(['trackPageView']);
  }, []);

  const navigate = useNavigate();
    const { isAuthenticated } = useAuth();

    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Title>Order Completion</Title>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <p>Thank you for your order! It is being processed and will be available in a few minutes on the 'My Purchases' tab in the Lead Shop.</p>
                  {/* <Questionnaire questionnaireID="postPurchase" /> */}
                  <Box>
                    <Typography variant="body1">We value your feedback and would love to hear from you!</Typography>
                    <Button variant="contained" target="_new" href="https://search.google.com/local/writereview?placeid=ChIJVVF_XC_tk4cR-coS4HGH-FM">
                      <Typography variant="body1">Leave a&nbsp;</Typography>
                      <Box sx={{height:32, mt:.25}} component="img" alt="Google Logo" src="https://lh3.googleusercontent.com/d_S5gxu_S1P6NR1gXeMthZeBzkrQMHdI5uvXrpn3nfJuXpCjlqhLQKH_hbOxTHxFhp5WugVOEcl4WDrv9rmKBDOMExhKU5KmmLFQVg" />
                      <Typography variant="body1">&nbsp;Review</Typography>
                    </Button>
                  </Box>
                  <Typography variant="h6" sx={{marginTop:2}}>Next Steps:</Typography>
                  { isAuthenticated
                    ? <Box> 
                        <Button variant="contained" sx={{width:240, marginTop:3}} onClick={() => {navigate("/myorders");}}>
                          View Purchases
                        </Button>
                    </Box>
                    : <Box>
                        <Stack direction="row">
                          <Typography sx={{marginTop:0}}>If you do not have an account, you will need to create one in order to access your data.&nbsp;</Typography>
                          <Button variant="contained" sx={{width:160, marginTop:-.5}} onClick={() => {navigate("/signup");}}>
                            Create Account
                          </Button>
                        </Stack>
                        <Stack direction="row" sx={{marginTop:2}}>
                          <Typography sx={{marginTop:0}}>If you already have an account, sign-in to access your data.&nbsp;</Typography>
                          <Button variant="contained" sx={{width:160, marginTop:-.5}} onClick={() => {navigate("/signin");}}>
                          Sign-In
                        </Button>
                        </Stack>
                      </Box>
                  }
                </Paper>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Copyright sx={{ pt: 4 }} />
      </Container>
    );
};
  
  export default CheckoutSuccess;