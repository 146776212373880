import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CopyToClipboard from './CopyToClipboard';
import { Product } from '../models/Products';
import { Tooltip } from '@mui/material';
import { LocalTime } from '../utils/localTime';

interface Props {
  product: Product
  close(): void
}

export default function ProductDetails(props: Props) {
    useEffect(() => {
        console.log("Product Details:", props.product)
    });

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

  return (
    <Grid sx={{height:600, overflow:"scroll"}}>
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
            <Typography sx={{marginLeft:.5, marginTop:-.6, color:"#000000"}} variant='h6'>Order Details</Typography>
            <IconButton sx={{marginTop:-.5, color:"#000000"}} size="small" type="button" onClick={() => props.close() } >
                <CloseIcon />
            </IconButton>
        </Stack>
        <Grid sx={{ marginTop: 0}}>
            <Stack>
            <React.Fragment>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>Product ID:</TableCell>
                            <TableCell>{props.product.id} <CopyToClipboard value={props.product.id} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Name:</TableCell>
                            <TableCell>{props.product.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Description:</TableCell>
                            <TableCell>{props.product.description}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Description:</TableCell>
                            <TableCell>{props.product.detailedDescription}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Audience ID:</TableCell>
                            <TableCell><a href={"https://via.giantpartners.com/audiences/" + props.product.audienceID} target='_blank'>{props.product.audienceID}</a>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Price:</TableCell>
                            <TableCell>{formatter.format(props.product.priceInfo.price)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Stripe Price ID:</TableCell>
                            <TableCell>{props.product.priceInfo.stripePriceID} <CopyToClipboard value={props.product.priceInfo.stripePriceID} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Stripe Product ID:</TableCell>
                            <TableCell>{props.product.stripeID} <CopyToClipboard value={props.product.stripeID} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Features:</TableCell>
                            <TableCell>No DNC: {props.product.features.noDNC}, PhonesEmails: {props.product.features.phonesEmails}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Product Type:</TableCell>
                            <TableCell>{props.product.productType}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Group Name:</TableCell>
                            <TableCell>{props.product.groupName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Info URL:</TableCell>
                            <TableCell><a href={props.product.infoURL} target='_blank'>{props.product.infoURL}</a></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Output Fields:</TableCell>
                            <TableCell>{props.product.outputFields.join(", ")}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Modified Date:</TableCell>
                            <TableCell>
                                <Tooltip title={LocalTime(props.product.modifiedAt)} placement="bottom-start">
                                    <Typography variant="body2">{props.product.modifiedAt}</Typography>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                </TableContainer>
            </React.Fragment>
            </Stack>
        </Grid>
    </Grid>
    
  );
}
