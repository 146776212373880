import * as React from "react";
import { createRoot } from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import App from "./App";
//import lightModeTheme from "./theme";
import { red } from '@mui/material/colors';

// import aws amplify
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import AuthProvider from "./contexts/AuthContext";
import { LicenseInfo } from '@mui/x-license-pro';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);

// configure amplify
Amplify.configure(awsExports)

// set MUI Premium License Key
const key = process.env.REACT_APP__MUI_LICENSE_KEY;
if (key != undefined) {
  LicenseInfo.setLicenseKey(key);
}

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#96b5f1'
    },
    secondary: {
      main: red[700],
    },
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#96b5f1'
    },
    secondary: {
      main: red[700],
    },
  },
});

let selectedTheme = lightTheme
const colorMode = localStorage.getItem("colorMode")

if (colorMode == "dark") {
  selectedTheme = darkTheme
}

root.render(
  <ThemeProvider theme={selectedTheme}>
    <AuthProvider>
      <CssBaseline />
        <App  />
    </AuthProvider>
  </ThemeProvider>
);
