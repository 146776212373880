import { Grid, IconButton, Link, Paper, Stack, Table, TableBody, TableCell, TableContainer, Typography, Box } from "@mui/material"
import { LookupResultDetail } from "../models/Lookup"
import React from "react"
import CloseIcon from '@mui/icons-material/Close';
import { Storefront } from "../models/StorefrontResponse";

interface Props {
    lookupResult: LookupResultDetail
    storefront: Storefront
    close(): void
}

export default function LookupDetails(props: Props) {

    const googleMapsLink = (latitude: string, longitude: string): string => {
        return `https://www.google.com/maps/?q=${latitude},${longitude}`
    }

    const formatCurrency = (value: string): string => {
        return formatter.format(parseFloat(value))
    }

    function formatPhoneNumber(phoneNumberString: string) {
        const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return (
        <Grid sx={{ height: 600, overflow: "scroll" }}>
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                <Typography sx={{ marginLeft: .5, marginTop: -.6, color: "#000000" }} variant='h6'>Lookup Details</Typography>
                <IconButton sx={{ marginTop: -.5, color: "#000000" }} size="small" type="button" onClick={() => props.close()} >
                    <CloseIcon />
                </IconButton>
            </Stack>
            <Grid sx={{ marginTop: 0 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small">
                        <TableBody>
                            <TableCell>Name:</TableCell>
                            <TableCell>{props.lookupResult.first} {props.lookupResult.last}</TableCell>
                        </TableBody>
                        {props.lookupResult.exact_age && props.lookupResult.exact_age.trim() != "" &&
                            <TableBody>
                                <TableCell>Age:</TableCell>
                                <TableCell>{props.lookupResult.exact_age}</TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.marital_status &&
                            <TableBody>
                                <TableCell>Marital Status:</TableCell>
                                <TableCell>{props.lookupResult.marital_status}</TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.est_income &&
                            <TableBody>
                                <TableCell>Estimated Income:</TableCell>
                                <TableCell>{props.lookupResult.est_income}</TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.net_worth &&
                            <TableBody>
                                <TableCell>Net Worth:</TableCell>
                                <TableCell>{props.lookupResult.net_worth}</TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.credit_rating &&
                            <TableBody>
                                <TableCell>Credit Rating:</TableCell>
                                <TableCell>{props.lookupResult.credit_rating}</TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.education &&
                            <TableBody>
                                <TableCell>Education:</TableCell>
                                <TableCell>{props.lookupResult.education}</TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.gender &&
                            <TableBody>
                                <TableCell>Gender:</TableCell>
                                <TableCell>{props.lookupResult.gender}</TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.presence_of_children && props.lookupResult.presence_of_children.trim() != "" &&
                            <TableBody>
                                <TableCell>Children Present:</TableCell>
                                <TableCell>{props.lookupResult.presence_of_children}</TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.number_of_children && props.lookupResult.number_of_children.trim() != "" &&
                            <TableBody>
                                <TableCell>Number of Children:</TableCell>
                                <TableCell>{props.lookupResult.number_of_children}</TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.occupation && props.lookupResult.occupation.trim() != "" &&
                            <TableBody>
                                <TableCell>Occupation:</TableCell>
                                <TableCell>{props.lookupResult.occupation}</TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.language &&
                            <TableBody>
                                <TableCell>Language:</TableCell>
                                <TableCell>{props.lookupResult.language}</TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.phone &&
                            <TableBody>
                                <TableCell>Phone:</TableCell>
                                <TableCell>
                                    <Stack direction="row">
                                        {formatPhoneNumber(props.lookupResult.phone)}
                                        {props.lookupResult.phone_type && <Typography variant="body2" sx={{ ml: .5 }}>| Phone Type: ({props.lookupResult.phone_type})</Typography>}
                                        {props.lookupResult.dnc && <Typography variant="body2" sx={{ ml: .5 }}>| DNC: {props.lookupResult.dnc}</Typography>}
                                    </Stack>
                                </TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.email &&
                            <TableBody>
                                <TableCell>Email:</TableCell>
                                <TableCell>{props.lookupResult.email}</TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.address &&
                            <TableBody>
                                <TableCell>Address:</TableCell>
                                <TableCell>
                                    <Stack>
                                        <Typography variant="body2">{props.lookupResult.address}</Typography>
                                        <Typography variant="body2">{props.lookupResult.city}, {props.lookupResult.state} {props.lookupResult.zip}</Typography>
                                    </Stack>
                                </TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.latitude && props.lookupResult.longitude &&
                            <TableBody>
                                <TableCell>Latitude/Longitude:</TableCell>
                                <TableCell>{props.lookupResult.latitude}, {props.lookupResult.longitude}
                                    <Link sx={{ ml: 1 }} target="_blank" href={googleMapsLink(props.lookupResult.latitude, props.lookupResult.longitude)}>View on Google Maps</Link>
                                </TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.property_type &&
                            <TableBody>
                                <TableCell>Property Type:</TableCell>
                                <TableCell>{props.lookupResult.property_type}</TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.homeowner &&
                            <TableBody>
                                <TableCell>Home Relationship:</TableCell>
                                <TableCell>{props.lookupResult.homeowner}</TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.year_built &&
                            <TableBody>
                                <TableCell>Year Built:</TableCell>
                                <TableCell>{props.lookupResult.year_built}</TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.lor &&
                            <TableBody>
                                <TableCell>Residency Length:</TableCell>
                                <TableCell>{props.lookupResult.lor} years</TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.most_recent_mortgage_amount &&
                            <TableBody>
                                <TableCell>Most Recent Mortgage Amount:</TableCell>
                                <TableCell>{formatCurrency(props.lookupResult.most_recent_mortgage_amount)}</TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.most_recent_mortgage_date &&
                            <TableBody>
                                <TableCell>Most Recent Mortgage Date:</TableCell>
                                <TableCell>{props.lookupResult.most_recent_mortgage_date}</TableCell>
                            </TableBody>
                        }
                        {props.lookupResult.loan_to_value &&
                            <TableBody>
                                <TableCell>Loan to Value:</TableCell>
                                <TableCell>{props.lookupResult.loan_to_value}</TableCell>
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
                {props.lookupResult.mortgage_details && props.lookupResult.mortgage_details.length > 0 &&
                    <Box>
                        {props.lookupResult.mortgage_details.map((mortgage) => (
                            <Box sx={{mt:1}} key={mortgage.id}>
                                <Typography sx={{ marginLeft: .5, marginTop: -.6, color: "#000000" }} variant='h6'>Mortgage Details</Typography>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} size="small">
                                        {mortgage.owner_name &&
                                            <TableBody>
                                                <TableCell>Owner Name:</TableCell>
                                                <TableCell>{mortgage.owner_name}</TableCell>
                                            </TableBody>
                                        }
                                        {mortgage.owner_name_2 &&
                                            <TableBody>
                                                <TableCell>Owner Name (secondary):</TableCell>
                                                <TableCell>{mortgage.owner_name_2}</TableCell>
                                            </TableBody>
                                        }
                                        {mortgage.owner_occupied &&
                                            <TableBody>
                                                <TableCell>Owner Occupied:</TableCell>
                                                <TableCell>{mortgage.owner_occupied}</TableCell>
                                            </TableBody>
                                        }
                                        {mortgage.trust &&
                                            <TableBody>
                                                <TableCell>Trust:</TableCell>
                                                <TableCell>{mortgage.trust}</TableCell>
                                            </TableBody>
                                        }
                                        {mortgage.property_address &&
                                            <TableBody>
                                                <TableCell>Property Address:</TableCell>
                                                <TableCell>
                                                    <Stack>
                                                        <Typography variant="body2">{mortgage.property_address}</Typography>
                                                        <Typography variant="body2">{mortgage.property_city}, {mortgage.property_state} {mortgage.property_zip}</Typography>
                                                    </Stack>
                                                </TableCell>
                                            </TableBody>
                                        }
                                        {props.lookupResult.home_value &&
                                            <TableBody>
                                                <TableCell>Home Value:</TableCell>
                                                <TableCell>{formatCurrency(props.lookupResult.home_value)}</TableCell>
                                            </TableBody>
                                        }
                                        {mortgage.property_latitude && mortgage.property_longitude &&
                                            <TableBody>
                                                <TableCell>Property Latitude/Longitude:</TableCell>
                                                <TableCell>{mortgage.property_latitude}, {mortgage.property_longitude}
                                                    <Link sx={{ ml: 1 }} target="_blank" href={googleMapsLink(mortgage.property_latitude, mortgage.property_longitude)}>View on Google Maps</Link>
                                                </TableCell>
                                            </TableBody>
                                        }
                                        {mortgage.income_estimated_household &&
                                            <TableBody>
                                                <TableCell>Household Income:</TableCell>
                                                <TableCell>{mortgage.income_estimated_household}</TableCell>
                                            </TableBody>
                                        }
                                        {mortgage.sale_date &&
                                            <TableBody>
                                                <TableCell>Sale Date:</TableCell>
                                                <TableCell>{mortgage.sale_date}</TableCell>
                                            </TableBody>
                                        }
                                        {mortgage.sale_price &&
                                            <TableBody>
                                                <TableCell>Sale Price:</TableCell>
                                                <TableCell>{formatCurrency(mortgage.sale_price)}</TableCell>
                                            </TableBody>
                                        }
                                        {mortgage.total_value_calculated &&
                                            <TableBody>
                                                <TableCell>Home Total Value:</TableCell>
                                                <TableCell>{formatCurrency(mortgage.total_value_calculated)}</TableCell>
                                            </TableBody>
                                        }
                                        {mortgage.appr_total_value &&
                                            <TableBody>
                                                <TableCell>Home Appraised Value:</TableCell>
                                                <TableCell>{formatCurrency(mortgage.appr_total_value)}</TableCell>
                                            </TableBody>
                                        }
                                        {mortgage.assd_total_value &&
                                            <TableBody>
                                                <TableCell>Home Assessed Value:</TableCell>
                                                <TableCell>{formatCurrency(mortgage.assd_total_value)}</TableCell>
                                            </TableBody>
                                        }
                                        {mortgage.mkt_total_value &&
                                            <TableBody>
                                                <TableCell>Home Market Value:</TableCell>
                                                <TableCell>{formatCurrency(mortgage.mkt_total_value)}</TableCell>
                                            </TableBody>
                                        }
                                        {mortgage.tax_amount &&
                                            <TableBody>
                                                <TableCell>Tax Amount:</TableCell>
                                                <TableCell>{formatCurrency(mortgage.tax_amount)}</TableCell>
                                            </TableBody>
                                        }
                                        {mortgage.purchase_1st_mortgage_amount &&
                                            <TableBody>
                                                <TableCell>1st Mortgage Amount:</TableCell>
                                                <TableCell>{formatCurrency(mortgage.purchase_1st_mortgage_amount)}</TableCell>
                                            </TableBody>
                                        }
                                        {mortgage.purchase_2nd_mortgage_amount &&
                                            <TableBody>
                                                <TableCell>2nd Mortgage Amount:</TableCell>
                                                <TableCell>{formatCurrency(mortgage.purchase_2nd_mortgage_amount)}</TableCell>
                                            </TableBody>
                                        }
                                        {mortgage.living_square_feet &&
                                            <TableBody>
                                                <TableCell>Living Square Feet:</TableCell>
                                                <TableCell>{mortgage.living_square_feet}</TableCell>
                                            </TableBody>
                                        }

                                        {mortgage.land_square_footage &&
                                            <TableBody>
                                                <TableCell>Land Square Feet:</TableCell>
                                                <TableCell>{mortgage.land_square_footage}</TableCell>
                                            </TableBody>
                                        }
                                        {mortgage.bedrooms &&
                                            <TableBody>
                                                <TableCell>Bedrooms:</TableCell>
                                                <TableCell>{mortgage.bedrooms}</TableCell>
                                            </TableBody>
                                        }
                                        {mortgage.total_baths &&
                                            <TableBody>
                                                <TableCell>Bathrooms:</TableCell>
                                                <TableCell>{mortgage.total_baths}</TableCell>
                                            </TableBody>
                                        }
                                        {mortgage.stories_number &&
                                            <TableBody>
                                                <TableCell>Stories:</TableCell>
                                                <TableCell>{mortgage.stories_number}</TableCell>
                                            </TableBody>
                                        }
                                    </Table>
                                </TableContainer>
                            </Box>
                        ))}
                    </Box>
                }

            </Grid>
        </Grid>
    )
}