import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { User, UsersResponse } from '../models/UsersResponse'

const apiURL =  APIEndpoint(EndpointType.Users);

export const useUsers = (refreshKey: number) => {
    const [users, setUsers] = useState<User[]>([])
    const [autocompleteUsers, setAutocompleteUsers] = useState<AutocompleteUser[]>([])
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {        
        setLoading(true)
        setError("")
        httpGet(apiURL)
            .then((data) => {
                const usersResponse = data as UsersResponse;
                
                if (usersResponse.status == "error") {
                    setError(usersResponse.errorMessage)
                    setUsers([])
                    return
                }

                if (usersResponse.users) {
                    setUsers(usersResponse.users);
                    setAutocompleteUsers(createAutocompleteUsers(usersResponse.users))
                } else {
                    const emptyUsers: User[] = [];
                    setUsers(emptyUsers);
                    const emptyAutocompleteUsers: AutocompleteUser[] = [];
                    setAutocompleteUsers(emptyAutocompleteUsers);
                }
                
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false))

    }, [refreshKey]);

    return { users, autocompleteUsers, loading, error };
};

function createAutocompleteUsers(users: User[]) {
    const autocompleteUsers: AutocompleteUser[] = [];

    for (const user of users) {
        const newAutocompleteUser: AutocompleteUser = {
            label: user.firstName + " " + user.lastName + " (" + user.email + ")",
            id: user.id
        };
        autocompleteUsers.push(newAutocompleteUser);
    }

    return autocompleteUsers.sort((a, b) => a.label.localeCompare(b.label));
}

export interface AutocompleteUser {
    label: string,
    id: string
}