import { JsonViewer } from '@textea/json-viewer'
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  title: string
  data: string | object | undefined
  close(): void
}

export default function DataViewer(props: Props) {
  return (
    <Box >
      <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
        <Typography variant='h6' sx={{color:"#000000"}}>{props.title}</Typography>
        <IconButton sx={{marginTop:-.5, color:"#000000"}} size="small" type="button" onClick={() => props.close() } >
            <CloseIcon />
        </IconButton>
      </Stack>

      <Box component={Paper} sx={{backgroundColor:"#edeff2", height:750, overflow: 'auto'}}>
        <JsonViewer sx={{margin:1}} value={props.data} />
      </Box>
    </Box>
    
  );
}
