import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Copyright from "../components/CopyRight";
import * as React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
import Title from '../components/Title';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Attribute, CognitoUser } from '../models/CognitoUsersResponse';
import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

const Users = () => {


  useEffect(() => {
    // Log Pageview with Hubspot
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', '/users']);
    _hsq.push(['trackPageView']);
  }, []);

  // call to the hook
  //const data: TApiResponse = useApiGet('https://jsonplaceholder.typicode.com/users');
  //const data: TApiResponse = useApiGet('https://pmoojp96eg.execute-api.us-west-2.amazonaws.com/prod/admin/users/*');

  //let users: User[] = new Array;
  //let cognitoUsersResponse: CognitoUserResponse = {};
  const users: CognitoUser[] = [];

  // if (data.loading) {
  //   return <LoadingSpinner />;
  // } else {
  //   if (data.status != 0) {
  //     //users = data.data as User[];
  //     let cognitoUsersResponse = data.data as CognitoUserResponse;
  //     users = cognitoUsersResponse.cognitoUsers.Users;
  //     //console.log('User Count: ' + users.length);
  //   }
  // }

  // function getUsers() {
  //   const data: TApiResponse = useApiGet('https://jsonplaceholder.typicode.com/users');
  // }

    function getAttributeValue(key: string, attributes: Attribute[]) {
      for (const attribute of attributes) {
        if (attribute.Name == key) {
          return attribute.Value
        }
      }

      return ""
    }


  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
         {/*Recent Products*/}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Title>Users</Title>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                  item
                  xs={12}
                >
                  <IconButton size="medium" type="button">
                    <RefreshIcon color="primary" />
                  </IconButton>
                </Grid>
                <React.Fragment>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Username</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((user) => (
                                <TableRow key={user.Username}>
                                    <TableCell>{getAttributeValue('name', user.Attributes)}</TableCell>
                                    <TableCell>{user.Username}</TableCell>
                                    <TableCell>{getAttributeValue('email', user.Attributes)}</TableCell>
                                    <TableCell>{user.UserStatus}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                  </React.Fragment>
              </Paper>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </Container>
  );
};

export default Users;