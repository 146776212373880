import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { Announcement, AnnouncementsResponse } from '../models/AnnouncementsResponse';

const apiURL =  APIEndpoint(EndpointType.Announcements);

export const useAnnouncements = (refreshKey: number) => {
    const [announcements, setAnnouncements] = useState<Announcement[]>([])
    const [announcementError, setError] = useState("")
    const [announcementLoading, setLoading] = useState(false)

    useEffect(() => {        
        setLoading(true)
        setError("")
        httpGet(apiURL)
            .then((data) => {
                const announcementsResponse = data as AnnouncementsResponse;
                
                if (announcementsResponse.status == "error") {
                    setError(announcementsResponse.errorMessage)
                    setLoading(false)
                    return
                }
                if (announcementsResponse.announcements) {
                    setAnnouncements(announcementsResponse.announcements);
                } else {
                    const emptyCount: Announcement[] = []
                    setAnnouncements(emptyCount)
                }

            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false))

    }, [refreshKey]);

    return { announcements, announcementLoading, announcementError };
};