import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { Company, CompaniesResponse } from '../models/CompaniesResponse'

const apiURL =  APIEndpoint(EndpointType.Companies);

export const useCompany = (companyID: string) => {
    const [company, setCompany] = useState<Company>()
    const [companyError, setError] = useState("")
    const [companyLoading, setLoading] = useState(false)

    useEffect(() => {        
        if (companyID == "") {
            return
        }
        setLoading(true)
        setError("")
        httpGet(apiURL + "/" + companyID)
            .then((data) => {
                const companiesResponse = data as CompaniesResponse;
                
                if (companiesResponse.status == "error") {
                    setError(companiesResponse.errorMessage)
                    return
                }
                if (companiesResponse.companies) {
                    if (companiesResponse.companies.length > 0) {
                        setCompany(companiesResponse.companies[0]);
                    }
                } else {
                    const emptyCompany = {} as Company
                    setCompany(emptyCompany);
                }
                
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false))

    }, [companyID]);

    return { company, companyLoading, companyError };
};