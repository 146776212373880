import * as React from "react";
import Container from "@mui/material/Container";
// import SwaggerUI from "swagger-ui-react";
// import "swagger-ui-react/swagger-ui.css";

const ApiDocs = () => {
    //const docURL = location.origin + "/docs/swagger.json"

    return (
        // <SwaggerUI url={docURL}  />
        <Container maxWidth="xl" sx={{ mt: 1, mb: 1 }}>
            <div>
                <iframe width="100%" height="1000" src="/apidocs/index.html" />
            </div>
        </Container>   
    )
}

export default ApiDocs;