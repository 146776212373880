import React, { useRef, useState, useEffect } from 'react';
import mapboxgl, { Map } from 'mapbox-gl'; 
import Grid from "@mui/material/Grid";
import { Storefront } from '../models/StorefrontResponse';
import { Box, Typography, Stack, Button, Drawer } from '@mui/material';
import { useLeadVisualizer } from '../hooks/useLeadVisualizer';
import { useGeoJsonVisualizer } from '../hooks/useGeoJsonVisualizer';
import useAuth from "../hooks/useAuth";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { format } from 'date-format-parse';
import { VisualizationOrder } from '../models/OrderVisualizationResponse';
import { Properties } from '../models/orderGeoJsonResponse';
import markerImage from "../assets/images/tsg_visualizer_house.png"
import PropertyDetails from '../components/PropertyDetails';
import { useNavigate } from "react-router-dom";
//import '../styles/globals.css';

interface CartProps {
  storefront: Storefront
}

const LeadsVisualizer = (props: CartProps) => {
  const navigate = useNavigate();
  const [customerID, setCustomerID] = useState("");
  const [orderID, setOrderID] = useState("");
  const [order, setOrder] = useState<VisualizationOrder>();
  const [mapMarkers, setMapMarkers] = useState<mapboxgl.Marker[]>([])
  const [listID, setListID] = useState("");
  const [overlay, setOverlay] = useState("lastName");
  const [dataRequestID, setRequestID] = useState("");
  const [showPropertyDetails, setShowPropertyDetails] = useState(false);
  const { user  } = useAuth();
  const { visualOrders} = useLeadVisualizer(customerID, 0)
  const { geoJSON} = useGeoJsonVisualizer(orderID, dataRequestID, 0)
  const map = useRef<Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const token = process.env.REACT_APP__MAPBOX_TOKEN;

    if (token != undefined) {
      //setMapboxAccessToken(token);
      mapboxgl.accessToken = token;
    }

    console.log("reload!")
  })

  useEffect(() => {
    try {
      setCustomerID(user.username)
    }
    catch(e) {
      console.log(e)
      navigate("/signin")
    }
  }, [])

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const getPropertyValue = (propertyName: string, properties: Properties) => {
    let value = ""

    if (propertyName == "") {
      return properties.lastName;
    }

    if (!properties) {
      return ""
    }

    switch (propertyName) {
      case "address":
        value = properties.address;
        break;
      case "age":
        value = properties.age;
        break;
      case "homeValue":
        if (parseInt(properties.homeValue)) {
          value = formatter.format(parseInt(properties.homeValue));
        }
        break;
      case "education":
        value = properties.education;
        break;
      case "income":
        value = properties.income;
        break;
      case "lastName":
        value = properties.lastName;
        break;
      case "loanToValue":
        value = properties.loanToValue;
        break;
      case "maritalStatus":
        value = properties.maritalStatus;
        break;
      case "mortgageAmount":
        value = properties.mortgageAmount;
        break;
      case "mortgageDate":
        value = properties.mortgageDate;
        break;
      case "name":
        value = properties.name;
        break;
      case "presenceOfChildren":
        value = properties.presenceOfChildren;
        break;
      case "yearBuilt":
        value = properties.yearBuilt;
        break;
      case "years":
        value = properties.years;
        break;
      default:
        console.log("invalid property name specified: " + propertyName);
    }
    console.log(propertyName + " = " + value)
    if (value == undefined || value == null) {
      return ""
    }

    return value.trim()
  }

  useEffect(() => {
    const tempMapMarkers: mapboxgl.Marker[] = []
    for (const feature of geoJSON) {
      const el = document.createElement('div');
      el.className = 'vizMarker';

      const elText = document.createElement('div');
      elText.setAttribute("data-feature", JSON.stringify(feature.properties))
      elText.innerText = getPropertyValue(overlay, feature.properties);
      elText.className = 'vizMarkerText';
      
      el.appendChild(elText)

      el.style.backgroundImage = `url('${markerImage}')`;
      const lonlat = new mapboxgl.LngLat(feature.geometry.coordinates[0], feature.geometry.coordinates[1])
      if (map.current != null) {
        const newMarker = new mapboxgl.Marker(el).setLngLat(lonlat)
        newMarker.getElement().addEventListener('click', handleOpenPropertyDetails)
        newMarker.addTo(map.current)
        tempMapMarkers.push(newMarker)
      }
    }

    setMapMarkers(tempMapMarkers)
    if (geoJSON.length > 0) {
      map.current?.flyTo({ center: [geoJSON[0].geometry.coordinates[0], geoJSON[0].geometry.coordinates[1]], essential: true, zoom: 11.7});
    }
  }, [geoJSON])

  useEffect(() => {
    const mapboxStyle = "mapbox://styles/danpenn/clraw3rsv002601recroybxas"
    
    if (map.current) return; // initialize map only once
    
    map.current = new mapboxgl.Map({
      container: mapContainerRef.current as HTMLDivElement,
      style: mapboxStyle,
      center: [-98.5795, 39.828175],
      zoom: 3.5
  });

  
    map.current.on('load', function() {
    //   // The feature-state dependent fill-opacity expression will render the hover effect
    //   // when a feature's hover state is set to true.
      if (map.current == null) {
        return
      }


    //   map.current.addSource("zips", {
    //     "type": "vector",
    //     "url": "mapbox://danpenn.b5k2x2yo"
    //   });

    //   map.current.addLayer({
    //     'id': 'zip-fills',
    //     'type': 'fill',
    //     'source': 'zips',
    //     'source-layer': 'zip_boundaries_20231117',
    //     'layout': {},
    //     'paint': {
    //     'fill-color': '#24fc03',
    //     'fill-opacity': [
    //     'case',
    //     ['boolean', ['feature-state', 'hover'], false],
    //     0.15,
    //     0
    //     ]
    //     }
    //   });
    // });

    // // When the user moves their mouse over the zip boundaries layer, we'll update the
    // // feature state for the feature under the mouse.
    // map.current.on('mousemove', 'zip-fills', function(e: any) {
    //   if (e.features == undefined || map.current == null) {
    //     return
    //   }      

    //   if (e.features.length > 0) {
    //     if (hoveredPolygonId !== null) {
    //       map.current.setFeatureState(
    //         { source: 'zips', id: hoveredPolygonId, sourceLayer: sourceLayer },
    //         { hover: false }
    //       );
    //     }

    //     hoveredPolygonId = e.features[0].id;
    //     map.current.setFeatureState(
    //       { source: 'zips', id: hoveredPolygonId, sourceLayer: sourceLayer },
    //       { hover: true }
    //     );
    //   }
    // });

    // // When the mouse leaves the state-fill layer, update the feature state of the
    // // previously hovered feature.
    // map.current.on('mouseleave', 'zip-fills', function(e: any) {
    //   if (e.features == undefined || map.current == null) {
    //     return
    //   } 

    //   if (hoveredPolygonId !== null) {
    //     map.current.setFeatureState(
    //       { source: 'zips', id: hoveredPolygonId, sourceLayer: sourceLayer },
    //       { hover: false }
    //     );
    //   }
    //   hoveredPolygonId = null;
    // });

    // map.current.on('click', 'zip-fills', function(e: any) {
    //   setTooltipIsOpen(false)
    //   if (e.features == undefined || map.current == null) {
    //     return
    //   } 
    //   //console.log("zip code:", e.features[0].properties.ZIP)
    //   //setSelectedZip(e.features[0].properties.ZIP)
    //   props.selectedZipChangedCallback(e.features[0].properties.ZIP)
    // });

    // map.current.on('click', 'zipcodecounts', function(e: any) {
    //   if (e.features == undefined || map.current == null) {
    //     return
    //   } 

    //   props.selectedZipChangedCallback(e.features[0].properties.zip)
    //   //console.log("zip code:", e.features[0].properties.zip)
    //   //setSelectedZip(e.features[0].properties.zip)
    });

  },[]);

  const getShortDate = (dateString: string | undefined) => {
    if (dateString != undefined) {
        const utcDate = new Date(dateString);
        return format(utcDate, "MM/DD/YY")
    }
  }

  const handleOrderIDChanged = (event: SelectChangeEvent) => {
    setOrderID(event.target.value)

    if (event.target.value == "") {
      return
    }

    for (const vOrder of visualOrders) {
      if (event.target.value == vOrder.id) {
        setOrder(vOrder)
      }
    }
  }

  const handleLeadListChanged = (event: SelectChangeEvent) => {
    setListID(event.target.value)
  }

  const handleVisualizationButtonClick = () => {
    setRequestID(listID)
  }

  const handleOverlayChanged = (event: SelectChangeEvent) => {
    setOverlay(event.target.value)

    const tempMapMarkers = mapMarkers

    console.log("markers length:", tempMapMarkers.length)
    // Update any existing markers
    for (const marker of tempMapMarkers) {
      const markerDiv = marker.getElement()
      const nodes = markerDiv.children
      if (nodes.length == 1) {
        const data = nodes[0].getAttribute("data-feature")
        if (data != null) {
          const properties = JSON.parse(data)
          nodes[0].textContent = getPropertyValue(event.target.value, properties)
        } else {
          console.log("data is null")
        }
      } else {
        console.log("no nodes")
      }
    }

    setMapMarkers(tempMapMarkers)
  }

  const handleClearAllButtonClick = () => {
    
    mapMarkers.forEach((marker) => marker.remove())
    setMapMarkers([])
  }

  const handleOpenPropertyDetails = () => {
    setShowPropertyDetails(true)
  }

  // const handleClosePropertyDetails = () => {
  //   setShowPropertyDetails(false)
  // }

  return (
    <div>
      <Grid sx={{marginBottom: 1, marginLeft:2}}>
        <Typography variant="h5">Lead Visualizer</Typography>
        <Stack direction="row" sx={{mt:1}}>
          <Box sx={{width:270}}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Orders</InputLabel>
              <Select
                value={orderID}
                label="Orders"
                onChange={handleOrderIDChanged}
              >
                { visualOrders.map((order) => (
                  <MenuItem value={order.id} key={order.id}>{getShortDate(order.date)}: {order.description}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{width:270, ml:2}}>
            <FormControl fullWidth>
              <InputLabel id="leadLists">Lead Lists</InputLabel>
              <Select
                value={listID}
                label="Lead Lists"
                onChange={handleLeadListChanged}
              >
                { order?.dataRequests.map((dataRequest) => (
                  <MenuItem value={dataRequest.id} key={dataRequest.id}>{dataRequest.description}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{width:270, ml:2}}>
            <FormControl fullWidth>
              <InputLabel id="leadLists">Overlay</InputLabel>
              <Select
                value={overlay}
                label="Overlay"
                onChange={handleOverlayChanged}
              >
                  <MenuItem value="address" key="address">Address</MenuItem>
                  <MenuItem value="age" key="age">Owner Age</MenuItem>
                  <MenuItem value="education" key="education">Education</MenuItem>
                  <MenuItem value="homeValue" key="homeValue">Home Value</MenuItem>
                  <MenuItem value="income" key="income">Income</MenuItem>
                  <MenuItem value="lastName" key="lastName">Last Name</MenuItem>
                  <MenuItem value="loanToValue" key="loanToValue">Loan to Value</MenuItem>
                  <MenuItem value="maritalStatus" key="maritalStatus">Marital Status</MenuItem>
                  <MenuItem value="mortgageAmount" key="mortgageAmount">Mortgage Amount</MenuItem>
                  <MenuItem value="mortgageDate" key="mortgageDate">Mortgage Date</MenuItem>
                  <MenuItem value="name" key="name">Name</MenuItem>
                  <MenuItem value="presenceOfChildren" key="presenceOfChildren">Presence of Children</MenuItem>
                  <MenuItem value="yearBuilt" key="yearBuilt">Year Built</MenuItem>
                  <MenuItem value="years" key="years">Years in Home</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Button variant="contained" sx={{ml:2, width:110}} onClick={handleVisualizationButtonClick}>Visualize</Button>
          <Button variant="contained" sx={{ml:2, width:110}} onClick={handleClearAllButtonClick}>Clear All</Button>
        </Stack>
      </Grid>
      <Box ref={mapContainerRef} className="map-container" />
      <Drawer open={showPropertyDetails} variant="persistent" anchor="right">
        <Box sx={{width: 340, backgroundColor: props.storefront?.activeColors.addProductForm.formBackground, height:'100%'}}>
          <PropertyDetails/>
        </Box>
      </Drawer>
    </div>
  );
}

export default LeadsVisualizer;
