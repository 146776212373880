import React, {useState} from 'react';
import Typography from '@mui/material/Typography';
import { useOrder } from '../hooks/useOrder';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import LoadingSpinner from './LoadingSpinner';
import ErrorFallBack from './ErrorFallBack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import DataViewer from './DataViewer';
import Modal from '@mui/material/Modal';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CopyToClipboard from './CopyToClipboard';
import { Tooltip } from '@mui/material';
import { LocalTime } from '../utils/localTime';

interface Props {
  orderID: string
  showFullDetails: boolean
  close(): void
}

export default function OrderDetails(props: Props) {
    const { order, orderLoading, orderError } = useOrder(props.orderID, false);
    const [ openStripeDetails, setOpenStripeDetails ] = useState(false);
    const [ openStripeInvoice, setOpenStripeInvoice ] = useState(false);
    const [ openFullDetails, setOpenFullDetails ] = useState(false);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    function makeDefined(input: number | undefined) {
        if (input == undefined) {
          return 0
        }
    
        return input
    }

    function prettyZipDisplay(zips: string[]): string {
        if (zips == null || zips.length == 0) {
          return ''
        }
       
        return 'Zip Codes: ' + zips.join(', ')
    }

    const orderDetailsStyle = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: '#ffffff',
        border: '1px solid #5c5c5e',
        boxShadow: 24,
        p: 1,
        height: 800,
    };

    const getReceiptLink = (receiptURL: string | undefined) => {
        if (receiptURL == undefined || receiptURL == "") {
            return <p>Pending...</p>
        }

        return <a href={receiptURL}>Download Receipt</a>
    }

    const handleStripeDetailsClose = () => setOpenStripeDetails(false);
    const handleStripeInvoiceClose = () => setOpenStripeInvoice(false);
    const handleFullDetailsClose = () => setOpenFullDetails(false);

  return (
    <Grid sx={{height:600, overflow:"scroll"}}>
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
            <Typography sx={{marginLeft:.5, marginTop:-.6, color:"#000000"}} variant='h6'>Order Details</Typography>
            <IconButton sx={{marginTop:-.5, color:"#000000"}} size="small" type="button" onClick={() => props.close() } >
                <CloseIcon />
            </IconButton>
        </Stack>
        <Grid sx={{ marginTop: 0}}>
            <Stack>
            <React.Fragment>
            {orderLoading
                ? <LoadingSpinner title="Loading data..." textColor="#0352fc" />
                : orderError
                ? <ErrorFallBack message={orderError} />
                : 
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>Order #:</TableCell>
                            <TableCell>{order?.id} <CopyToClipboard value={order?.id} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Order Date:</TableCell>
                            <TableCell>
                                <Tooltip title={LocalTime(order?.orderDate)} placement="bottom-start">
                                    <Typography variant="body2">{order?.orderDate}</Typography>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Description:</TableCell>
                            <TableCell>{order?.description}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Amount:</TableCell>
                            <TableCell>{order && formatter.format(makeDefined(order.totalPrice))}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Receipt:</TableCell>
                            <TableCell>{getReceiptLink(order?.receiptURL)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Products:</TableCell>
                            <TableCell>
                            <Stack>
                                {order?.products && order?.products!.map((product) => (
                                <Box key={product.product.id}>
                                    { product.product.productType == "processing"
                                      ? <Stack direction="row" spacing={2}>
                                          <Box>{product.product.name}</Box>
                                        </Stack>
                                      : <Box>
                                            <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />} key={order.id}>
                                                <Box>{product.product.name}</Box>
                                                <Box>{product.quantity} leads</Box>
                                                {
                                                product.postalCodes && product.postalCodes.length == 1
                                                ? <Box>Zip Code: {product.postalCodes[0]}</Box>
                                                : <Box />
                                                }
                                                <Box>Price/Lead: {product.product.priceInfo.price}</Box>
                                            </Stack>
                                            {
                                                product.postalCodes && product.postalCodes.length > 1
                                                ? <Stack direction="row" spacing={3}><Box></Box><Box>{prettyZipDisplay(product.postalCodes)}</Box></Stack>
                                                : <Box />
                                            }
                                        </Box>
                                    }
                                </Box>
                                ))}
                            </Stack>      
                            </TableCell>
                        </TableRow>
                        {props.showFullDetails &&
                            <TableRow>
                                <TableCell>Customer:</TableCell>
                                <TableCell>{order?.customerName} ({order?.email})</TableCell>
                            </TableRow>
                        }
                        {props.showFullDetails &&
                            <TableRow>
                                <TableCell>Customer ID:</TableCell>
                                <TableCell>{order?.customerID}<CopyToClipboard value={order?.customerID} /></TableCell>
                            </TableRow>
                        }
                        {props.showFullDetails &&
                            <TableRow>
                                <TableCell>HubSpot Deal ID:</TableCell>
                                <TableCell>{order?.hubSpotDealID}<CopyToClipboard value={order?.hubSpotDealID} /></TableCell>
                            </TableRow>
                        }
                        {props.showFullDetails &&
                            <TableRow>
                                <TableCell>Shopping Cart ID:</TableCell>
                                <TableCell>{order?.shoppingCartID}<CopyToClipboard value={order?.shoppingCartID} /></TableCell>
                            </TableRow>
                        }
                        {props.showFullDetails &&
                            <TableRow>
                                <TableCell>Fulfillment Date:</TableCell>
                                <TableCell>
                                    <Tooltip title={LocalTime(order?.fulfillment?.createdAt)} placement="bottom-start">
                                        <Typography variant="body2">{order?.fulfillment?.createdAt}</Typography>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        }
                        {props.showFullDetails &&
                            <TableRow>
                                <TableCell>Order Source:</TableCell>
                                <TableCell>
                                {order?.manualOrderDetails != undefined
                                    ? <Box>{order.manualOrderDetails.salesAssociateName}</Box>
                                    : <div />
                                }
                                {order?.orderSource}
                                </TableCell>
                            </TableRow>
                        }
                        {props.showFullDetails &&
                            <TableRow>
                                <TableCell>Storefront Name:</TableCell>
                                <TableCell>{order?.storefront}</TableCell>
                            </TableRow>
                        }
                        {props.showFullDetails &&
                            <TableRow>
                                <TableCell>Download Timestamps:</TableCell>
                                <TableCell>
                                <Stack>
                                    { order?.fulfillment?.downloads?.map((downloads) => (
                                    <Stack direction="column" key={downloads}>
                                        <Box>{downloads}</Box>
                                    </Stack>
                                    ))}
                                </Stack>      
                                </TableCell>
                            </TableRow>
                        }
                        {props.showFullDetails && order?.tsgStripeOrderDetails &&
                            <TableRow>
                                <TableCell>Stripe Order Details:</TableCell>
                                <TableCell>
                                    <Stack>
                                        <IconButton sx={{width:120}} onClick={() => {setOpenStripeDetails(true)}}>
                                            <ArticleOutlinedIcon />
                                            <Typography variant="body2">Stripe JSON</Typography>
                                        </IconButton>
                                    </Stack>
                                    <Modal open={openStripeDetails} onClose={handleStripeDetailsClose}>
                                        <Box sx={orderDetailsStyle} >
                                            <DataViewer title='Stripe Order Details' data={order?.tsgStripeOrderDetails} close={() => {setOpenStripeDetails(false)}} />
                                        </Box>
                                    </Modal>
                                </TableCell>
                            </TableRow>
                        }
                        {props.showFullDetails && order?.tsgStripeInvoice != undefined &&
                            <TableRow>
                                <TableCell>Stripe Invoice:</TableCell>
                                <TableCell>
                                    <Stack  justifyContent="left" alignItems="flex-start">
                                        <IconButton sx={{width:120}} onClick={() => {setOpenStripeInvoice(true)}}>
                                            <ArticleOutlinedIcon />
                                            <Typography variant="body2">Stripe JSON</Typography>
                                        </IconButton>
                                    </Stack>
                                    <Modal open={openStripeInvoice} onClose={handleStripeInvoiceClose}>
                                        <Box sx={orderDetailsStyle}>
                                            <DataViewer title='Stripe Invoice' data={order?.tsgStripeInvoice} close={() => {setOpenStripeInvoice(false)}} />
                                        </Box>
                                    </Modal>
                                </TableCell>
                            </TableRow>
                        }
                        {props.showFullDetails && order?.shopifyOrderDetails &&
                            <TableRow>
                                <TableCell>Shopify Order Details:</TableCell>
                                <TableCell>
                                    <Stack  justifyContent="left" alignItems="flex-start">
                                        <IconButton sx={{width:120}} onClick={() => {setOpenFullDetails(true)}}>
                                            <ArticleOutlinedIcon />
                                            <Typography variant="body2" >Stripe JSON</Typography>
                                        </IconButton>
                                    </Stack>
                                    <Modal open={openStripeDetails} onClose={handleStripeDetailsClose}>
                                        <Box sx={orderDetailsStyle} >
                                            <DataViewer title='Stripe Order Details' data={order?.shopifyOrderDetails} close={() => {setOpenStripeDetails(false)}} />
                                        </Box>
                                    </Modal>
                                </TableCell>
                            </TableRow>
                        }
                        {props.showFullDetails &&
                            <TableRow>
                                <TableCell>Full Order Details:</TableCell>
                                <TableCell>
                                    <Stack justifyContent="left" alignItems="flex-start">
                                        <IconButton sx={{width:120}} onClick={() => {setOpenFullDetails(true)}}>
                                            <ArticleOutlinedIcon />
                                            <Typography variant="body2" >Order JSON</Typography>
                                        </IconButton>
                                    </Stack>
                                    <Modal open={openFullDetails} onClose={handleFullDetailsClose}>
                                        <Box sx={orderDetailsStyle} >
                                            <DataViewer title='Order Details' data={order} close={() => {setOpenFullDetails(false)}} />
                                        </Box>
                                    </Modal>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                    </Table>
                </TableContainer>
                }
            </React.Fragment>
            </Stack>
        </Grid>
    </Grid>
    
  );
}
