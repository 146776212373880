import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { PeriodTimeRange, QueryAPILog, QueryApiLogsResponse } from '../models/APILogs';

const apiURL =  APIEndpoint(EndpointType.Query) + "/apilogs";

export const useAPILogs = (companyID: string, timeRange: PeriodTimeRange) => {
    const [logs, setLogs] = useState<QueryAPILog[]>()
    const [logsError, setLogsError] = useState("")
    const [logsLoading, setLogsLoading] = useState(false)
    
    useEffect(() => {
        if (companyID === "" || timeRange.start === undefined || timeRange.end === undefined) {
            return
        }
        if (companyID === "" || timeRange.start === "" || timeRange.end === "") {
            return
        }

        console.log("fetching logs")
        setLogsLoading(true)
        setLogsError("")
        const url = `${apiURL}?company_id=${companyID}&start_date=${timeRange.start}&end_date=${timeRange.end}`
        httpGet(url)
            .then((data) => {
                const response = data as QueryApiLogsResponse;
                
                if (response.status == "error") {
                    setLogsError(response.errorMessage)
                    setLogs([])
                } else {
                    if (response.apiLogs && response.apiLogs.length > 0) {
                        setLogs(response.apiLogs);
                    } else {
                        setLogs([])
                    }
                }
            })
            .catch((error) => setLogsError(error))
            .finally(() => setLogsLoading(false))

    }, [companyID, timeRange]);

    return { logs, logsLoading, logsError };
};
