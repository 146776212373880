import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Copyright from "../components/CopyRight";
import * as React from "react";
import Stack from '@mui/material/Stack';
import { useAnnouncements } from '../hooks/useAnnouncements';
import { useEffect, useState } from 'react';
import Title from "../components/Title";
import IconButton from "@mui/material/IconButton";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorFallBack from "../components/ErrorFallBack";
import { DataGrid, GridValueGetterParams, GridRowId } from '@mui/x-data-grid';
import { format, parse } from 'date-format-parse';
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { httpDelete, httpPost, APIEndpoint, EndpointType } from "../utils/apiService";
import { AnnouncementsResponse, Announcement } from "../models/AnnouncementsResponse"
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import useAuth from "../hooks/useAuth";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

const ManageAnnouncements = () => {
    const [refreshKey, setRefreshKey] = useState(0);
    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
    const [description, setDescription] = useState("");
    const [link, setLink] = useState("");
    const { announcements, announcementLoading, announcementError } = useAnnouncements(refreshKey)
    const columns = [
        { field: 'id', headerName: 'ID', width: 80 },
        { field: 'description', headerName: 'Description', editable: true, width: 400 },
        { field: 'link', headerName: 'Link', editable: true, width: 400 },
        { field: 'createdBy', headerName: 'Created By', width: 125 },
        { field: 'createdAt', headerName: 'Date', width: 85, valueGetter: (params: GridValueGetterParams) => {
            return getShortDate(params.value)
          }, },
      ]
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMsg, setSnackbarMsg] = useState("")
    const { user  } = useAuth();
    const [showCreateSubmitWarning, setShowCreateSubmitWarning] = useState("none")
    const [createSubmitWarningMsg, setCreateSubmitWarningMsg] = useState("")

      useEffect(() => {
        // Log Pageview with Hubspot
        const _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', '/ManageAnnouncements']);
        _hsq.push(['trackPageView']);
      }, []);

      const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackbar(false);
      };

      const snackbarAction = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

      const descriptionChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setDescription(event.target.value)
      }

      const linkChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setLink(event.target.value)
      }

    function getShortDate(dateString: string | undefined) {
        if (dateString != undefined) {
          const dt = parse(dateString, 'YYYY-MM-DDTHH:mm:ss');
          return format(dt, "MM/DD/YY")
        }
      }

      function deleteAnnouncements() {
        if (selectionModel.length == 0) {
            return
        }

        for (const item of selectionModel) {
          const apiURL =  APIEndpoint(EndpointType.Announcements) + "/" + item
            httpDelete(apiURL)
            .then((data) => {
                const announcementsResponse = data as AnnouncementsResponse;
                
                if (announcementsResponse.status == 'error') {
                    console.log("Error deleting announcement: " + announcementsResponse.errorMessage)
                } else {
                  setSnackbarMsg("Announcement deleted successfully!")
                  setOpenSnackbar(true)
                  setRefreshKey(refreshKey+1)
                }
            })
            .catch((error) => { 
              setSnackbarMsg(error.message)
              setOpenSnackbar(true)
            });
        }
      }

      function createAnnouncement() {
        if (description.trim() == "") {
            setCreateSubmitWarningMsg("A description must be specified")
            setShowCreateSubmitWarning("inline")
            return
        }

        if (link.trim() == "") {
            setCreateSubmitWarningMsg("A link must be specified")
            setShowCreateSubmitWarning("inline")
            return
        }

        const newAnnouncement: Announcement = {
            id: "",
            description: description,
            link: link,
            createdBy: user.attributes.name,
            createdAt: "",
            modifiedAt: ""
          }

        const apiURL =  APIEndpoint(EndpointType.Announcements)
        httpPost(apiURL, JSON.stringify(newAnnouncement))
        .then((data) => {
            const announcementsResponse = data as AnnouncementsResponse;
            
            if (announcementsResponse.status == 'error') {
                console.log("Error creating announcement: " + announcementsResponse.errorMessage)
            } else {
              setSnackbarMsg("Announcement created successfully!")
              setOpenSnackbar(true)
              setRefreshKey(refreshKey+1)
            }
        })
        .catch((error) => { 
          setSnackbarMsg(error.message)
          setOpenSnackbar(true)
        });

        setShowCreateSubmitWarning("none")
        
      }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
                <React.Fragment>
                    <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={2}
                            sx={{marginBottom: 1}}
                        >
                        <Title>Announcements</Title>
                        <Button size="small" variant="outlined" onClick={deleteAnnouncements} sx={{marginLeft: 1}}>
                            <DeleteForeverIcon color="primary" />Delete
                        </Button>
                    </Stack>
                    <Grid>
                        {announcementLoading
                        ? <LoadingSpinner title="Loading announcements..." textColor="#0352fc" />
                        : announcementError
                        ? <ErrorFallBack message={announcementError} />
                        :
                        <DataGrid
                        rows={announcements}
                        columns={columns}
                        hideFooterSelectedRowCount
                        hideFooter
                        checkboxSelection
                        rowSelectionModel={selectionModel}
                        initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                        }}
                        sx={{"& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                            display: "none"
                          }}}
                        pageSizeOptions={[5, 10, 25, 50]}
                        onRowSelectionModelChange={(ids) => {
                            if (ids.length > 1) {
                                const selectionSet = new Set(selectionModel);
                                const result = ids.filter((s) => !selectionSet.has(s));
                      
                                setSelectionModel(result);
                              } else {
                                setSelectionModel(ids);
                              }
                          }}
                    />
                        }
                    </Grid>
                    </React.Fragment>
          </Paper>
          <Paper               sx={{
                p: 2,
                display: "flex",
                flexDirection: "column", marginTop: 2
              }}>
            <Grid item xs={12}>
                <Title>Create New Announcement</Title>
                <TextField id="outlined-basic" label="Description" variant="outlined" size="small" required onChange={descriptionChanged} value={description} fullWidth />
                <TextField id="outlined-basic" label="More Info URL" variant="outlined" size="small" required onChange={linkChanged} value={link} fullWidth sx={{marginTop: 1}} />
                <Button variant="contained" color="primary" onClick={createAnnouncement} sx={{marginTop: 1}} >Create Announcement</Button>
                <Box display={showCreateSubmitWarning}>
                      <Alert severity="warning">{createSubmitWarningMsg}</Alert>
                </Box>
            </Grid>
          </Paper>
        </Grid>
        <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={snackbarMsg}
            action={snackbarAction}
        />
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </Container>
  );
};

export default ManageAnnouncements;
