import { useEffect, useState } from 'react';
import { APIEndpoint, EndpointType } from "../utils/apiService";
import axios from 'axios';
import { FileUploadDetail, FileUploadResponse } from '../models/DataEnhancement';

export const useFileUpload = (formData: FormData, startUpload: boolean, customerID: string, accessToken: string) => {
    const [uploadProgress, setUploadProgress] = useState(0)
    const [uploadError, setUploadError] = useState("")
    const [uploadDataFileSummary, setUploadDataFileSummary] = useState<FileUploadDetail[]>()
    const [uploadCompleted, setUploadCompleted] = useState(false)

    function parseURLMeat(url: string): string {
        const idx = url.indexOf(".amazonaws.com")

        if (idx < 0) {
            return url
        }

        return url.substring(idx + 14)
    }

    useEffect(() => {
        if (!startUpload) {
            return
        }

        setUploadCompleted(false)
        setUploadProgress(1)
        setUploadError("")
        setUploadDataFileSummary(undefined)

        try {
            setUploadCompleted(false)
            const apiURL = APIEndpoint(EndpointType.DataEnhancement) + "/upload?customerID=" + customerID
            console.log('[FILEUPLOAD] ' + parseURLMeat(apiURL));

            axios.post(apiURL, formData, {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    if (total != undefined) {
                        const percentage = Math.floor((loaded * 100) / total);
                        setUploadProgress(percentage);
                    }
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': accessToken,
                }
            }).then((res) => {
                setUploadProgress(100);
                const fileUploadResponse = res.data as FileUploadResponse

                if (fileUploadResponse.status == "error") {
                    setUploadError(fileUploadResponse.errorMessage)
                } else {
                    setUploadDataFileSummary(fileUploadResponse.fileUploadDetails)
                }

            }).catch((error) => {
                setUploadError("failed to upload file - " + error)
                setUploadCompleted(true)
            }).finally(() => {
                setUploadCompleted(true);
            });
        } catch (error) {
            setUploadError("failed to upload file")
            setUploadCompleted(true)
        }
    }, [startUpload]);

    return { uploadProgress, uploadError, uploadDataFileSummary, uploadCompleted };
}