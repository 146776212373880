import { useEffect, useState } from 'react';
import { APIEndpoint, EndpointType } from "../utils/apiService";
import axios from 'axios';
import { SuppressionList, SuppressionListResponse } from '../models/Suppression';

export const useSuppressionFileUpload = (formData: FormData, startUpload: boolean, referenceID: string, accessToken: string) => {
    const [suppressionUploadProgress, setSuppressionUploadProgress] = useState(0)
    const [suppressionUploadError, setSuppressionUploadError] = useState("")
    const [suppressionUploadList, setSuppressionUploadList] = useState<SuppressionList>()
    const [suppressionUploadCompleted, setSuppressionUploadCompleted] = useState(false)

    function parseURLMeat(url: string): string {
        const idx = url.indexOf(".amazonaws.com")

        if (idx < 0) {
            return url
        }

        return url.substring(idx + 14)
    }

    useEffect(() => {
        if (!startUpload) {
            return
        }

        setSuppressionUploadCompleted(false)
        setSuppressionUploadProgress(1)
        setSuppressionUploadError("")
        setSuppressionUploadList(undefined)

        try {
            setSuppressionUploadCompleted(false)
            const apiURL = APIEndpoint(EndpointType.Suppressions) + "/upload?reference-id=" + referenceID
            console.log('[SUPPRESSION FILEUPLOAD] ' + parseURLMeat(apiURL));

            axios.post(apiURL, formData, {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    if (total != undefined) {
                        const percentage = Math.floor((loaded * 100) / total);
                        setSuppressionUploadProgress(percentage);
                    }
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': accessToken,
                }
            }).then((res) => {
                setSuppressionUploadProgress(100);
                const suppressionResponse = res.data as SuppressionListResponse

                if (suppressionResponse.status == "error") {
                    setSuppressionUploadError(suppressionResponse.errorMessage)
                } else {
                    if (suppressionResponse.suppressions.length > 0) {
                        setSuppressionUploadList(suppressionResponse.suppressions[0])
                    }
                }

            }).catch((error) => {
                setSuppressionUploadError("failed to upload file - " + error)
                setSuppressionUploadCompleted(true)
            }).finally(() => {
                setSuppressionUploadCompleted(true);
            });
        } catch (error) {
            setSuppressionUploadError("failed to upload file")
            setSuppressionUploadCompleted(true)
        }
    }, [startUpload]);

    return { suppressionUploadProgress, suppressionUploadError, suppressionUploadList, suppressionUploadCompleted };
}