import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Copyright from "../components/CopyRight";
import * as React from "react";
import useAuth from "../hooks/useAuth";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Title from '../components/Title';
import { useEffect, useState } from "react";
import { useUser } from "../hooks/useUser";
import CopyToClipboard from "../components/CopyToClipboard";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

const Reports = () => {
    const { user  } = useAuth();
    const [userID, setUserID] = useState("")
    const { customer } = useUser(userID);

    useEffect(() => {
      // Log Pageview with Hubspot
      const _hsq = window._hsq = window._hsq || [];
      _hsq.push(['setPath', '/profile']);
      _hsq.push(['trackPageView']);
    }, []);

    useEffect(() => {
      setUserID(user.username)
    }, [user])

    function Role() {
        try {
        if (user.signInUserSession.accessToken.payload['cognito:groups'] != undefined) {
          if (user.signInUserSession.accessToken.payload['cognito:groups'].length > 0) {
              if (user.signInUserSession.accessToken.payload['cognito:groups'].length === 1) {
                  return user.signInUserSession.accessToken.payload['cognito:groups'][0];
              }
          }
        }

        if (String(user.attributes.email).toLowerCase().includes("@theshare.group")) {
          return "Employee";
        }

        } catch (err) {
            console.log("error: " + err)
        }
        return "Customer"
    }

    function Company() {
      if (customer?.companyID == "") {
        return "No company association"
      } else {
        return "ID=" + customer?.companyID
      }
    }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            {/* Search */}
            <Title>Profile</Title>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>{user.username}<CopyToClipboard value={user.username} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>{user.attributes.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell>{user.attributes.email}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Phone</TableCell>
                            <TableCell>{customer?.phone}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Role</TableCell>
                            <TableCell><Role /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Storefront</TableCell>
                            <TableCell>{customer?.storefrontName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Company</TableCell>
                            <TableCell>{Company()}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </Container>
  );
};

export default Reports;