import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { WalletTransaction, WalletTransactionResponse } from '../models/Wallet';


const apiURL =  APIEndpoint(EndpointType.WalletTransactions);

export const useWalletTransactions = (customerID: string, refreshKey: number) => {
    const [walletTransactions, setWalletTransactions] = useState<WalletTransaction[]>([])
    const [walletTransactionsError, setWalletTransactionsError] = useState("")
    const [walletTransactionsLoading, setWalletTransactionsLoading] = useState(false)
    
    useEffect(() => {
        if (customerID == "") {
            return
        }
        
        setWalletTransactionsLoading(true)
        setWalletTransactionsError("")
        httpGet(apiURL + "/" + customerID)
            .then((data) => {
                const response = data as WalletTransactionResponse;
                if (response.status === 'error') {
                    setWalletTransactionsError(response.errorMessage!)
                    setWalletTransactions([])
                } else {
                    if (response.transactions != undefined) {
                        setWalletTransactions(response.transactions)
                    } else {
                        setWalletTransactions([])
                    }
                }
            })
            .catch((error) => setWalletTransactionsError(error))
            .finally(() => setWalletTransactionsLoading(false))

    }, [customerID, refreshKey]);

    return { walletTransactions, walletTransactionsLoading, walletTransactionsError };
};