import Container from "@mui/material/Container";
import * as React from "react";
import { useEffect, useState } from "react";
import { ParseQueryString } from "../utils/apiService";
import OrderDetails from "../components/OrderDetails";
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

const OrderDetailsPage = () => {
    const [orderDetailsID, setOrderDetailsID] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        // Log Pageview with Hubspot
        const _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', '/orderdetails']);
        _hsq.push(['trackPageView']);
    }, []);

    useEffect(() => {
        const queryParams = ParseQueryString(location.search)
        const orderID = queryParams.get("id")

        if (orderID != undefined && orderID != "") {
        setOrderDetailsID(orderID)
        } 
    }, []);

    const orderDetailsStyle = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: "#ffffff",
        border: '1px solid #5c5c5e',
        boxShadow: 24,
        p: 1,
      };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Box sx={orderDetailsStyle}>
            <OrderDetails orderID={orderDetailsID} showFullDetails close={() => {navigate("/orders")}} />
        </Box>
    </Container>
  );
};

export default OrderDetailsPage;
