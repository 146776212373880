import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { OrderPeriodSummary } from '../models/ValuesResponse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';

interface SummaryProps {
  children?: React.ReactNode;
  data?: OrderPeriodSummary;
}

function currencyFormat(num: number | undefined) {
    if (num == undefined) {
        return "$0.00"
    }

    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export default function TotalSummary(props: SummaryProps) {
  return (
    <Paper
      sx={{
        p: 2,
        margin: 'auto',
        maxWidth: 250,
        flexGrow: 1,
        backgroundColor: (theme) => theme.palette.primary.dark,
      }}
    >
        <Typography component="h2" variant="h6" color="#ffffff" gutterBottom >
        {props.children}
        </Typography>

        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 210, paddingLeft: 0 }} size="small" aria-label="a dense table">
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">Orders</TableCell>
                        <TableCell align="right">{props.data?.orderCount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Leads</TableCell>
                        <TableCell align="right">{props.data?.leadCount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Revenue</TableCell>
                        <TableCell align="right">{currencyFormat(props.data?.revenue)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        {props.data?.salesTeamRevenue == null
            ? <Typography></Typography>
            :
            <Grid>
                <Typography sx={{marginTop: 1, color: "#d6d5e0"}}>Sales Leaders</Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 210, paddingLeft: 0 }} size="small">
                        <TableBody>
                        { Object.keys(props.data.salesTeamRevenue).map((e) => 
                            <TableRow key={e}>
                                <TableCell component="th" scope="row">{e}</TableCell>
                                <TableCell align="right">{props.data?.salesTeamRevenue && currencyFormat(props.data?.salesTeamRevenue[e])}</TableCell>
                            </TableRow>
                        )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        }
    </Paper>

  );
}
