import { Box, Container, Grid, Link, Modal, Paper, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useState } from 'react';
import Title from '../components/Title';
import { useProductsAll } from "../hooks/useProductsAll";
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorFallBack from '../components/ErrorFallBack';
import { DataGridPremium, GridRenderCellParams, GridToolbar, GridValueGetterParams } from '@mui/x-data-grid-premium';
import ProductDetails from '../components/ProductDetails';
import { Product, ProductFeatures } from '../models/Products';
import { Storefront } from '../models/StorefrontResponse';

interface Props {
    storefront: Storefront
}

const Products = (props: Props) => {
    const { productsAll, productsAllLoading, productsAllError } = useProductsAll("", 1)
    const [productDetails, setProductDetails] = useState<Product>();
    const [openProductDetails, setOpenProductDetails] = useState(false);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const columns = [
        { field: 'id', headerName: 'ID', width: 147, renderCell: (params: GridRenderCellParams) => {
          return         <Link
              component="button"
              variant="body2"
              onClick={() => {
                setProductDetails(params.row);
                setOpenProductDetails(true);
              }}
              >
              {params.value}
            </Link>
        } },
        { field: 'name', headerName: 'Name', width: 240 },
        { field: 'features', headerName: 'Features', width: 200, valueGetter: (params: GridValueGetterParams) => {
            return friendlyFeatures(params.value)
          }, },
        { field: 'priceInfo', headerName: 'Price', width: 80, valueGetter: (params: GridValueGetterParams) => {
            return formatter.format(params.row.priceInfo.price)
          }, },
        { field: 'productType', headerName: 'Type', width: 100 },
    ]

    function friendlyFeatures(features: ProductFeatures): string {
        let featureString = '';
        if (features.noDNC) {
            featureString += "No DNC";
        }

        if (features.phonesEmails) {
            if (featureString.length > 0) {
                featureString += ", ";
            }
            featureString += "Phones & Emails";
        }

        return featureString
    }

    function CustomNoRowsOverlay() {
        return (
          <StyledGridOverlay>
            <Box width={100}>
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 512 640" enableBackground="new 0 0 512 512"><g><g>
                <polygon points="448,384 181.531,384 69.531,128 0,128 0,96 90.469,96 202.469,352 448,352   " fill="#797a7a"/></g>
                <path d="M288,464c0,26.5-21.5,48-48,48s-48-21.5-48-48s21.5-48,48-48S288,437.5,288,464z" fill="#797a7a"/>
                <path d="M448,464c0,26.5-21.5,48-48,48s-48-21.5-48-48s21.5-48,48-48S448,437.5,448,464z" fill="#797a7a"/><g>
                  <path d="M400,0c-61.844,0-112,50.156-112,112s50.156,112,112,112s112-50.156,112-112S461.844,0,400,0z M456.563,145.938    l-22.625,22.625L400,134.625l-33.938,33.938l-22.625-22.625L377.375,112l-33.938-33.938l22.625-22.625L400,89.375l33.938-33.938    l22.625,22.625L422.625,112L456.563,145.938z" fill="#797a7a"/></g>
                  <path d="M400,256c-62.5,0-115.281-40.25-135.156-96H160l64,160h224l36.938-92.313C461.063,245.25,431.844,256,400,256z" fill="#797a7a"/></g>
              </svg>
            </Box>
            <Box sx={{ mt: 1, color:"#797a7a" }}>No Products</Box>
          </StyledGridOverlay>
        );
    }

    const StyledGridOverlay = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& .ant-empty-img-1': {
          fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
          fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
          fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
          fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
          fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
          fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
    }));

    const orderDetailsStyle = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: props.storefront.activeColors.addProductForm.formBackground,
        border: '1px solid #5c5c5e',
        boxShadow: 24,
        p: 1,
    };

    const handleProductDetailsClose = () => setOpenProductDetails(false);

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
           {/*Orders*/}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
              >
                <Title>Products</Title>
              </Stack>
              <Stack>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                      item
                      xs={12}
                    >
                    </Grid>
                    <Grid height={500}>
                      {productsAllLoading
                        ? <LoadingSpinner title="Loading products..." textColor="#0352fc"/>
                        : productsAllError
                        ? <ErrorFallBack message={productsAllError} />
                        : 
                          <DataGridPremium
                            rows={productsAll}
                            columns={columns}
                            initialState={{
                              sorting: {
                                sortModel: [{ field: 'name', sort: 'desc' }],
                              },
                            }}
                            slots={{
                              noRowsOverlay: CustomNoRowsOverlay,
                              toolbar: GridToolbar
                            }}
                            slotProps={{ toolbar: { 

                          } }}
                          />
                        }
                      </Grid>
                  </Paper>
                </Grid>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
        <Modal
            open={openProductDetails}
            onClose={handleProductDetailsClose}
        >
            <Box sx={orderDetailsStyle}>
                { productDetails &&
                    <ProductDetails product={productDetails} close={() => {setOpenProductDetails(false)}} />
                }
            </Box>
        </Modal>
      </Container>
    );
};

export default Products;