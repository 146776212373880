import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { ShortOrder, ShortOrdersResponse } from '../models/OrdersResponse'

const apiURL =  APIEndpoint(EndpointType.Orders);

export const useOrders = (startDate: string, endDate: string, pageKey: string, storefrontName: string, refreshKey: number) => {
    const [orders, setMyOrders] = useState<ShortOrder[]>([])
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState("")

    function getDate7DaysAgoFormatted(): string {
        const today = new Date();
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 7);
      
        const year = sevenDaysAgo.getFullYear();
        const month = String(sevenDaysAgo.getMonth() + 1).padStart(2, '0');
        const day = String(sevenDaysAgo.getDate()).padStart(2, '0');
        const hours = String(sevenDaysAgo.getHours()).padStart(2, '0');
        const minutes = String(sevenDaysAgo.getMinutes()).padStart(2, '0');
        const seconds = String(sevenDaysAgo.getSeconds()).padStart(2, '0');
      
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      }

    useEffect(() => {
        const params: string[] = []

        if (startDate) {
            if (startDate == "default") {
                startDate = getDate7DaysAgoFormatted()
            }
            params.push('startDate=' + startDate);
        }

        if (endDate) {
            params.push('endDate=' + endDate);
        }

        if (pageKey) {
            params.push('page=' + pageKey);
        }

        if (storefrontName == "") {
            console.log("cannot retrieve orders...no storefront specified")
            return
        } else {
            // By default we want to see all orders across all storefronts if the user
            // is a member of tsg. If they are any other storefront then we need to filter 
            // by that storefront
            if (storefrontName != "tsg") {
                params.push('sf=' + storefrontName);
            }
        }

        // build the querystring
        let queryString = "?short=true&"

        if (params.length > 0) {
            queryString += params.join("&")
        }
        
        setLoading(true)
        setError("")
        httpGet(apiURL + queryString)
            .then((data) => {
                const shortOrdersResponse = data as ShortOrdersResponse;
                
                if (shortOrdersResponse.status == "error") {
                    setError(shortOrdersResponse.errorMessage)
                    setMyOrders([])
                    return
                }
                
                if (shortOrdersResponse.orders) {
                    setMyOrders(shortOrdersResponse.orders);
                } else {
                    const emptyOrder: ShortOrder[] = []
                    setMyOrders(emptyOrder)
                }
                
                setPage(shortOrdersResponse.page)
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false))

    }, [startDate, endDate, pageKey, storefrontName, refreshKey]);

    return { orders, page, loading, error };
};