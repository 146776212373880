import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Copyright from "../components/CopyRight";
import * as React from "react";
import Title from '../components/Title';
import { useState, useEffect } from 'react';
import { useValues } from "../hooks/useValues";
import { ValueOrderTotalsSummary } from "../models/ValuesResponse";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorFallBack from "../components/ErrorFallBack";
import TotalSummary from "../components/TotalsSummary";
import { BarChart } from '@mui/x-charts/BarChart';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

const SalesDashboard = () => {
  const [orderTotalsSummary, setOrderTotalsSummary] = useState<ValueOrderTotalsSummary>({ 
    today: {orderCount: 0, leadCount: 0, revenue: 0},
    last7Days: {orderCount: 0, leadCount: 0, revenue: 0},
    last28Days: {orderCount: 0, leadCount: 0, revenue: 0},
    currentMonth: {orderCount: 0, leadCount: 0, revenue: 0},
    currentYear: {orderCount: 0, leadCount: 0, revenue: 0},
    allTime: {orderCount: 0, leadCount: 0, revenue: 0},
    dailyRevenueChart: {axisPoints: [""], data: [0], title: ""},
    monthlyRevenueChart: {axisPoints: [""], data: [0], title: ""},
  })
  const { nameValue, nameValueLoading, nameValueError } = useValues("ORDER_TOTALS_SUMMARY", 0)

  useEffect(() => {
    // Log Pageview with Hubspot
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', '/salesdashboard']);
    _hsq.push(['trackPageView']);
  }, []);

  useEffect(() => {
    if (nameValue.length > 0) {
      const summary: ValueOrderTotalsSummary = JSON.parse(nameValue)
      setOrderTotalsSummary(summary)
      //const keys = Object.keys(summary.allTime.salesTeamRevenue)
      //console.log(keys.length)
    }
  }, [nameValue]);


  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Title>Sales Dashboard</Title>
            </Stack>
            <Grid>
              {nameValueLoading
                  ? <LoadingSpinner title="Loading data..." textColor="#0352fc"/>
                  : nameValueError
                  ? <ErrorFallBack message={nameValueError} />
                  : 
                  <Grid sx={{ marginLeft:5, marginTop: 3}}>
                    {/* <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}> */}
                      <Stack direction="column">
                        { orderTotalsSummary != undefined
                          ?  <Stack direction="column">
                                <Title>Daily Sales</Title>
                                <BarChart 
                                  title={orderTotalsSummary.dailyRevenueChart.title}
                                  xAxis={[
                                      { 
                                        scaleType: 'band',
                                        data: orderTotalsSummary.dailyRevenueChart.axisPoints,
                                        label: orderTotalsSummary.dailyRevenueChart.title,
                                      }
                                    ]}
                                  series={[
                                      {
                                        data: orderTotalsSummary.dailyRevenueChart.data,
                                        color: "#7b9a6d",
                                      },
                                    ]}
                                  width={800}
                                  height={300}
                                  margin={{ top: 5 }}
                                />
                                <Title>Monthly Sales</Title>
                                <BarChart 
                                  xAxis={[
                                      { 
                                        scaleType: 'band',
                                        data: orderTotalsSummary.monthlyRevenueChart.axisPoints,
                                        label: orderTotalsSummary.monthlyRevenueChart.title,
                                      }
                                    ]}
                                  series={[
                                      {
                                        data: orderTotalsSummary.monthlyRevenueChart.data,
                                        color: "#7b9a6d",
                                      },
                                    ]}
                                  width={800}
                                  height={300}
                                  margin={{ top: 5 }}
                                />
                            </Stack>
                          : <LoadingSpinner title="Loading data..." textColor="#0352fc"/>
                          }
                      <Title>More Views</Title>
                      <Grid sx={{ marginTop:2}} container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={4}>
                          <TotalSummary data={orderTotalsSummary?.today}>Today</TotalSummary>
                        </Grid>
                        <Grid item xs={4}>
                          <TotalSummary data={orderTotalsSummary?.last7Days}>Last 7 Days</TotalSummary>
                        </Grid>
                        <Grid item xs={4}>
                          <TotalSummary data={orderTotalsSummary?.last28Days}>Last 28 Days</TotalSummary>
                        </Grid>
                        <Grid item xs={4}>
                          <TotalSummary data={orderTotalsSummary?.currentMonth}>Current Month</TotalSummary>
                        </Grid>
                        <Grid item xs={4}>
                          <TotalSummary data={orderTotalsSummary?.currentYear}>Current Year</TotalSummary>
                        </Grid>
                        <Grid item xs={4}>
                          <TotalSummary data={orderTotalsSummary?.allTime}>All Time</TotalSummary>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Grid>
                }
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </Container>
  );
};

export default SalesDashboard;
