import React from 'react';
import { Helmet } from 'react-helmet-async';

interface Props {
    title: string, 
    description: string, 
    name: string, 
    type: string
    children?: React.ReactNode;
}

export default function SEO(props: Props) {
    return (
        <div>
            <Helmet>
                { /* Standard metadata tags */ }
                {props.title != "" &&
                    <title>{props.title}</title>
                }

                {props.description != "" &&
                    <meta name='description' content={props.description} />
                }
                
                <link rel="canonical" href={location.href} />
                { /* End standard metadata tags */ }

                { /* Facebook tags */ }
                <meta property="og:site_name" content="The Share Group" />
                <meta property="og:url" content={location.href} />

                {props.title != "" &&
                    <meta property="og:title" content={props.title} />
                }

                <meta property="og:type" content={props.type} />

                {props.description != "" &&
                    <meta property="og:description" content={props.description} />
                }
                { /* End Facebook tags */ }

                { /* Twitter tags */ }
                <meta name="twitter:creator" content={props.name} />
                <meta name="twitter:card" content={props.type} />
                
                {props.title != "" &&
                    <meta name="twitter:title" content={props.title} />
                }

                {props.description != "" &&
                    <meta name="twitter:description" content={props.description} />
                }
                { /* End Twitter tags */ }
            </Helmet>
            {props.children}
        </div>
    )
}