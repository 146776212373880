import { Blueprint } from "./Blueprint";

export interface WebsocketMessage {
    command: WebsocketCommandType;
    customerID: string;
    audienceCommandInfo?: AudienceCommandInfo;
    blueprintCommandInfo?: BlueprintCommandInfo;
}

export interface AudienceCommandInfo {
    latitude?: number;
    longitude?: number;
    zip4Radius?: string;
    audienceID: string;
    radius?: string;
    suppressOrders?: boolean;
}

export interface BlueprintCommandInfo {
    blueprint?: Blueprint;
    latitude?: number;
    longitude?: number;
    zip4Radius?: string;
    radius?: string;
    suppressOrders?: boolean;
}

export interface RadiusCountWebsocketResponse {
    command: WebsocketCommandType;
    audienceID: string;
    result: RadiusCountResult;
    status: WebsocketCommandStatus;
    moreInfo: string;
}

export interface RadiusCountResult {
    audienceID: number;
    blueprintID: string;
    count: number;
    zip4Radius: string;
    ordersSuppressedCount: number;
}

export enum WebsocketCommandStatus {
    Initiated = "initiated",
    Completed = "completed",
    Failed = "failed",
    Unknown = "unknown",
    Progressing = "progressing"
}

export enum WebsocketCommandType {
    AudienceCount = "audience_count",
    BlueprintCount = "blueprint_count",
    Unknown = "unknown"
}
