import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Copyright from "../components/CopyRight";
import * as React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import Title from '../components/Title';
import LoadingSpinner from "../components/LoadingSpinner";
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import { format, parse } from 'date-format-parse';
import { usePendingOrders } from '../hooks/usePendingOrders';
import ErrorFallBack from "../components/ErrorFallBack";
import { DataGrid, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';
import { useOrder } from "../hooks/useOrder";
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

const PendingOrders = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [orderDetailsID, setOrderDetailsID] = useState("");
  const [showOrderDetails, setShowOrderDetails] = useState("none")
  const { order, orderLoading, orderError } = useOrder(orderDetailsID, false);
  const { orders, loading, error } = usePendingOrders("", refreshKey);

  useEffect(() => {
    // Log Pageview with Hubspot
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', '/pendingorders']);
    _hsq.push(['trackPageView']);
  }, []);

  function getShortDate(dateString: string) {
    const dt = parse(dateString, 'YYYY-MM-DDTHH:mm:ss');
    return format(dt, "MM/DD/YY")
  }

  const handleRefreshClick = () => {
    setRefreshKey(refreshKey+1);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 160, renderCell: (params: GridRenderCellParams) => {
      return         <Link
          component="button"
          variant="body2"
          onClick={() => {
            setOrderDetailsID(params.value);
            setShowOrderDetails("inline");
          }}
          >
          {params.value}
        </Link>
    } },
    { field: 'orderDate', headerName: 'Date', width: 85, valueGetter: (params: GridValueGetterParams) => {
      return getShortDate(params.value)
    }, },
    { field: 'customerName', headerName: 'Name', width: 164 },
    { field: 'email', headerName: 'Email', width: 170 },
    { field: 'description', headerName: 'Description', width: 170 },
    { field: 'totalPrice', headerName: 'Amount', width: 80},
    { field: 'displayQuantity', headerName: '# Leads', width: 90},
    { field: 'orderSource', headerName: 'Order Source', width: 124 },
    { field: 'downloadURL', headerName: 'Download', width: 76, renderCell: (params: GridRenderCellParams) => {
      if (params.value && params.value != "" ) {
        return <Button href={`${params.value}`} download={"order.csv"} size="small">
        <DownloadIcon color="primary" />
      </Button>
      } else {
        return <div>Pending...</div>
      }
    }, },
  ]

  function prettyZipDisplay(zips: string[]): string {
    return 'Zip Codes: ' + zips.join(', ')
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  function makeDefined(input: number | undefined) {
    if (input == undefined) {
      return 0
    }

    return input
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
         {/*Orders*/}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Title>Pending Orders</Title>
              <IconButton size="medium" type="button" onClick={() => handleRefreshClick()}>
                <RefreshIcon color="primary" />
              </IconButton>
            </Stack>
            <Stack>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                    item
                    xs={12}
                  >
                  </Grid>
                  <React.Fragment>
                    {loading
                      ? <LoadingSpinner title="Loading Orders..." textColor="#0352fc"/>
                      : error
                      ? <ErrorFallBack message={error} />
                      : 
                        <DataGrid
                          rows={orders}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 10 },
                            },
                            sorting: {
                              sortModel: [{ field: 'orderDate', sort: 'desc' }],
                            },
                          }}
                          pageSizeOptions={[5, 10, 25, 50]}
                        />
                      }
                    </React.Fragment>
                </Paper>
              </Grid>
              <Grid sx={{ marginTop: 2}} display={showOrderDetails}>
                  <Stack>
                    <b>Order Details:</b>
                    <React.Fragment>
                    {orderLoading
                      ? <LoadingSpinner title="Getting order..." textColor="#0352fc"/>
                      : orderError
                      ? <ErrorFallBack message={orderError} />
                      : 
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 650 }} size="small">
                            <TableBody>
                                <TableRow>
                                  <TableCell>Order #:</TableCell>
                                  <TableCell>{order?.id}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Order Date:</TableCell>
                                  <TableCell>{order?.orderDate}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Amount:</TableCell>
                                  <TableCell>{order && formatter.format(makeDefined(order.totalPrice))
                                    }
                                     &nbsp;(Discount: &nbsp;
                                    {order && order.manualOrderDetails &&  formatter.format((makeDefined(order.manualOrderDetails.discount)))}
                                     )
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Customer:</TableCell>
                                  <TableCell>{order?.customerName} ({order?.email})</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Fulfillment Date:</TableCell>
                                  <TableCell>{order?.fulfillment?.createdAt}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Sold By:</TableCell>
                                  <TableCell>
                                    {order?.manualOrderDetails != undefined
                                      ? <Box>{order.manualOrderDetails.salesAssociateName}</Box>
                                      : <div />
                                    }
                                    {order?.tsgStripeOrderDetails != undefined
                                      ? <Box>Lead Shop</Box>
                                      : <Box>Shopify</Box>
                                    }
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Products:</TableCell>
                                  <TableCell>
                                    <Stack>
                                    {order?.products!.map((order) => (
                                        <Box>
                                        <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />} key={order.id}>
                                          <Box>{order.leadType}</Box>
                                          <Box>{order.quantity} leads</Box>
                                          {
                                            order.postalCodes.length == 1
                                            ? <Box>Zip Code: {order.postalCodes[0]}</Box>
                                            : <Box />
                                          }
                                        </Stack>
                                        {
                                            order.postalCodes.length > 1
                                            ? <Stack direction="row" spacing={3}><Box></Box><Box>{prettyZipDisplay(order.postalCodes)}</Box></Stack>
                                            : <Box />
                                        }
                                        </Box>
                                      ))}
                                    </Stack>      
                                  </TableCell>
                                </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      }
                    </React.Fragment>
                  </Stack>
              </Grid>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </Container>
  );
};

export default PendingOrders;