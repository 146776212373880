import Grid from "@mui/material/Grid";
import * as React from "react";
import { useEffect } from "react";
import { Paper, Button, Stack, Typography, Container } from "@mui/material";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

const Calculators = () => {
  useEffect(() => {
    // Log Pageview with Hubspot
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', '/calculators' + location.search]);
    _hsq.push(['trackPageView']);
  }, []);

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Typography variant="h4">Check out our helpful calculators</Typography>
            <Stack sx={{mt:4}}>
              <Typography variant="h6">Dials to Listings Calculator</Typography>
              <Typography variant="body1">Helps you understand the number of listings you can expect from your monthly calling goals.</Typography>
              <Button variant="contained" sx={{width: 120}} onClick={() => {openInNewTab("https://www.theshare.group/dials-per-day-calculator")}}>View</Button>
            </Stack>
            <Stack sx={{mt:4}}>
              <Typography variant="h6">Leads Calculator</Typography>
              <Typography variant="body1">Helps you understand the number of leads you need to achieve your listing goal.</Typography>
              <Button variant="contained" sx={{width: 120}} onClick={() => {openInNewTab("https://www.theshare.group/lead-calculator")}}>View</Button>
            </Stack>
          </Paper>
        </Grid> 
      </Grid>
    </Container>
  );
};

export default Calculators;
