import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Copyright from "../components/CopyRight";
import logoImage from "../assets/images/tsglogo.png";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import useAuth from "../hooks/useAuth";
import { useEffect } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

export default function ChangePassword() {
  const navigate = useNavigate();
  const { changePassword } = useAuth();

  useEffect(() => {
    // Log Pageview with Hubspot
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', '/changepassword' + location.search]);
    _hsq.push(['trackPageView']);
  }, []);

  const validationSchema = Yup.object({
    oldPassword: Yup.string()
      .min(8, "Old Password should be of minimum 8 characters length")
      .required("Old Password is required"),
    newPassword: Yup.string()
      .min(8, "New Password should be of minimum 8 characters length")
      .required("New Password is required"),
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={logoImage} width={128} height={48} />
        <Typography component="h1" variant="h5" sx={{marginTop:2}}>
          Change Password
        </Typography>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            submit: null,
          }}
          validationSchema={validationSchema}
          onSubmit={async (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            values: any,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            { setErrors, setStatus, setSubmitting }: any
          ): Promise<void> => {
            try {
              setSubmitting(true);
              // user change password
              await changePassword({oldPassword: values.oldPassword, newPassword: values.newPassword});
              navigate("/signin", { replace: true });
              setSubmitting(false);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (err: any) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }): JSX.Element => (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="oldPassword"
                    label="Old Password"
                    name="oldPassword"
                    value={values?.oldPassword}
                    onChange={handleChange}
                    error={Boolean(touched.oldPassword && errors.oldPassword)}
                    helperText={touched.oldPassword && errors.oldPassword}
                    autoComplete="oldPassword"
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="newPassword"
                    label="New Password"
                    value={values?.newPassword}
                    onChange={handleChange}
                    error={Boolean(touched.newPassword && errors.newPassword)}
                    helperText={touched.newPassword && errors.newPassword}
                    type="password"
                    id="newPassword"
                    autoComplete="newPassword"
                  />
                </Grid>
                <Grid item xs={12}>
                  {/*Display Error with Icon*/}
                  {errors?.submit && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ErrorOutlineIcon sx={{ color: "red", mr: 1 }} />
                      <Typography variant="body2" color="error">
                        {errors?.submit}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Change Password
                  </Button>
                </Grid>
              </Grid>
              <Typography
                sx={{
                  width: "100%",
                  my: 2,
                  textAlign: "center",
                }}
              >
                {/* OR */}
              </Typography>
            </Box>
          )}
        </Formik>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
