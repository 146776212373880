import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { LookupResponse, LookupResult } from '../models/Lookup';

const apiURL =  APIEndpoint(EndpointType.Lookup);

export const useLookup = (lookupType: string, first: string, last: string, address: string, city: string, state: string, zip: string, email: string, phone: string, refreshToken: number, customerID: string, credits: number, lookupID: string, lookupChildID: string) => {
    const [lookups, setLookups] = useState<LookupResult>()
    const [lookupsError, setLookupsError] = useState("")
    const [lookupsLoading, setLoookupsLoading] = useState(false)

    const [lookType, setLookType] = useState<string>("")
    const [lookFirst, setLookFirst] = useState<string>("")
    const [lookLast, setLookLast] = useState<string>("")
    const [lookAddress, setLookAddress] = useState<string>("")
    const [lookCity, setLookCity] = useState<string>("")
    const [lookState, setLookState] = useState<string>("")
    const [lookZip, setLookZip] = useState<string>("")
    const [lookEmail, setLookEmail] = useState<string>("")
    const [lookPhone, setLookPhone] = useState<string>("")

    useEffect(() => {
        if (!lookupType) return;
        if (refreshToken === 0) return;

        if (lookupType === lookType && first === lookFirst && last === lookLast && address === lookAddress && city === lookCity && state === lookState && zip === lookZip && email === lookEmail && phone === lookPhone && lookupID === "" && lookupChildID === "" && customerID === "" && credits === 0) {
            return;
        }

        if (lookupType === "purchase" && (!lookupID || !lookupChildID)) {
            setLookupsError("Lookup ID and Child ID are required for purchase lookup")
            return;
        }

        if (lookupType === "phone" && !phone) {
            setLookupsError("Phone number is required for phone lookup")
            return;
        }

        if (lookupType === "email" && !email) {
            setLookupsError("Email is required for email lookup")
            return;
        }

        if (lookupType === "address" && !address) {
            setLookupsError("Address, City, State, and Zip are required for address lookup")
            return;
        }

        if (lookupType === "address" && !zip) {
            if (city === "" || state === "") {
                setLookupsError("Zip is required if city and state are not provided for address lookup")
                return;
            }
        }

        if (lookupType === "name" && (!first || !last || !state)) {
            setLookupsError("First Name, Last Name, and State are required for name lookup")
            return;
        }

        setLookAddress(address)
        setLookCity(city)
        setLookEmail(email)
        setLookFirst(first)
        setLookLast(last)
        setLookPhone(phone)
        setLookState(state)
        setLookType(lookupType)
        setLookZip(zip)

        setLoookupsLoading(true)
        setLookupsError("")
        const emptyResult = {} as LookupResult

        const url = `${apiURL}?type=${lookupType}&first=${first}&last=${last}&address=${address}&city=${city}&state=${state}&zip=${zip}&email=${email}&phone=${phone}&customer-id=${customerID}&credits=${credits}&lookup-id=${lookupID}&lookup-child-id=${lookupChildID}`
        httpGet(url)
            .then((data) => {
                const response = data as LookupResponse;
                
                if (response.status == "error") {
                    setLookupsError(response.errorMessage)
                    setLookups(emptyResult)
                } else {
                    if (response.results) {
                        setLookups(response.results);
                    } else {
                        setLookups(emptyResult)
                    }
                }
            })
            .catch((error) => setLookupsError(error))
            .finally(() => setLoookupsLoading(false))

    }, [refreshToken]);

    return { lookups, lookupsLoading, lookupsError };
};
