import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { Blueprint, BlueprintResponse } from '../models/Blueprint'

const apiURL =  APIEndpoint(EndpointType.Blueprints);

export const useBlueprint = (blueprintID: string) => {
    const [blueprint, setBlueprint] = useState<Blueprint>()
    const [blueprintError, setBlueprintError] = useState("")
    const [blueprintLoading, setBlueprintLoading] = useState(false)
    
    if (blueprintID == "" && blueprint != undefined) {
        setBlueprint(undefined)
    }

    useEffect(() => {
        if (blueprintID == "") {
            return
        }

        setBlueprintLoading(true)
        setBlueprintError("")
        httpGet(apiURL + "/" + blueprintID)
            .then((data) => {
                const response = data as BlueprintResponse;
                
                if (response.status == "error") {
                    setBlueprintError(response.errorMessage)
                    setBlueprint(response.blueprint)
                } else {
                    setBlueprint(response.blueprint);
                }
            })
            .catch((error) => setBlueprintError(error))
            .finally(() => setBlueprintLoading(false))

    }, [blueprintID]);

    return { blueprint, blueprintLoading, blueprintError };
};