import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Copyright from "../components/CopyRight";
import * as React from "react";
import Title from '../components/Title';
import { useState, useEffect, useRef } from 'react';
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { AddProductForm, Storefront, StorefrontResponse, MainPage, Cart, LeftMenu, PageFooter} from "../models/StorefrontResponse";
import { httpPost, APIEndpoint, EndpointType, getEnvironment, httpPostFormData } from "../utils/apiService";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {genUniqueId} from "../utils/GenerateUniqueID";
import { useUser } from "../hooks/useUser";
import useAuth from "../hooks/useAuth";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useStorefrontManager } from "../hooks/useStorefrontManager";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

interface Props {
    storefront: Storefront
    storefrontoverride: boolean
}

const StoreManager = (props: Props) => {    
    const [storefrontName, setStorefrontName] = useState("");
    const [environment, setEnvironment] = useState("prod");
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMsg, setSnackbarMsg] = useState("")
    const [sfID, setSfID] = useState("")
    //const [sfName, setSfName] = useState("mystore")
    const [sfCreatedAt, setSfCreatedAt] = useState("")
    const [sfModifiedAt, setSfModifiedAt] = useState("")
    const [sfTitle, setSfTitle] = useState("The Share Group Custom Lead Shop")
    const [sfMinimumOrderAmount, setSfMinimumOrderAmount] = useState(300.0)
    const [logoRefreshDisplay, setLogoRefreshDisplay] = useState("none")
    const [products, setProducts] = useState<string[]>([])
    const [emailSignatureHTML, setEmailSignatureHTML] = useState("")

    // Light Mode
    const [sfPageText, setSfPageText] = useState("#000000")
    const [sfPageButtonBackground, setSfPageButtonBackground] = useState("#1b5e20")
    const [sfPageIconBackground, setSfPageIconBackground] = useState("#1b5e20")
    const [sfPageMapBorderBackground, setSfPageMapBorderBackground] = useState("#a5d6a7")
    const [sfPageSearchBoxBackground, setSfPageSearchBoxBackground] = useState("#a5d6a7")
    const [sfPageSearchBoxText, setSfPageSearchBoxText] = useState("#000000")
    const [sfHeaderBackground, setSfHeaderBackground] = useState("#000000")
    const [sfHeaderText, setSfHeaderText] = useState("#000000")
    const [sfAvatarBackground, setSfAvatarBackground] = useState("#000000")
    const [sfAvatarForeground, setSfAvatarForeground] = useState("#000000")
    const [sfCartBackground, setSfCartBackground] = useState("#81c784")
    const [sfCartProductBackground, setSfCartProductBackground] = useState("#43a047")
    const [sfCartPurchaseTotalBackground, setSfCartPurchaseTotalBackground] = useState("#388e3c")
    const [sfCartTitleText, setSfCartTitleText] = useState("#000000")
    const [sfCartProductText, setSfCartProductText] = useState("#e8f5e9")
    const [sfCartPurchaseText, setSfCartPurchaseText] = useState("#e8f5e9")
    const [sfCartIconBackground, setSfCartIconBackground] = useState("#1b5e20")
    const [sfCartButtonBackground, setSfCartButtonBackground] = useState("#01579b")
    const [sfCartPurchaseProgressBackground, setSfCartPurchaseProgressBackground] = useState("#01579b")
    const [sfCartProductRemoveIcon, setSfCartProductRemoveIcon] = useState("#1b5e20")
    const [sfAddProdFormBackground, setSfAddProdFormBackground] = useState("#fafafa")
    const [sfAddProdTitleText, setSfAddProdTitleText] = useState("#000000")
    const [sfAddProdProductTitleText, setSfAddProdProductTitleText] = useState("#254d26")
    const [sfAddProdProductText, setSfAddProdProductText] = useState("#000000")
    const [sfAddProdIconForeground, setSfAddProdIconForeground] = useState("#404242")
    const [sfAddProdButtonText, setSfAddProdButtonText] = useState("#000000")
    const [sfAddProdButtonBackground, setSfAddProdButtonBackground] = useState("#1c72b0")
    const [sfPageFooterText, setSfPageFooterText] = useState("#000000")
    const [sfLeftMenuIcon, setSfLeftMenuIcon] = useState("#000000")
    const [sfLeftMenuDivider, setSfLeftMenuDivider] = useState("#000000")
    const [sfLogoLightUrl, setSfLogoLightURL] = useState("")
    const [sfLogo, setSfLogo] = useState("")

    // Dark Mode
    const [sfDarkPageText, setSfDarkPageText] = useState("#000000")
    const [sfDarkPageButtonBackground, setSfDarkPageButtonBackground] = useState("#1b5e20")
    const [sfDarkPageIconBackground, setSfDarkPageIconBackground] = useState("#1b5e20")
    const [sfDarkPageMapBorderBackground, setSfDarkPageMapBorderBackground] = useState("#a5d6a7")
    const [sfDarkPageSearchBoxBackground, setSfDarkPageSearchBoxBackground] = useState("#a5d6a7")
    const [sfDarkPageSearchBoxText, setSfDarkPageSearchBoxText] = useState("#000000")
    const [sfDarkHeaderBackground, setSfDarkHeaderBackground] = useState("#000000")
    const [sfDarkHeaderText, setSfDarkHeaderText] = useState("#000000")
    const [sfDarkAvatarBackground, setSfDarkAvatarBackground] = useState("#000000")
    const [sfDarkAvatarForeground, setSfDarkAvatarForeground] = useState("#000000")
    const [sfDarkCartBackground, setSfDarkCartBackground] = useState("#81c784")
    const [sfDarkCartProductBackground, setSfDarkCartProductBackground] = useState("#43a047")
    const [sfDarkCartPurchaseTotalBackground, setSfDarkCartPurchaseTotalBackground] = useState("#388e3c")
    const [sfDarkCartTitleText, setSfDarkCartTitleText] = useState("#000000")
    const [sfDarkCartProductText, setSfDarkCartProductText] = useState("#e8f5e9")
    const [sfDarkCartPurchaseText, setSfDarkCartPurchaseText] = useState("#e8f5e9")
    const [sfDarkCartIconBackground, setSfDarkCartIconBackground] = useState("#1b5e20")
    const [sfDarkCartButtonBackground, setSfDarkCartButtonBackground] = useState("#01579b")
    const [sfDarkCartPurchaseProgressBackground, setSfDarkCartPurchaseProgressBackground] = useState("#01579b")
    const [sfDarkCartProductRemoveIcon, setSfDarkCartProductRemoveIcon] = useState("#1b5e20")
    const [sfDarkAddProdFormBackground, setSfDarkAddProdFormBackground] = useState("#fafafa")
    const [sfDarkAddProdTitleText, setSfDarkAddProdTitleText] = useState("#000000")
    const [sfDarkAddProdProductTitleText, setSfDarkAddProdProductTitleText] = useState("#254d26")
    const [sfDarkAddProdProductText, setSfDarkAddProdProductText] = useState("#000000")
    const [sfDarkAddProdIconForeground, setSfDarkAddProdIconForeground] = useState("#404242")
    const [sfDarkAddProdButtonText, setSfDarkAddProdButtonText] = useState("#000000")
    const [sfDarkAddProdButtonBackground, setSfDarkAddProdButtonBackground] = useState("#1c72b0")
    const [sfDarkPageFooterText, setSfDarkPageFooterText] = useState("#000000")
    const [sfDarkLeftMenuIcon, setSfDarkLeftMenuIcon] = useState("#000000")
    const [sfDarkLeftMenuDivider, setSfDarkLeftMenuDivider] = useState("#000000")
    const [sfLogoDarkUrl, setSfLogoDarkURL] = useState("")

    const { storefronts} = useStorefrontManager(storefrontName)

    //const [companyID, setCompanyID] = useState("")
    const [userID, setUserID] = useState("")
    //const { company} = useCompany(companyID);
    const { customer} = useUser(userID);
    const { user } = useAuth();
    const hiddenFileInputRef = useRef<HTMLInputElement>(null)
    const hiddenFileInputRefDark = useRef<HTMLInputElement>(null)

    useEffect(() => {
        // Log Pageview with Hubspot
        const _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', '/storefront']);
        _hsq.push(['trackPageView']);
      }, []);

    useEffect(() => {
        setEnvironment(getEnvironment())
    }, []);

    useEffect(() => {
        if (storefronts.length > 0 && storefronts[0].lightMode && storefronts[0].darkMode) {
            setSfID(storefronts[0].id)
            setSfTitle(storefronts[0].title)
            //setSfName(storefronts[0].storeName)
            setSfMinimumOrderAmount(storefronts[0].minOrderAmount)
            setSfCreatedAt(storefronts[0].createdAt!)
            setSfModifiedAt(storefronts[0].modifiedAt!)
            setProducts(storefronts[0].products)
            setEmailSignatureHTML(storefronts[0].emailSignatureHTML)

            // Light Mode
            setSfPageText(storefronts[0].lightMode?.mainPage.pageText)
            setSfPageButtonBackground(storefronts[0].lightMode?.mainPage.pageButtonBackground)
            setSfPageIconBackground(storefronts[0].lightMode?.mainPage.pageIconBackground)
            setSfPageMapBorderBackground(storefronts[0].lightMode?.mainPage.pageMapBorderBackground)
            setSfPageSearchBoxBackground(storefronts[0].lightMode?.mainPage.pageSearchBoxBackground)
            setSfPageSearchBoxText(storefronts[0].lightMode?.mainPage.pageSearchBoxText)
            setSfHeaderBackground(storefronts[0].lightMode?.mainPage.headerBackground)
            setSfHeaderText(storefronts[0].lightMode?.mainPage.headerText)
            setSfAvatarBackground(storefronts[0].lightMode?.mainPage.avatarBackground)
            setSfAvatarForeground(storefronts[0].lightMode?.mainPage.avatarForeground)
            setSfCartBackground(storefronts[0].lightMode?.cart.cartBackground)
            setSfCartProductBackground(storefronts[0].lightMode?.cart.cartProductBackground)
            setSfCartPurchaseTotalBackground(storefronts[0].lightMode?.cart.cartPurchaseTotalBackground)
            setSfCartTitleText(storefronts[0].lightMode?.cart.cartTitleText)
            setSfCartProductText(storefronts[0].lightMode?.cart.cartProductText)
            setSfCartProductRemoveIcon(storefronts[0].lightMode?.cart.cartProductRemoveIcon)
            setSfCartPurchaseText(storefronts[0].lightMode?.cart.cartPurchaseText)
            setSfCartIconBackground(storefronts[0].lightMode?.cart.cartIconBackground)
            setSfCartButtonBackground(storefronts[0].lightMode?.cart.cartButtonBackground)
            setSfCartPurchaseProgressBackground(storefronts[0].lightMode?.cart.cartPurchaseProgressBackground)
            setSfAddProdButtonBackground(storefronts[0].lightMode?.addProductForm.buttonBackground)
            setSfAddProdButtonText(storefronts[0].lightMode?.addProductForm.buttonText)
            setSfAddProdFormBackground(storefronts[0].lightMode?.addProductForm.formBackground)
            setSfAddProdIconForeground(storefronts[0].lightMode?.addProductForm.iconForeground)
            setSfAddProdProductText(storefronts[0].lightMode?.addProductForm.productText)
            setSfAddProdProductTitleText(storefronts[0].lightMode?.addProductForm.productTitleText)
            setSfAddProdTitleText(storefronts[0].lightMode?.addProductForm.titleText)
            setSfPageFooterText(storefronts[0].lightMode?.pageFooter.text)
            setSfLeftMenuDivider(storefronts[0].lightMode?.leftMenu.divider)
            setSfLeftMenuIcon(storefronts[0].lightMode?.leftMenu.icon)
            setSfLogo(storefronts[0].lightMode?.logo)

            // Dark Mode
            setSfDarkPageText(storefronts[0].darkMode.mainPage.pageText)
            setSfDarkPageButtonBackground(storefronts[0].darkMode.mainPage.pageButtonBackground)
            setSfDarkPageIconBackground(storefronts[0].darkMode.mainPage.pageIconBackground)
            setSfDarkPageMapBorderBackground(storefronts[0].darkMode.mainPage.pageMapBorderBackground)
            setSfDarkPageSearchBoxBackground(storefronts[0].darkMode.mainPage.pageSearchBoxBackground)
            setSfDarkPageSearchBoxText(storefronts[0].darkMode.mainPage.pageSearchBoxText)
            setSfDarkHeaderBackground(storefronts[0].darkMode.mainPage.headerBackground)
            setSfDarkHeaderText(storefronts[0].darkMode.mainPage.headerText)
            setSfDarkAvatarBackground(storefronts[0].darkMode.mainPage.avatarBackground)
            setSfDarkAvatarForeground(storefronts[0].darkMode.mainPage.avatarForeground)
            setSfDarkCartBackground(storefronts[0].darkMode.cart.cartBackground)
            setSfDarkCartProductBackground(storefronts[0].darkMode.cart.cartProductBackground)
            setSfDarkCartPurchaseTotalBackground(storefronts[0].darkMode.cart.cartPurchaseTotalBackground)
            setSfDarkCartTitleText(storefronts[0].darkMode.cart.cartTitleText)
            setSfDarkCartProductText(storefronts[0].darkMode.cart.cartProductText)
            setSfDarkCartProductRemoveIcon(storefronts[0].darkMode.cart.cartProductRemoveIcon)
            setSfDarkCartPurchaseText(storefronts[0].darkMode.cart.cartPurchaseText)
            setSfDarkCartIconBackground(storefronts[0].darkMode.cart.cartIconBackground)
            setSfDarkCartButtonBackground(storefronts[0].darkMode.cart.cartButtonBackground)
            setSfDarkCartPurchaseProgressBackground(storefronts[0].darkMode.cart.cartPurchaseProgressBackground)
            setSfDarkAddProdButtonBackground(storefronts[0].darkMode.addProductForm.buttonBackground)
            setSfDarkAddProdButtonText(storefronts[0].darkMode.addProductForm.buttonText)
            setSfDarkAddProdFormBackground(storefronts[0].darkMode.addProductForm.formBackground)
            setSfDarkAddProdIconForeground(storefronts[0].darkMode.addProductForm.iconForeground)
            setSfDarkAddProdProductText(storefronts[0].darkMode.addProductForm.productText)
            setSfDarkAddProdProductTitleText(storefronts[0].darkMode.addProductForm.productTitleText)
            setSfDarkAddProdTitleText(storefronts[0].darkMode.addProductForm.titleText)
            setSfDarkPageFooterText(storefronts[0].darkMode.pageFooter.text)
            setSfDarkLeftMenuDivider(storefronts[0].darkMode.leftMenu.divider)
            setSfDarkLeftMenuIcon(storefronts[0].darkMode.leftMenu.icon)
        }
        
    }, [storefronts]);

    useEffect(() => {
        setUserID(user.username)
    }, [user]);

    useEffect(() => {
    }, [sfLogoLightUrl, sfLogoDarkUrl]);

    useEffect(() => {
        getlogoURLs()
    }, [sfLogo]);

    useEffect(() => {
        if (customer == undefined) {
          return
        }
    
        setStorefrontName(props.storefront.storeName)
        // if (props.storefrontoverride) {
        //     console.log("Storefront Manager Override. Store=" + props.storefront.storeName)
        //     setStorefrontName(props.storefront.storeName)
        // } else {
        //     if (customer.companyID != "") {
        //     setCompanyID(customer.companyID)
        //     setStorefrontName(customer.storefrontName)
        //     }
        // }
    }, [customer]);
    
    // useEffect(() => {
    //     if (company == undefined) {
    //         return
    //     }
    //     setStorefrontName(company.storefrontName)
    // }, [company]);

    const storeMinOrderAmountChanged = (event: React.ChangeEvent<{ value: string }>) => {
        const orderAmount: number = +event.target.value
        setSfMinimumOrderAmount(orderAmount)
    }

    const pageTitleChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfTitle(event.target.value)
    }

    const logoChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files != null) {
            const selectedFile = event.target.files[0];
            setSfLogo(selectedFile.name)

            // Upload the logo
            const apiURL =  APIEndpoint(EndpointType.Storefront)
            const formData = new FormData();
            formData.append("logocolorscheme", "light")
            formData.append("sf", storefrontName)
            formData.append("logodata", selectedFile)

            httpPostFormData(apiURL, formData)
            .then((data) => {
                const storefrontResponse = data as StorefrontResponse;
                
                if (storefrontResponse.status == 'error') {
                    console.log("Error saving storefront logo: " + storefrontResponse.errorMessage)
                    setSnackbarMsg("Error updating logo: " + storefrontResponse.errorMessage)
                    setOpenSnackbar(true)
                } else {
                    setSnackbarMsg("Logo updated successfully!")
                    setOpenSnackbar(true)
                    setLogoRefreshDisplay("inline")
                }
            })
            .catch((error) => { 
                setSnackbarMsg(error.message)
                setOpenSnackbar(true)
            }); 
        }
    }

    const logoDarkChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files != null) {
            const selectedFile = event.target.files[0];
            // setSfLogoDark(selectedFile.name)
            // setSfLogoDarkFile(selectedFile)

            // Upload the logo
            const apiURL =  APIEndpoint(EndpointType.Storefront)
            const formData = new FormData();
            formData.append("logocolorscheme", "dark")
            formData.append("sf", storefrontName)
            formData.append("logodata", selectedFile)

            httpPostFormData(apiURL, formData)
            .then((data) => {
                const storefrontResponse = data as StorefrontResponse;
                
                if (storefrontResponse.status == 'error') {
                    console.log("Error saving storefront logo: " + storefrontResponse.errorMessage)
                    setSnackbarMsg("Error updating logo: " + storefrontResponse.errorMessage)
                    setOpenSnackbar(true)
                } else {
                    setSnackbarMsg("Logo updated successfully!")
                    setOpenSnackbar(true)
                }
            })
            .catch((error) => { 
                setSnackbarMsg(error.message)
                setOpenSnackbar(true)
            }); 
        }
    }

    // Light Mode
    const pageIconBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfPageIconBackground(event.target.value)
    }

    const pageButtonBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfPageButtonBackground(event.target.value)
    }

    const pageTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfPageText(event.target.value)
    }

    const pageMapBorderBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfPageMapBorderBackground(event.target.value)
    }

    const pageSearchBoxBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfPageSearchBoxBackground(event.target.value)
    }

    const pageSearchBoxTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfPageSearchBoxText(event.target.value)
    }

    const pageHeaderBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfHeaderBackground(event.target.value)
    }

    const pageHeaderTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfHeaderText(event.target.value)
    }

    const pageAvatarBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfAvatarBackground(event.target.value)
    }

    const pageAvatarForegroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfAvatarForeground(event.target.value)
    }

    const cartBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfCartBackground(event.target.value)
    }
    
    const cartProductBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfCartProductBackground(event.target.value)
    }

    const cartPurchaseTotalBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfCartPurchaseTotalBackground(event.target.value)
    }

    const cartTitleTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfCartTitleText(event.target.value)
    }

    const cartProductTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfCartProductText(event.target.value)
    }

    const cartPurchaseTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfCartPurchaseText(event.target.value)
    }

    const cartIconBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfCartIconBackground(event.target.value)
    }

    const cartButtonBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfCartButtonBackground(event.target.value)
    }

    const cartPurchaseProgressBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfCartPurchaseProgressBackground(event.target.value)
    }

    const cartProductRemoveIconBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfCartProductRemoveIcon(event.target.value)
    }

    const AddProdButtonBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfAddProdButtonBackground(event.target.value)
    }

    const AddProdFormBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfAddProdFormBackground(event.target.value)
    }

    const AddProdTitleTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfAddProdTitleText(event.target.value)
    }

    const AddProdProductTitleTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfAddProdProductTitleText(event.target.value)
    }

    const AddProdProductTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfAddProdProductText(event.target.value)
    }

    const AddProdButtonTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfAddProdButtonText(event.target.value)
    }

    const AddProdIconForegroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfAddProdIconForeground(event.target.value)
    }

    const LeftMenuDividerChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfLeftMenuDivider(event.target.value)
    }

    const LeftMenuIconhanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfLeftMenuIcon(event.target.value)
    }

    const PageFooterTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfPageFooterText(event.target.value)
    }

    // Dark Mode
    const pageDarkIconBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkPageIconBackground(event.target.value)
    }

    const pageDarkButtonBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkPageButtonBackground(event.target.value)
    }

    const pageDarkTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkPageText(event.target.value)
    }

    const pageDarkMapBorderBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkPageMapBorderBackground(event.target.value)
    }

    const pageDarkSearchBoxBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkPageSearchBoxBackground(event.target.value)
    }

    const pageDarkSearchBoxTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkPageSearchBoxText(event.target.value)
    }

    const pageDarkHeaderBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkHeaderBackground(event.target.value)
    }

    const pageDarkHeaderTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkHeaderText(event.target.value)
    }

    const pageDarkAvatarBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkAvatarBackground(event.target.value)
    }

    const pageDarkAvatarForegroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkAvatarForeground(event.target.value)
    }

    const cartDarkBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkCartBackground(event.target.value)
    }
    
    const cartDarkProductBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkCartProductBackground(event.target.value)
    }

    const cartDarkPurchaseTotalBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkCartPurchaseTotalBackground(event.target.value)
    }

    const cartDarkTitleTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkCartTitleText(event.target.value)
    }

    const cartDarkProductTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkCartProductText(event.target.value)
    }

    const cartDarkPurchaseTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkCartPurchaseText(event.target.value)
    }

    const cartDarkIconBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkCartIconBackground(event.target.value)
    }

    const cartDarkButtonBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkCartButtonBackground(event.target.value)
    }

    const cartDarkPurchaseProgressBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkCartPurchaseProgressBackground(event.target.value)
    }

    const cartDarkProductRemoveIconBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkCartProductRemoveIcon(event.target.value)
    }

    const AddDarkProdButtonBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkAddProdButtonBackground(event.target.value)
    }

    const AddDarkProdFormBackgroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkAddProdFormBackground(event.target.value)
    }

    const AddDarkProdTitleTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkAddProdTitleText(event.target.value)
    }

    const AddDarkProdProductTitleTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkAddProdProductTitleText(event.target.value)
    }

    const AddDarkProdProductTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkAddProdProductText(event.target.value)
    }

    const AddDarkProdButtonTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkAddProdButtonText(event.target.value)
    }

    const AddDarkProdIconForegroundChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkAddProdIconForeground(event.target.value)
    }

    const LeftDarkMenuDividerChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkLeftMenuDivider(event.target.value)
    }

    const LeftDarkMenuIconhanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkLeftMenuIcon(event.target.value)
    }

    const PageDarkFooterTextChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setSfDarkPageFooterText(event.target.value)
    }

    function storefrontValueCopy(): Storefront {
        const lightAddProductForm: AddProductForm = {
            formBackground: sfAddProdFormBackground,
            titleText: sfAddProdTitleText,
            productTitleText: sfAddProdProductTitleText,
            productText: sfAddProdProductText,
            iconForeground: sfAddProdIconForeground,
            buttonText: sfAddProdButtonText,
            buttonBackground: sfAddProdButtonBackground
        }

        const darkAddProductForm: AddProductForm = {
            formBackground: sfDarkAddProdFormBackground,
            titleText: sfDarkAddProdTitleText,
            productTitleText: sfDarkAddProdProductTitleText,
            productText: sfDarkAddProdProductText,
            iconForeground: sfDarkAddProdIconForeground,
            buttonText: sfDarkAddProdButtonText,
            buttonBackground: sfDarkAddProdButtonBackground
        }

        const lightModeCart: Cart = {
            cartBackground: sfCartBackground,
            cartProductBackground: sfCartProductBackground,
            cartPurchaseTotalBackground: sfCartPurchaseTotalBackground,
            cartTitleText: sfCartTitleText,
            cartProductText: sfCartProductText,
            cartProductRemoveIcon: sfCartProductRemoveIcon,
            cartPurchaseText: sfCartPurchaseText,
            cartIconBackground: sfCartIconBackground,
            cartButtonBackground: sfCartButtonBackground,
            cartPurchaseProgressBackground: sfCartPurchaseProgressBackground,
        }

        const darkModeCart: Cart = {
            cartBackground: sfDarkCartBackground,
            cartProductBackground: sfDarkCartProductBackground,
            cartPurchaseTotalBackground: sfDarkCartPurchaseTotalBackground,
            cartTitleText: sfDarkCartTitleText,
            cartProductText: sfDarkCartProductText,
            cartProductRemoveIcon: sfDarkCartProductRemoveIcon,
            cartPurchaseText: sfDarkCartPurchaseText,
            cartIconBackground: sfDarkCartIconBackground,
            cartButtonBackground: sfDarkCartButtonBackground,
            cartPurchaseProgressBackground: sfDarkCartPurchaseProgressBackground,
        }

        const lightMainPage: MainPage = {
            pageButtonBackground: sfPageButtonBackground,
            pageIconBackground: sfPageIconBackground,
            pageText: sfPageText,
            pageMapBorderBackground: sfPageMapBorderBackground,
            pageSearchBoxBackground: sfPageSearchBoxBackground,
            pageSearchBoxText: sfPageSearchBoxText,
            headerBackground: sfHeaderBackground,
            headerText: sfHeaderText,
            avatarBackground: sfAvatarBackground,
            avatarForeground: sfAvatarForeground
        }

        const darkMainPage: MainPage = {
            pageButtonBackground: sfDarkPageButtonBackground,
            pageIconBackground: sfDarkPageIconBackground,
            pageText: sfDarkPageText,
            pageMapBorderBackground: sfDarkPageMapBorderBackground,
            pageSearchBoxBackground: sfDarkPageSearchBoxBackground,
            pageSearchBoxText: sfDarkPageSearchBoxText,
            headerBackground: sfDarkHeaderBackground,
            headerText: sfDarkHeaderText,
            avatarBackground: sfDarkAvatarBackground,
            avatarForeground: sfDarkAvatarForeground
        }

        const lightPageFooter: PageFooter = {
            text: sfPageFooterText
        }

        const darkPageFooter: PageFooter = {
            text: sfDarkPageFooterText
        }

        const lightLeftMenu: LeftMenu = {
            icon: sfLeftMenuIcon,
            divider: sfLeftMenuDivider
        }

        const darkLeftMenu: LeftMenu = {
            icon: sfDarkLeftMenuIcon,
            divider: sfDarkLeftMenuDivider
        }

        const newStorefront: Storefront = {
            id: sfID,
            storeName: props.storefront.storeName,
            title: sfTitle,
            createdAt: sfCreatedAt,
            modifiedAt: sfModifiedAt,
            emailSignatureHTML: emailSignatureHTML,
            companyID: props.storefront.companyID,
            products: products,
            lookupWalletCredits: 5,
            minOrderAmount: sfMinimumOrderAmount,
            lightMode: {
                mainPage: lightMainPage,
                cart: lightModeCart,
                addProductForm: lightAddProductForm,
                pageFooter: lightPageFooter,
                leftMenu:lightLeftMenu,
                logo: sfLogo
            },
            darkMode: {
                mainPage: darkMainPage,
                cart: darkModeCart,
                addProductForm: darkAddProductForm,
                pageFooter: darkPageFooter,
                leftMenu:darkLeftMenu,
                logo: sfLogo, // TODO: Change this
            },
            activeColors:  {
                mainPage: lightMainPage,
                cart: lightModeCart,
                addProductForm: lightAddProductForm,
                pageFooter: lightPageFooter,
                leftMenu:lightLeftMenu,
                logo: sfLogo
            },
        }

        if (newStorefront.id === '') {
            newStorefront.id = genUniqueId();
            setSfID(newStorefront.id)
        }
        
        return newStorefront
    }

    const saveStorefront = () => {
        const sfNew = storefrontValueCopy()

        if (sfMinimumOrderAmount < 1) {
            setSnackbarMsg("The minimum order amount cannot be less than $200")
            setOpenSnackbar(true)
            return
        }

        if (storefrontName == "mystore") {
            setSnackbarMsg("Cannot save at this time. Please refresh the page and try again.")
            setOpenSnackbar(true)
            return
        }

        const apiURL =  APIEndpoint(EndpointType.Storefront)
        httpPost(apiURL, JSON.stringify(sfNew))
        .then((data) => {
            const storefrontResponse = data as StorefrontResponse;
            
            if (storefrontResponse.status == 'error') {
                console.log("Error saving storefront: " + storefrontResponse.errorMessage)
                setSnackbarMsg("Error saving storefront: " + storefrontResponse.errorMessage)
                setOpenSnackbar(true)
            } else {
                setSnackbarMsg("Storefront saved successfully!")
                setOpenSnackbar(true)
            }
        })
        .catch((error) => { 
            setSnackbarMsg(error.message)
            setOpenSnackbar(true)
        });    
    }

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenSnackbar(false);
    };

    const handleIconRefresh = () => {
        setSfLogoLightURL("")
        setSfLogoDarkURL("")

        setTimeout(function () {
            getlogoURLs()
        }, 1000);
        
    };

    const getStoreURL = () => {
        let url = "https://shop.theshare.group/shop?sf=" + props.storefront.storeName

        if (environment == "dev") {
            url = "https://" + environment + ".portal.theshare.group/shop?sf=" + props.storefront.storeName
        }
        return url
    }

    const getlogoURLs = () => {
        if (sfLogo == "") {
            if (environment == "prod") {
                setSfLogoLightURL("https://shop.theshare.group/storefront/your_logo_light.png")
                setSfLogoDarkURL("https://shop.theshare.group/storefront/your_logo_dark.png")
            } else {
                setSfLogoLightURL("https://" + environment + ".portal.theshare.group/storefront/your_logo_light.png")
                setSfLogoDarkURL("https://" + environment + ".portal.theshare.group/storefront/your_logo_dark.png")
            }
        } else {
            if (environment == "prod") {
                setSfLogoLightURL("https://shop.theshare.group/storefront/" + props.storefront.storeName + "_logo_light.png")
                setSfLogoDarkURL("https://shop.theshare.group/storefront/" + props.storefront.storeName + "_logo_dark.png")
            } else {
                setSfLogoLightURL("https://" + environment + ".portal.theshare.group/storefront/" + props.storefront.storeName + "_logo_light.png")
                setSfLogoDarkURL("https://" + environment + ".portal.theshare.group/storefront/" + props.storefront.storeName + "_logo_dark.png")
            }
        }
    }

    const snackbarAction = (
        <React.Fragment>
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
        </React.Fragment>
    );

    const handleLogoChangeClick = () => {
        hiddenFileInputRef.current && hiddenFileInputRef.current.click()
    };

    const handleLogoDarkChangeClick = () => {
        hiddenFileInputRefDark.current && hiddenFileInputRefDark.current.click()
    };

    return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Title>Store Manager</Title>
            <Grid item xs={12}>
                <Stack>
                    <Stack>
                        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", marginBottom:2}}>
                            <Stack spacing={1}>
                                <Typography sx={{width: "150px", fontWeight:'bold'}}>General Settings:</Typography>
                                <Typography sx={{marginLeft:1, marginTop:1}}>Store URL: <a className="link_darkmode" href={getStoreURL()} target="_blank">{getStoreURL()}</a></Typography>                             
                                <TextField size="small" label="Main Title" id="outlined-basic" variant="outlined" sx={{ width: 400 }} onChange={pageTitleChanged} value={sfTitle} />
                                <TextField size="small" label="Minimum Order Amount" id="outlined-basic" variant="outlined" sx={{ width: 200 }} onChange={storeMinOrderAmountChanged} value={sfMinimumOrderAmount} />
                            </Stack>
                        </Paper>

                        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", marginBottom:2}}>
                            <Stack spacing={1}>
                                <Typography sx={{width: "300px", fontWeight:'bold'}}>Main Page Color Settings:</Typography>
                                <Table sx={{ minWidth: 650 }}>
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>Light Mode</TableCell>
                                        <TableCell>Dark Mode</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row" spacing={2}>
                                                    <Typography sx={{width: "60px"}}>Logo:</Typography>
                                                    <input  onChange={logoChanged} type="file" ref={hiddenFileInputRef} className="hideme" />
                                                    <Button onClick={handleLogoChangeClick}>
                                                        <Stack>
                                                            <img src={sfLogoLightUrl} height="48" key={sfLogoLightUrl} />
                                                            <Typography sx={{fontSize:".6rem"}}>Click to Change</Typography>
                                                        </Stack>
                                                    </Button>
                                                    <Stack sx={{display:logoRefreshDisplay}}>
                                                        <Typography variant="subtitle2" sx={{width:230}}>Logo updates take 1-2 minutes to clear the CDN cache. Hit the refresh button below after 2 minutes</Typography>
                                                        <IconButton onClick={handleIconRefresh}>
                                                            <RefreshIcon />
                                                        </IconButton>
                                                    </Stack>
                                                </Stack>
                                            </TableCell>
                                            <TableCell sx={{backgroundColor:"#000000"}}>
                                                <Stack direction="row">
                                                    <Typography sx={{width: "60px"}}>Logo:</Typography>
                                                    <input  onChange={logoDarkChanged} type="file" ref={hiddenFileInputRefDark} className="hideme" />
                                                    <Button onClick={handleLogoDarkChangeClick}>
                                                        <Stack>
                                                            <img src={sfLogoDarkUrl} height="48" key={sfLogoDarkUrl} />
                                                            <Typography sx={{fontSize:".6rem"}}>Click to Change</Typography>
                                                        </Stack>
                                                    </Button>
                                                    <Stack sx={{display:logoRefreshDisplay}}>
                                                        <Typography variant="subtitle2" sx={{width:230, color:"white"}}>Logo updates take 1-2 minutes to clear the CDN cache. Hit the refresh button below after 2 minutes</Typography>
                                                        <IconButton onClick={handleIconRefresh}>
                                                            <RefreshIcon sx={{color:"white"}} />
                                                        </IconButton>
                                                    </Stack>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Stack direction="row">
                                                    <TextField size="small" label="Button Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageButtonBackgroundChanged} value={sfPageButtonBackground} />
                                                    <Chip label={sfPageButtonBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfPageButtonBackground}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Button Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageDarkButtonBackgroundChanged} value={sfDarkPageButtonBackground} />
                                                    <Chip label={sfDarkPageButtonBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkPageButtonBackground}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Map Border Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageMapBorderBackgroundChanged} value={sfPageMapBorderBackground} />
                                                    <Chip label={sfPageMapBorderBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfPageMapBorderBackground}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                            <Stack direction="row">
                                                    <TextField size="small" label="Map Border Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageDarkMapBorderBackgroundChanged} value={sfDarkPageMapBorderBackground} />
                                                    <Chip label={sfDarkPageMapBorderBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkPageMapBorderBackground}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Search Box Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageSearchBoxBackgroundChanged} value={sfPageSearchBoxBackground} />
                                                    <Chip label={sfPageSearchBoxBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfPageSearchBoxBackground}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Search Box Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageDarkSearchBoxBackgroundChanged} value={sfDarkPageSearchBoxBackground} />
                                                    <Chip label={sfDarkPageSearchBoxBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkPageSearchBoxBackground}}/>
                                                </Stack>     
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Search Box Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageSearchBoxTextChanged} value={sfPageSearchBoxText} />
                                                    <Chip label={sfPageSearchBoxText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfPageSearchBoxText}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Search Box Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageDarkSearchBoxTextChanged} value={sfDarkPageSearchBoxText} />
                                                    <Chip label={sfDarkPageSearchBoxText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkPageSearchBoxText}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Icon Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageIconBackgroundChanged} value={sfPageIconBackground} />
                                                    <Chip label={sfPageIconBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfPageIconBackground}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Icon Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageDarkIconBackgroundChanged} value={sfDarkPageIconBackground} />
                                                    <Chip label={sfDarkPageIconBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkPageIconBackground}}/>
                                                </Stack> 
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Page Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageTextChanged} value={sfPageText} />
                                                    <Chip label={sfPageText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfPageText}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Page Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageDarkTextChanged} value={sfDarkPageText} />
                                                    <Chip label={sfDarkPageText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkPageText}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Header Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageHeaderBackgroundChanged} value={sfHeaderBackground} />
                                                    <Chip label={sfHeaderBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfHeaderBackground}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Header Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageDarkHeaderBackgroundChanged} value={sfDarkHeaderBackground} />
                                                    <Chip label={sfDarkHeaderBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkHeaderBackground}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Header Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageHeaderTextChanged} value={sfHeaderText} />
                                                    <Chip label={sfHeaderText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfHeaderText}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Header Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageDarkHeaderTextChanged} value={sfDarkHeaderText} />
                                                    <Chip label={sfDarkHeaderText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkHeaderText}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Avatar Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageAvatarBackgroundChanged} value={sfAvatarBackground} />
                                                    <Chip label={sfAvatarBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfAvatarBackground}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Avatar Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageDarkAvatarBackgroundChanged} value={sfDarkAvatarBackground} />
                                                    <Chip label={sfDarkAvatarBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkAvatarBackground}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Avatar Foreground" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageAvatarForegroundChanged} value={sfAvatarForeground} />
                                                    <Chip label={sfAvatarForeground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfAvatarForeground}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Avatar Foreground" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={pageDarkAvatarForegroundChanged} value={sfDarkAvatarForeground} />
                                                    <Chip label={sfDarkAvatarForeground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkAvatarForeground}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Left Menu Icons" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={LeftMenuIconhanged} value={sfLeftMenuIcon} />
                                                    <Chip label={sfLeftMenuIcon} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfLeftMenuIcon}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Left Menu Icons" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={LeftDarkMenuIconhanged} value={sfDarkLeftMenuIcon} />
                                                    <Chip label={sfDarkLeftMenuIcon} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkLeftMenuIcon}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Left Menu Divider" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={LeftMenuDividerChanged} value={sfLeftMenuDivider} />
                                                    <Chip label={sfLeftMenuDivider} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfLeftMenuDivider}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Left Menu Divider" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={LeftDarkMenuDividerChanged} value={sfDarkLeftMenuDivider} />
                                                    <Chip label={sfDarkLeftMenuDivider} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkLeftMenuDivider}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Footer Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={PageFooterTextChanged} value={sfPageFooterText} />
                                                    <Chip label={sfPageFooterText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfPageFooterText}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Footer Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={PageDarkFooterTextChanged} value={sfDarkPageFooterText} />
                                                    <Chip label={sfDarkPageFooterText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkPageFooterText}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Stack>
                        </Paper>

                        <Paper sx={{ p: 2, display: "flex", flexDirection: "column"}}>
                            <Stack spacing={1}>
                                <Typography sx={{width: "300px", fontWeight:'bold'}}>Shopping Cart Color Settings:</Typography>
                                <Table sx={{ minWidth: 650 }}>
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>Light Mode</TableCell>
                                        <TableCell>Dark Mode</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartBackgroundChanged} value={sfCartBackground} />
                                                    <Chip label={sfCartBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfCartBackground}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartDarkBackgroundChanged} value={sfDarkCartBackground} />
                                                    <Chip label={sfDarkCartBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkCartBackground}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Icon Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartIconBackgroundChanged} value={sfCartIconBackground} />
                                                    <Chip label={sfCartIconBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfCartIconBackground}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Icon Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartDarkIconBackgroundChanged} value={sfDarkCartIconBackground} />
                                                    <Chip label={sfDarkCartIconBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkCartIconBackground}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Product Box Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartProductBackgroundChanged} value={sfCartProductBackground} />
                                                    <Chip label={sfCartProductBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfCartProductBackground}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Product Box Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartDarkProductBackgroundChanged} value={sfDarkCartProductBackground} />
                                                    <Chip label={sfDarkCartProductBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkCartProductBackground}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Product Box Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartProductTextChanged} value={sfCartProductText} />
                                                    <Chip label={sfCartProductText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfCartProductText}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Product Box Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartDarkProductTextChanged} value={sfDarkCartProductText} />
                                                    <Chip label={sfDarkCartProductText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkCartProductText}}/>
                                                </Stack> 
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Product Remove Icon" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartProductRemoveIconBackgroundChanged} value={sfCartProductRemoveIcon} />
                                                    <Chip label={sfCartProductRemoveIcon} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfCartProductRemoveIcon}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Product Remove Icon" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartDarkProductRemoveIconBackgroundChanged} value={sfDarkCartProductRemoveIcon} />
                                                    <Chip label={sfDarkCartProductRemoveIcon} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkCartProductRemoveIcon}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Purchase Box Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartPurchaseTextChanged} value={sfCartPurchaseText} />
                                                    <Chip label={sfCartPurchaseText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfCartPurchaseText}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Purchase Box Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartDarkPurchaseTextChanged} value={sfDarkCartPurchaseText} />
                                                    <Chip label={sfDarkCartPurchaseText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkCartPurchaseText}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Purchase Box Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartPurchaseTotalBackgroundChanged} value={sfCartPurchaseTotalBackground} />
                                                    <Chip label={sfCartPurchaseTotalBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfCartPurchaseTotalBackground}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Purchase Box Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartDarkPurchaseTotalBackgroundChanged} value={sfDarkCartPurchaseTotalBackground} />
                                                    <Chip label={sfDarkCartPurchaseTotalBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkCartPurchaseTotalBackground}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Title Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartTitleTextChanged} value={sfCartTitleText} />
                                                    <Chip label={sfCartTitleText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfCartTitleText}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Title Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartDarkTitleTextChanged} value={sfDarkCartTitleText} />
                                                    <Chip label={sfDarkCartTitleText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkCartTitleText}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Button Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartButtonBackgroundChanged} value={sfCartButtonBackground} />
                                                    <Chip label={sfCartButtonBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfCartButtonBackground}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Button Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartDarkButtonBackgroundChanged} value={sfDarkCartButtonBackground} />
                                                    <Chip label={sfDarkCartButtonBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkCartButtonBackground}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Progress Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartPurchaseProgressBackgroundChanged} value={sfCartPurchaseProgressBackground} />
                                                    <Chip label={sfCartPurchaseProgressBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfCartPurchaseProgressBackground}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                            <Stack direction="row">
                                                    <TextField size="small" label="Progress Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={cartDarkPurchaseProgressBackgroundChanged} value={sfDarkCartPurchaseProgressBackground} />
                                                    <Chip label={sfDarkCartPurchaseProgressBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkCartPurchaseProgressBackground}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Stack>
                        </Paper>

                        <Paper sx={{ p: 2, display: "flex", flexDirection: "column"}}>
                            <Stack spacing={1}>
                                <Typography sx={{width: "300px", fontWeight:'bold'}}>Add Product Form Colors:</Typography>
                                <Table sx={{ minWidth: 650 }}>
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>Light Mode</TableCell>
                                        <TableCell>Dark Mode</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={AddProdFormBackgroundChanged} value={sfAddProdFormBackground} />
                                                    <Chip label={sfAddProdFormBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfAddProdFormBackground}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={AddDarkProdFormBackgroundChanged} value={sfDarkAddProdFormBackground} />
                                                    <Chip label={sfDarkAddProdFormBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkAddProdFormBackground}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Title Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={AddProdTitleTextChanged} value={sfAddProdTitleText} />
                                                    <Chip label={sfAddProdTitleText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfAddProdTitleText}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Title Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={AddDarkProdTitleTextChanged} value={sfDarkAddProdTitleText} />
                                                    <Chip label={sfDarkAddProdTitleText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkAddProdTitleText}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Product Title Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={AddProdProductTitleTextChanged} value={sfAddProdProductTitleText} />
                                                    <Chip label={sfAddProdProductTitleText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfAddProdProductTitleText}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Product Title Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={AddDarkProdProductTitleTextChanged} value={sfDarkAddProdProductTitleText} />
                                                    <Chip label={sfDarkAddProdProductTitleText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkAddProdProductTitleText}}/>
                                                </Stack>       
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Product Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={AddProdProductTextChanged} value={sfAddProdProductText} />
                                                    <Chip label={sfAddProdProductText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfAddProdProductText}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Product Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={AddDarkProdProductTextChanged} value={sfDarkAddProdProductText} />
                                                    <Chip label={sfDarkAddProdProductText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkAddProdProductText}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Icon Foreground" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={AddProdIconForegroundChanged} value={sfAddProdIconForeground} />
                                                    <Chip label={sfAddProdIconForeground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfAddProdIconForeground}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Icon Foreground" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={AddDarkProdIconForegroundChanged} value={sfDarkAddProdIconForeground} />
                                                    <Chip label={sfDarkAddProdIconForeground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkAddProdIconForeground}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Button Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={AddProdButtonBackgroundChanged} value={sfAddProdButtonBackground} />
                                                    <Chip label={sfAddProdButtonBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfAddProdButtonBackground}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Button Background" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={AddDarkProdButtonBackgroundChanged} value={sfDarkAddProdButtonBackground} />
                                                    <Chip label={sfDarkAddProdButtonBackground} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkAddProdButtonBackground}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Button Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={AddProdButtonTextChanged} value={sfAddProdButtonText} />
                                                    <Chip label={sfAddProdButtonText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfAddProdButtonText}}/>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <TextField size="small" label="Button Text" id="outlined-basic" variant="outlined" sx={{ width: 200, marginLeft: "0px" }} onChange={AddDarkProdButtonTextChanged} value={sfDarkAddProdButtonText} />
                                                    <Chip label={sfDarkAddProdButtonText} sx={{marginTop:.5, marginLeft:1, backgroundColor:sfDarkAddProdButtonText}}/>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Stack>
                        </Paper>

                        <Button variant="contained" color="primary" sx={{ width: 250, marginTop:2 }} onClick={saveStorefront}>Save Storefront</Button>
                    </Stack>
                </Stack>
            </Grid>
            </Paper>
        </Grid>
        <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={snackbarMsg}
            action={snackbarAction}
        />
        </Grid>
        <Copyright sx={{ pt: 4 }} />
    </Container>
    );
};

export default StoreManager;