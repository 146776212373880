import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { Order, OrdersResponse } from '../models/OrdersResponse'

const apiURL =  APIEndpoint(EndpointType.Orders);

export const useOrder = (orderID: string, logDownload: boolean) => {
    const [order, setOrder] = useState<Order>()
    const [orderError, setOrderError] = useState("")
    const [orderLoading, setOrderLoading] = useState(false)
    
    useEffect(() => {
        if (orderID == "") {
            return
        }

        let downloadParam = ""
        if (logDownload) {
            downloadParam = "?download=true"
        }

        setOrderLoading(true)
        setOrderError("")
        httpGet(apiURL + "/" + orderID + downloadParam)
            .then((data) => {
                const ordersResponse = data as OrdersResponse;
                
                if (ordersResponse.status == "error") {
                    setOrderError(ordersResponse.errorMessage)
                    setOrder({})
                } else {
                    if (ordersResponse.orders && ordersResponse.orders.length > 0) {
                        setOrder(ordersResponse.orders[0]);
                    } else {
                        setOrder({})
                    }
                }
            })
            .catch((error) => setOrderError(error))
            .finally(() => setOrderLoading(false))

    }, [orderID]);

    return { order, orderLoading, orderError };
};