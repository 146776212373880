import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Title from '../components/Title';
import { useState, useEffect } from 'react';
import LoadingSpinner from "../components/LoadingSpinner";
import { useWalletBalance } from "../hooks/useWalletBalance";
import { useWalletTransactions } from "../hooks/useWalletTransactions";
import { Storefront } from "../models/StorefrontResponse";
import WalletTransactionList from "../components/WalletTransactionList";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from '@mui/material/IconButton';
import { WalletBalance } from "../models/Wallet";
import useAuth from "../hooks/useAuth";
import Slider from '@mui/material/Slider';
import WalletPriceOption from "../components/WalletPriceOption";
import { useNavigate } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { APIEndpoint, EndpointType, getEnvironment, httpPostNoAuth } from "../utils/apiService";
import { CartItem, ShoppingCart } from "../models/Store";
import { genUniqueId, generateOrderID } from "../utils/GenerateUniqueID";
import { CheckoutResponse } from "../models/CheckoutResponse";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useUploadErrorLog } from "../hooks/useUploadErrorLog";
import { User } from "../models/UsersResponse";
import { useConsoleLogHistory } from "../hooks/useConsoleLogHistory";

interface Props {
  storefront: Storefront
  user: User | undefined
}

const MyWallet = (props: Props) => {
  const navigate = useNavigate();
  const [balanceRefreshKey, setBalanceRefreshKey] = useState(0);
  const [transactionsRefreshKey, setTransactionsRefreshKey] = useState(0);
  const [customerID, setCustomerID] = useState("");
  const [leadsCount, setLeadsCount] = useState(5000)
  const [activePrice, setActivePrice] = useState(0)
  const [off0Active, setOff0Active] = useState(false)
  const [off10Active, setOff10Active] = useState(false)
  const [off20Active, setOff20Active] = useState(true)
  const [off30Active, setOff30Active] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState("")
  const [expanded, setExpanded] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")
  const { isAuthenticated } = useAuth();
  const { walletBalance, walletBalanceError } = useWalletBalance(customerID, balanceRefreshKey)
  const { walletTransactions, walletTransactionsLoading, walletTransactionsError } = useWalletTransactions(customerID, transactionsRefreshKey)
  const logs = useConsoleLogHistory();
  const { errorLogAcknowledged } = useUploadErrorLog("Wallet", errorMessage, customerID, logs);

  useEffect(() => {
    try {
      setCustomerID(props.user && props.user.id ? props.user.id : "")
    }
    catch (e) {
      console.log(e)
      navigate("/signin")
    }
  }, []);

  useEffect(() => {
    if (walletBalance?.balance && walletBalance?.balance < 100) {
      setExpanded(true)
    }
  }, [walletBalance]);

  useEffect(() => {
    setErrorMessage(errorMessage)
  }, [walletBalanceError]);

  useEffect(() => {
    setErrorMessage(errorMessage)
  }, [walletTransactionsError]);

  useEffect(() => {
    if (errorMessage != "") {
      console.log("errorLogSubmitted: result=" + errorLogAcknowledged + " - '" + errorMessage + "'")
    }
  }, [errorLogAcknowledged]);

  const getWalletBalance = (wb: WalletBalance | undefined) => {
    if (wb != undefined && wb.balance != undefined) {
      return wb.balance.toLocaleString() + " leads"
    }

    return "0 leads"
  }

  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleRefreshClick = () => {
    setBalanceRefreshKey(balanceRefreshKey + 1)
    setTransactionsRefreshKey(transactionsRefreshKey + 1)
  };

  const handleLeadsChange = (event: Event, newValue: number | number[]) => {
    if (newValue != null && newValue != undefined) {
      const count = newValue as number
      setLeadsCount(count);

      let off0 = false;
      let off10 = false;
      let off20 = false;
      let off30 = false;

      if (count < 2500) {
        off0 = true;
        setActivePrice(.2)
      } else if (count < 5000) {
        off10 = true;
        setActivePrice(.18)
      } else if (count < 10000) {
        off20 = true;
        setActivePrice(.16)
      } else if (count >= 10000) {
        off30 = true;
        setActivePrice(.14)
      }

      setOff0Active(off0)
      setOff10Active(off10)
      setOff20Active(off20)
      setOff30Active(off30)
    }
  };

  const getStripeProductID = () => {
    let productID = "prod_POppoGOu1yl8LX" // TODO: REPLACE WITH PRODUCT ID
    if (getEnvironment() == "dev") {
      productID = "prod_PMJjq0zyFp5QIX"
    }

    return productID
  }

  const getStripeUnitPrice = (name: string): number => {
    switch (name) {
      case "off0":
        return .20
        break;
      case "off10":
        return .18
        break;
      case "off20":
        return .16
        break;
      case "off25":
        return .15
        break;
      case "off30":
        return .14
        break;
      case "off35":
        return .13
        break;
    }
    return 0
  }

  const getStripePriceID = (name: string): string => {
    let priceID = ""

    const environment = getEnvironment()

    if (environment == "dev") {
      switch (name) {
        case "off0":
          priceID = "price_1Po4WKCxNi5DvLVrH4T6CZVJ"
          break;
        case "off10":
          priceID = "price_1OXb5gCxNi5DvLVr5AGamR7k"
          break;
        case "off20":
          priceID = "price_1OXb7PCxNi5DvLVrXDt64p92"
          break;
        case "off25":
          priceID = "price_1OXb7rCxNi5DvLVrZI5g2L57"
          break;
        case "off30":
          priceID = "price_1OXb85CxNi5DvLVro9L6Nk9I"
          break;
        case "off35":
          priceID = "price_1OXb8KCxNi5DvLVrJc3HTGgL"
          break;
      }
    } else {
      switch (name) {
        case "off0":
          priceID = "price_1Po4U6CxNi5DvLVrqM3x9NeR"
          break;
        case "off10":
          priceID = "price_1Oa2A9CxNi5DvLVrAN7cA7vX"
          break;
        case "off20":
          priceID = "price_1Oa2A9CxNi5DvLVrXezfN5H6"
          break;
        case "off25":
          priceID = "price_1Oa2A9CxNi5DvLVrlokhdP6H"
          break;
        case "off30":
          priceID = "price_1Oa2A9CxNi5DvLVrLiHA6LrU"
          break;
        case "off35":
          priceID = "price_1Oa2A9CxNi5DvLVrgBe5P3Xi"
          break;
      }
    }


    return priceID
  }

  const handlePurchase = (name: string) => {
    if (!isAuthenticated) {
      alert("It appears you are no longer authenticated. Please login again and retry the purchase.")
      navigate("/signin")
    }

    const shoppingCart: ShoppingCart = {
      cartToken: genUniqueId(),
      createdAt: "",
      modifiedAt: "",
      hubspotDealID: "",
      subtotal: activePrice * leadsCount,
      total: activePrice * leadsCount,
      discount: 0,
      couponCode: "",
      items: [],
      status: "shopping",
      userID: props.user && props.user.id ? props.user.id : "",
      storefrontID: props.storefront.storeName,
      discountDescription: "",
      orderID: generateOrderID(),
      applyWallet: false,
      walletCreditsApplied: 0
    }

    const cartItem: CartItem = {
      id: genUniqueId(),
      quantity: leadsCount,
      postalCodes: [],
      product: {
        id: "",
        name: "Wallet Recharge",
        description: "Addition of leads to wallet",
        leadType: "Add",
        productType: "wallet",
        price: getStripeUnitPrice(name),
        active: false,
        listCounts: [],
        stripeID: getStripeProductID(),
        stripePrice: getStripePriceID(name),
        createdAt: "",
        modifiedAt: "",
        groupName: "",
        infoURL: "",
        detailedDescription: "Addition of leads to wallet",
        audienceID: 0,
        outputFields: [],
        priceInfo: {
          price: getStripeUnitPrice(name),
          stripePriceID: getStripePriceID(name)
        },
        features: {
          noDNC: false,
          phonesEmails: false
        }
      }
    }

    shoppingCart.items.push(cartItem)

    if (shoppingCart.storefrontID == undefined || shoppingCart.storefrontID == "") {
      shoppingCart.storefrontID = props.storefront.storeName
    }

    if (shoppingCart.storefrontID == undefined || shoppingCart.storefrontID == "") {
      console.log("Cannot checkout with storefront! The storefront name could not be determined")
      setSnackbarMsg("Cannot checkout with storefront! The storefront name could not be determined. Are you logged-in?")
      setOpenSnackbar(true)
      return
    }

    if (isAuthenticated) {
      //if (user.username != undefined) {
        shoppingCart.userID = props.user && props.user.id ? props.user.id : ""
     // }
    } else {
      console.log("Cannot checkout without user! The user could not be determined")
      setSnackbarMsg("Cannot checkout at this time! The user could not be determined. Are you logged-in?")
      setOpenSnackbar(true)
      return
    }

    const apiURL = APIEndpoint(EndpointType.Checkout)
    httpPostNoAuth(apiURL, JSON.stringify(shoppingCart))
      .then((data) => {
        const checkoutResponse = data as CheckoutResponse;

        if (checkoutResponse.status == 'error') {
          console.log("Error checking-out: " + checkoutResponse.errorMessage)
          setSnackbarMsg("Error Checking out. " + checkoutResponse.errorMessage)
          setOpenSnackbar(true)
        } else {
          console.log("Checkout session created successfuly...redirecting to checkout page")

          // Redirect to the checkout page
          window.location.replace(checkoutResponse.redirectURL);

        }
      })
      .catch((error) => {
        console.log("saveShoppingCart: " + error.message)
      });
  }

  const snackbarAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const greenCheckCircle = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" ><path fill="#6ECC69" d="M50,2.5000033c-12.5977974,0-24.6798763,5.0045123-33.5873451,13.9126549  C7.5045118,25.3208027,2.4999995,37.4026566,2.4999995,50s5.0045123,24.6798782,13.9126549,33.5873413  C25.3207989,92.495491,37.4026566,97.5,50,97.5s24.6798782-5.004509,33.5873413-13.9126587  C92.495491,74.6791992,97.5,62.5973473,97.5,50s-5.004509-24.6798763-13.9126587-33.5873451  C74.6791992,7.5045123,62.5973473,2.5,50,2.5V2.5000033z M75.408226,31.9548092L49.0183334,68.8988495v0.000885  c-0.9021263,1.2626038-2.3139534,2.065773-3.8602066,2.1947708c-0.1475563,0.0105972-0.291584,0.0158997-0.4356041,0.0158997  c-1.4004517,0.000885-2.7425842-0.5557556-3.7312737-1.5462341L25.1577644,53.73069  c-1.3138618-1.3368416-1.8192673-3.2700691-1.3288822-5.0787048c0.4912701-1.8086586,1.904089-3.2214355,3.7127266-3.7118225  c1.8086567-0.4903831,3.7419052,0.0150223,5.0787029,1.3279991l11.4288368,11.4263458L66.8154373,25.821701  c1.0956345-1.5347633,2.9264755-2.3689137,4.8038788-2.1868305c1.8767014,0.1811314,3.5148087,1.3500977,4.2958527,3.06604  c0.7819519,1.7167568,0.5884628,3.718832-0.507164,5.2537785L75.408226,31.9548092z" /></svg>
    )
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Title>Wallet Details</Title>
            </Stack>
            <Accordion expanded={expanded}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
                onClick={() => setExpanded(!expanded)}
                sx={{ backgroundColor: "#2D3E4F" }}
              >
                <Typography sx={{ fontWeight: "bold", color: "#ffffff" }}>Add Leads to Wallet</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "#2D3E4F", color: "#ffffff" }}>
                <Box sx={{ marginTop: 0 }} textAlign="center">
                  <Box >
                    <Stack direction="row" alignItems="center" justifyContent="center">
                      <Box sx={{ mt: 1, ml: 1, width: 140, height: 138, borderRadius: "8%", borderColor: "black", backgroundColor: "#6ECC69" }}>
                        <Stack alignItems="baseline" sx={{ ml: 3, mt: 4 }}>
                          <Typography variant="h5" sx={{ color: "#2D3E4F", fontWeight: "Bold" }}>Wallet</Typography>
                          <Typography variant="h5" sx={{ color: "#2D3E4F", fontWeight: "Bold" }}>Benefits</Typography>
                        </Stack>
                      </Box>
                      <Stack sx={{ mt: 2 }}>
                        <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ mb: .5 }}>
                          <Box sx={{ width: 17, height: 17, ml: 1, mr: 1 }}>{greenCheckCircle()}</Box>
                          <Stack direction="row" spacing={1} sx={{}}>
                            <Typography variant="body2" sx={{}} align='left'>DNC & 100% Email included FREE ($100 value)</Typography>
                            <Typography variant="body2"></Typography>
                          </Stack>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ mb: .5 }}>
                          <Box sx={{ width: 17, height: 17, ml: 1, mr: 1 }}>{greenCheckCircle()}</Box>
                          <Stack direction="row" spacing={1} sx={{}}>
                            <Typography variant="body2" sx={{}} align='left'>No minimum order quantity</Typography>
                            <Typography variant="body2"></Typography>
                          </Stack>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ mb: .5 }}>
                          <Box sx={{ width: 17, height: 17, ml: 1, mr: 1 }}>{greenCheckCircle()}</Box>
                          <Stack direction="row" spacing={1} sx={{}}>
                            <Typography variant="body2">Buy leads for your team and share the cost</Typography>
                          </Stack>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ mb: .5 }}>
                          <Box sx={{ width: 17, height: 17, ml: 1, mr: 1 }}>{greenCheckCircle()}</Box>
                          <Stack direction="row" spacing={1} sx={{}}>
                            <Typography variant="body2" sx={{ fontWeight: "Bold" }} align='left'>Significant Savings:</Typography>
                            <Typography variant="body2">Unlock substantial cost savings</Typography>
                          </Stack>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ mb: .5 }}>
                          <Box sx={{ width: 17, height: 17, ml: 1, mr: 1 }}>{greenCheckCircle()}</Box>
                          <Stack direction="row" spacing={1} sx={{}}>
                            <Typography variant="body2" sx={{ fontWeight: "Bold" }} align='left'>Flexible Usage:</Typography>
                            <Typography variant="body2">Use your leads at your own pace</Typography>
                          </Stack>
                        </Stack>
                      </Stack>

                    </Stack>
                  </Box>
                  <Typography sx={{ mt: 4 }}>Use the slider below to choose your lead quantity, starting at 1,500 lead credits.</Typography>
                  <Typography sx={{ marginBottom: 6 }}>Unlock discounts up to a substantial 30% savings. The more you buy the more you save!</Typography>
                  <Slider defaultValue={5000} step={100} min={1500} max={15000} sx={{ width: 570, mb: 3, mr:2 }} aria-label="Default" valueLabelDisplay="on" onChange={handleLeadsChange} />
                  <Stack direction="row" spacing={0} alignItems="center" justifyContent="center">
                    <Grid>
                      <WalletPriceOption name="off0" title="1,500 Leads" subtitle="GETTING STARTED" leadBreakAmount="1,500+" active={off0Active} quantity={leadsCount} cpl={.2} storefront={props.storefront} purchaseClickedCallback={handlePurchase} marginRight={2} totalDials="12,500" listingsPerMonth="1-2" />
                      <WalletPriceOption name="off20" title="5,000 Leads" subtitle="** MOST POPULAR **" leadBreakAmount="5,000+" active={off20Active} quantity={leadsCount} cpl={.16} storefront={props.storefront} purchaseClickedCallback={handlePurchase} marginRight={2} totalDials="25,000" listingsPerMonth="3-5" />
                    </Grid>
                    <Grid>
                      <WalletPriceOption name="off10" title="2,500 Leads" subtitle="RAMPING UP" leadBreakAmount="2,500+" active={off10Active} quantity={leadsCount} cpl={.18} storefront={props.storefront} purchaseClickedCallback={handlePurchase} marginRight={2} totalDials="12,500" listingsPerMonth="1-2" />
                      <WalletPriceOption name="off30" title="10,000 Leads" subtitle="PROFESSIONAL/TEAM" leadBreakAmount="10,000+" active={off30Active} quantity={leadsCount} cpl={.14} storefront={props.storefront} purchaseClickedCallback={handlePurchase} totalDials="50,000" listingsPerMonth="5++" />
                    </Grid>
                  </Stack>

                </Box>
              </AccordionDetails>
            </Accordion>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }} style={{ overflow: 'auto' }}>
              <Box sx={{ marginTop: 0 }}>
                <Stack direction="row" spacing={2} alignItems="flex-start" justifyContent="space-between">
                  <Stack direction="row">
                    <Typography sx={{ fontWeight: "bold" }}>Balance:</Typography>
                    <Typography sx={{ ml: 2 }}>{getWalletBalance(walletBalance)}</Typography>
                  </Stack>
                  <IconButton size="medium" type="button" onClick={() => handleRefreshClick()} sx={{ height: 40, marginTop: 0 }}>
                    <RefreshIcon sx={{ color: props.storefront.activeColors.mainPage.pageIconBackground }} />
                  </IconButton>
                </Stack>
              </Box>
              <Box sx={{ marginTop: 1, minWidth: 460 }}>
                <Stack direction="column" spacing={0}>
                  <Typography sx={{ fontWeight: "bold" }}>Transactions:</Typography>
                  {walletTransactionsLoading
                    ? <LoadingSpinner title="Loading wallet..." textColor="#0352fc" />
                    : <WalletTransactionList transactions={walletTransactions} height={500} />
                  }
                </Stack>
              </Box>
            </Paper>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
        action={snackbarAction}
      />
    </Container>
  );
};

export default MyWallet;
