import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { Blueprint, BlueprintsResponse } from '../models/Blueprint';

const apiURL =  APIEndpoint(EndpointType.Blueprints);

export const useBlueprints = (refreshKey: number, scopes: string[]) => {
    const [blueprints, setBlueprints] = useState<Blueprint[]>()
    const [blueprintsError, setBlueprintsError] = useState("")
    const [blueprintsLoading, setBlueprintsLoading] = useState(false)
    
    useEffect(() => {
        if (refreshKey == 0) {
            return
        }
        setBlueprintsLoading(true)
        setBlueprintsError("")

        let url = apiURL;
        if (scopes && scopes.length > 0) {
            url += "?scopes=" + scopes.join(",")
        }

        httpGet(url)
            .then((data) => {
                const response = data as BlueprintsResponse;
                
                if (response.status == "error") {
                    setBlueprintsError(response.errorMessage)
                    setBlueprints(response.blueprints)
                } else {
                    setBlueprints(response.blueprints.sort((a, b) => a.name.localeCompare(b.name)));
                }
            })
            .catch((error) => setBlueprintsError(error))
            .finally(() => setBlueprintsLoading(false))

    }, [refreshKey, scopes]);

    return { blueprints, blueprintsLoading, blueprintsError };
};