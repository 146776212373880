import {useState, useEffect} from 'react';
import { httpPostNoAuth, APIEndpoint, EndpointType, getEnvironment } from '../utils/apiService'
import { ErrorLogResponse, ErrorLogRequest, ClientErrorInfo } from '../models/ErrorLog';
import useAuth from './useAuth';
import { LogMessage } from '../contexts/LogContext';
//import { Auth } from "aws-amplify";

const apiURL =  APIEndpoint(EndpointType.ErrorLog);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useUploadErrorLog = (page: string, errorMessage: string, userID: string, logs: LogMessage[]) => {
    const [errorLogAcknowledged, setErrorAcknowledged] = useState(false)
    const [errorLogError, setErrorLogError] = useState("")
    const [errorLogLoading, setErrorLogLoading] = useState(false)
    const { isAuthenticated } = useAuth();
//    const [accessToken, setAccessToken] = useState("")

    // useEffect(() => {
    //     (async () => { 
    //       try {
    //         const token = await getAuthToken()
    //         setAccessToken(token)
    //       } catch (error) {
    //         console.error(error);
    //         setAccessToken("")
    //       }
    //     })();
    // }, );

    useEffect(() => {
        if (errorMessage == "") {
            return
        }
        
        const clientErrorInfo: ClientErrorInfo = {
            userID: userID,
            page: page,
            isAuthenticated: isAuthenticated,
            token: ""
        }

        const errorLogRequest: ErrorLogRequest =  {
            errorMessage: errorMessage,
            source: "web_client",
            environment: getEnvironment(),
            clientInfo: clientErrorInfo,
            logs: logs
        }

        setErrorAcknowledged(false)
        setErrorLogLoading(true)
        setErrorLogError("")
        httpPostNoAuth(apiURL, JSON.stringify(errorLogRequest))
            .then((data) => {
                const response = data as ErrorLogResponse;
                
                if (response.status == "error") {
                    setErrorLogError(response.errorMessage)
                    setErrorAcknowledged(false)
                } else {
                    if (response.acknowledged) {
                        setErrorAcknowledged(response.acknowledged);
                    } else {
                        setErrorAcknowledged(false)
                    }
                }
            })
            .catch((error) => setErrorLogError(error))
            .finally(() => setErrorLogLoading(false))

    }, [errorMessage]);

    return { errorLogAcknowledged, errorLogError, errorLogLoading };
};

// const getAuthToken = async () => {
//     const currentSession = Auth.currentSession()
//     let accessToken = ""

//     if (currentSession == null) {
//         return "current session is null"
//     } else {
//         const token = (await currentSession).getAccessToken()
//         accessToken = token.getJwtToken()
//     }
//     return accessToken
// }