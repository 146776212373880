import { useState, useEffect } from 'react';

export function useConsoleLogHistory() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [logs, setLogs] = useState<any[]>([]);


  useEffect(() => {
    const originalConsoleLog = console.log;

    console.log = (...args) => {
      setLogs(prevLogs => [...prevLogs, args]);
      originalConsoleLog(...args);
    };

    return () => {
      console.log = originalConsoleLog;
    };
  }, []);

  return logs;
}