import {useState, useEffect} from 'react';
import { APIEndpoint, EndpointType, httpGetNoAuth } from '../utils/apiService'
import { Product, ProductsResponse } from '../models/Products';

const apiURL =  APIEndpoint(EndpointType.Products);

export const useProductsAll = (storefront: string, refreshKey: number) => {
    const [productsAll, setProducts] = useState<Product[]>([])
    const [productsAllError, setProductsError] = useState("")
    const [productsAllLoading, setProductsLoading] = useState(false)
    
    useEffect(() => {
        let url = apiURL
        if (storefront != "") {
            url += `?sf=${storefront}`
        }
        
        setProductsLoading(true)
        setProductsError("")
        httpGetNoAuth(url)
            .then((data) => {
                const productsResponse = data as ProductsResponse;
                if (productsResponse.status === 'error') {
                    setProductsError(productsResponse.errorMessage)
                    setProducts([])
                } else {
                    if (productsResponse.products != undefined) {
                        setProducts(productsResponse.products)
                    } else {
                        setProducts([])
                    }
                }
            })
            .catch((error) => setProductsError(error))
            .finally(() => setProductsLoading(false))

    }, [storefront, refreshKey]);

    return { productsAll, productsAllLoading, productsAllError };
};