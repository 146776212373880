import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import DataViewer from './DataViewer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal, Tooltip } from '@mui/material';
import { LocalTime } from '../utils/localTime';
import { QueryAPILog } from '../models/APILogs';
import React from 'react';
import { Storefront } from '../models/StorefrontResponse';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

interface Props {
  apiCallLog: QueryAPILog
  storefront: Storefront
  close(): void
}

export default function APICallDetails(props: Props) {
    const [openResponseDetails, setOpenResponseDetails] = React.useState(false);
    const [openParameterDetails, setOpenParameterDetails] = React.useState(false);

    const detailsStyle = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: '#ffffff',
        border: '1px solid #5c5c5e',
        boxShadow: 24,
        p: 1,
        height: 800,
    };

    const handleResponseDetailsClose = () => {
        setOpenResponseDetails(false);
    };

    const handleParameterDetailsClose = () => {
        setOpenParameterDetails(false);
    };

  return (
    <Grid sx={{height:600, overflow:"scroll"}}>
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
            <Typography sx={{marginLeft:.5, marginTop:-.6, color:"#000000"}} variant='h6'>API Call Details</Typography>
            <IconButton sx={{marginTop:-.5, color:"#000000"}} size="small" type="button" onClick={() => props.close() } >
                <CloseIcon />
            </IconButton>
        </Stack>
        <Grid sx={{ marginTop: 0}}>
            <Stack>
            <React.Fragment>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650, color:props.storefront.activeColors.mainPage.pageText }} size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>ID:</TableCell>
                            <TableCell>{props.apiCallLog.id}</TableCell>
                        </TableRow> 
                        <TableRow>
                            <TableCell>Call Time:</TableCell>
                            <TableCell>
                                <Tooltip title={LocalTime(props.apiCallLog.createdAt)} placement="bottom-start">
                                    <Typography variant="body2">{props.apiCallLog.createdAt}</Typography>
                                </Tooltip>
                            </TableCell>
                        </TableRow>                       
                        <TableRow>
                            <TableCell>Endpoint:</TableCell>
                            <TableCell>{props.apiCallLog.endpoint}</TableCell>
                        </TableRow> 
                        <TableRow>
                            <TableCell>Call Duration (ms):</TableCell>
                            <TableCell>{props.apiCallLog.apiDuration}</TableCell>
                        </TableRow>
                        { props.apiCallLog.clientInfo && props.apiCallLog.clientInfo.userID  &&
                            <TableRow>
                                <TableCell>User ID:</TableCell>
                                <TableCell>{props.apiCallLog.clientInfo.userID}</TableCell>
                            </TableRow>
                        }
                        { props.apiCallLog.clientInfo && props.apiCallLog.clientInfo.ipAddress &&
                            <TableRow>
                                <TableCell>Originating IP:</TableCell>
                                <TableCell>{props.apiCallLog.clientInfo.ipAddress}
                                </TableCell>
                            </TableRow>
                        }
                        { props.apiCallLog.requestID &&
                            <TableRow>
                                <TableCell>Request ID:</TableCell>
                                <TableCell>{props.apiCallLog.requestID}</TableCell>
                            </TableRow>
                        }
                        { props.apiCallLog.billingInfo && props.apiCallLog.billingInfo.listCountQuantity > 0 &&
                            <TableRow>
                                <TableCell>List Count Quantity:</TableCell>
                                <TableCell>{props.apiCallLog.billingInfo.listCountQuantity}</TableCell>
                            </TableRow>
                        }
                        { props.apiCallLog.billingInfo && props.apiCallLog.billingInfo.listOrderQuantity > 0 &&
                            <TableRow>
                                <TableCell>List Order Quantity:</TableCell>
                                <TableCell>{props.apiCallLog.billingInfo.listOrderQuantity}</TableCell>
                            </TableRow>
                        }
                        { props.apiCallLog.billingInfo && props.apiCallLog.billingInfo.listID &&
                            <TableRow>
                                <TableCell>List ID:</TableCell>
                                <TableCell>{props.apiCallLog.billingInfo.listID}</TableCell>
                            </TableRow>
                        }
                        { props.apiCallLog.queryParams && props.apiCallLog.queryParams &&
                            <TableRow>
                                <TableCell>Parameters:</TableCell>
                                <TableCell>
                                    <IconButton sx={{width:90}} onClick={() => {setOpenParameterDetails(true)}}>
                                        <ArticleOutlinedIcon />
                                        <Typography variant="body2">JSON</Typography>
                                    </IconButton>
                                    <Modal open={openParameterDetails} onClose={handleParameterDetailsClose}>
                                        <Box sx={detailsStyle} >
                                            <DataViewer title='Parameters' data={props.apiCallLog.queryParams} close={() => {setOpenParameterDetails(false)}} />
                                        </Box>
                                    </Modal>
                                </TableCell>
                            </TableRow>
                        }
                        { props.apiCallLog.response && props.apiCallLog.response.content &&
                            <TableRow>
                                <TableCell>Response Details:</TableCell>
                                <TableCell>
                                    <IconButton sx={{width:90}} onClick={() => {setOpenResponseDetails(true)}}>
                                        <ArticleOutlinedIcon />
                                        <Typography variant="body2">JSON</Typography>
                                    </IconButton>
                                    <Modal open={openResponseDetails} onClose={handleResponseDetailsClose}>
                                        <Box sx={detailsStyle} >
                                            <DataViewer title='Response Details' data={JSON.parse(props.apiCallLog.response.content)} close={() => {setOpenResponseDetails(false)}} />
                                        </Box>
                                    </Modal>
                                </TableCell>
                            </TableRow>
                        }
                        { props.apiCallLog.response && props.apiCallLog.response.contentv2 &&
                            <TableRow>
                                <TableCell>Response Details (v2):</TableCell>
                                <TableCell>
                                    <IconButton sx={{width:90}} onClick={() => {setOpenResponseDetails(true)}}>
                                        <ArticleOutlinedIcon />
                                        <Typography variant="body2">JSON</Typography>
                                    </IconButton>
                                    <Modal open={openResponseDetails} onClose={handleResponseDetailsClose}>
                                        <Box sx={detailsStyle} >
                                            <DataViewer title='Response Details' data={JSON.parse(props.apiCallLog.response.contentv2)} close={() => {setOpenResponseDetails(false)}} />
                                        </Box>
                                    </Modal>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                    </Table>
                </TableContainer>
            </React.Fragment>
            </Stack>
        </Grid>
    </Grid>
    
  );
}
