import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { OrderVisualizationResponse, VisualizationOrder } from '../models/OrderVisualizationResponse';

const apiURL =  APIEndpoint(EndpointType.MyOrders);

export const useLeadVisualizer = (customerID: string, refreshKey: number) => {
    const [visualOrders, setVisualOrders] = useState<VisualizationOrder[]>([])
    const [visualOrdersError, setError] = useState("")
    const [visualOrdersLoading, setLoading] = useState(false)
    
    useEffect(() => {
        if (!customerID) return;
        const url = apiURL + customerID + "?visualizer=true"

        setLoading(true)
        httpGet(url)
            .then((data) => {
                try {
                    const response = data as OrderVisualizationResponse;

                    if (response.status === 'error') {
                        setError(response.errorMessage)
                        setVisualOrders([])
                    } else {
                        if (response.visualizationOrders == undefined) {
                            response.visualizationOrders = []
                        } else {
                            setVisualOrders(response.visualizationOrders);
                        }
                    }
                }
                catch(error) {
                    setVisualOrders([])
                    setError("No orders were found")
                }
            })
            .catch((error) => {
                setVisualOrders([])
                setError(error)
            })
            .finally(() => setLoading(false))

    }, [customerID, refreshKey]);

    return { visualOrders, visualOrdersLoading, visualOrdersError };
};