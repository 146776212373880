import React from "react"
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export interface MessageProps {
  message: string | object;
}

export default function ErrorFallBack(props: MessageProps) {
    return (
      <Stack>
        <Stack direction='row' spacing={1}>
          <ErrorIcon color="primary" />
          <Typography sx={{fontWeight:'bold'}}>Oops! Failed to get data</Typography>
        </Stack>
        { typeof(props.message) == "string"
          ? <Typography>{props.message}</Typography>
          : <Typography>Unknown Error</Typography>
        }
        <Typography sx={{mt:1}}>If this problem persists, try refreshing the web page using one of the following procedures:</Typography>
        <Typography>Chrome Windows/Linux: Hold down Ctrl and click the Reload button</Typography>
        <Typography>Chrome Mac: Hold down Shift and click the Reload button</Typography>
        <Typography>Firefox Windows/Linux: Hold down Ctrl and Shift and then press 'R' key</Typography>
        <Typography>Firefox Mac: Hold down Shift and click the Reload button</Typography>
        <Typography>Safari: Hold down Shift and click the Reload button</Typography>
        <Typography>Edge: Hold down Ctrl and click the Reload button</Typography>
        <Typography sx={{mt:1}}>It is possible that your credentials have expired. Try logging out and then back in again.</Typography>
      </Stack>
    )
  }