export interface FileUploadResponse {
    status: string,
    requestID: string,
    timestamp: string,
    errorMessage: string,
    fileUploadDetails: FileUploadDetail[],
}


export interface FileUploadDetail {
    id: string,
    size: number,
    records: number,
    s3Uri: string,
    submittedFilename: string,
    validFormat: boolean,
    error: string,
    dataEnhancementID: string,
    fields: string[],
    lookupFields: Record<string, number>
    includeDNC: boolean,
}

export interface DataEnhancementResponse {
    status: string,
    requestID: string,
    timestamp: string,
    errorMessage: string,
    enhancements: DataEnhancement[],
}

export interface Enhancements {
    id: string,
    requestID: string,
    timestamp: string,
    submittedFilename: string,
    s3Uri: string,
    records: number,
    fields: string[],
    enhancements: string[],
}

export interface DataEnhancement {
    id: string,
    customerID: string,
    fileUploadDetails: FileUploadDetail[],
    status: string,
    enhancementResult: EnhancementResult,
    statusInfo: string,
    createdAt: string,
    modifiedAt: string,
    orderID: string
}

export interface EnhancementResult {
    processedCount: number,
    enhancedCount: number,
    nameCount: number,
    landlineCount: number,
    wirelessCount: number,
    landlineDNCCount: number,
    wirelessDNCCount: number,
    emailCount: number,
    // landEmailCount: number,
    // landWireCount: number,
    // landWireEmailCount: number,
    // wireEmailCount: number,
    errorCount: number,
    multipleResultsCount: number,
    noResultsCount: number,
    enhancementMasterS3URI: string,
    enhancementResultMatrix: EnhancementResultMatrix,
    createdAt: string,
}

export interface EnhancementResultMatrix {
    xAxisFields: string[],
    yAxisFields: string[],
    counts: number[][],
}

export enum EnhancementStatus {
    COMPLETED = "COMPLETED",
    APPROVED = "APPROVED",
    ERROR = "ERROR",
    IN_PROGRESS = "IN_PROGRESS",
    PENDING_APPROVAL = "PENDING_APPROVAL",
    NOT_STARTED = "NOT_STARTED",
    UNKNOWN = "",
}

export interface DataEnhancementTask {
    id: string,
    status: EnhancementStatus,
    progress: number,
    enhancementResult: EnhancementResult,
    createdAt: string,
    modifiedAt: string,
}
