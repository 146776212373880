import * as React from 'react';
import { AutocompleteUser, useUsers } from '../hooks/useUsers';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { User } from '../models/UsersResponse';


export default function LoginAs() {
    const [customerID, setCustomerID] = React.useState("");
    const [selectedUser, setSelectedUser] = React.useState<User>();
    const { users, autocompleteUsers } = useUsers(0);

    React.useEffect(() => {
        for (const user of users) {
            if (user.id == customerID) {
                setSelectedUser(user)
                console.log("User found: " + user.firstName + " " + user.lastName)
            }
        }
    }, [customerID]);

    const customerIDChanged = (event: React.SyntheticEvent<Element, Event>, value: AutocompleteUser | null) => {
        if (value == null) {
            setCustomerID("")
        } else {
            setCustomerID(value.id)
        }
    }

    const handleLoginAs = () => {
        if (selectedUser == null) {
            return
        }

        localStorage.setItem("loginAs", JSON.stringify(selectedUser))
        location.reload();
    }

    const handleLoginAsMe = () => {
        localStorage.setItem("loginAs", "")
        location.reload();
    }

    const loginAsButtonTitle = () => {
        if (selectedUser) {
            return "Login as " + selectedUser.firstName + " " + selectedUser.lastName
        } else {
            return "Login as"
        }
    }

    return (
        <Box sx={{ marginTop: 3, ml:3 }}>
            <Autocomplete disablePortal isOptionEqualToValue={(option: AutocompleteUser, value: AutocompleteUser) => option.id === value.id} id="existingCustomer" onChange={customerIDChanged} options={autocompleteUsers} size="small" sx={{ width: 360 }}
                renderInput={(params) => <TextField {...params} label="Customer Name" />}
            />

            <Button variant="contained" color="primary" sx={{ mt:3 }} onClick={handleLoginAs}>{loginAsButtonTitle()}</Button>
            <Button variant="contained" color="primary" sx={{ mt:3,ml:3 }} onClick={handleLoginAsMe}>Login as Me</Button>
        </Box>
    );
}
