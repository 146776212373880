import {useState, useEffect} from 'react';
import { APIEndpoint, EndpointType, httpGetNoAuth } from '../utils/apiService'
import { ProductGroupCount, ProductsCountResponse } from '../models/Products';

const apiURL =  APIEndpoint(EndpointType.Products) + "?output=counts";

export const useProductsCounts = (refreshKey: number) => {
    const [products, setProducts] = useState<ProductGroupCount[]>([])
    const [productsError, setProductsError] = useState("")
    const [productsLoading, setProductsLoading] = useState(false)

    useEffect(() => {
        setProductsLoading(true)
        setProductsError("")
        httpGetNoAuth(apiURL)
            .then((data) => {
                const productsResponse = data as ProductsCountResponse;
                if (productsResponse.status === 'error') {
                    setProductsError(productsResponse.errorMessage)
                    setProducts([])
                } else {
                    if (productsResponse.groups != undefined) {
                        setProducts(productsResponse.groups)
                    } else {
                        setProducts([])
                    }
                }
            })
            .catch((error) => setProductsError(error))
            .finally(() => setProductsLoading(false))

    }, [refreshKey]);

    return { products, productsLoading, productsError };
};