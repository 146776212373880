import * as React from 'react';
import { Typography, Stack, Button } from "@mui/material"
import { Storefront } from '../models/StorefrontResponse';
import { APIEndpoint, EndpointType, httpPut } from '../utils/apiService';
import { UsersResponse } from '../models/UsersResponse';


interface Props {
    storefront: Storefront,
    customerID: string,
    acknowledgement(acknowledged: boolean): void
}

export default function AckLookup(props: Props) {
    const cancelPressed = () => {
        props.acknowledgement(false)
    }

    const confirmButtonPressed = () => {

        const payload = {
            acknowledge: "lookup"
        }

        const apiURL =  APIEndpoint(EndpointType.Users) + "?customer-id=" + props.customerID 
        httpPut(apiURL, JSON.stringify(payload))
        .then((data) => {
            const usersResponse = data as UsersResponse;
            
            if (usersResponse.status == 'error') {
                console.log("Error saving acknowledgement for user: " + usersResponse.errorMessage)
            } else {
              console.log("Lookup Acknowledgement saved")
              props.acknowledgement(true)
            }
        })
        .catch((error) => { 
          console.error('User Ack Save Error:', error);
        });


    }


    return (
        <Stack spacing={2}>
            <Typography variant="h5" color={props.storefront.activeColors.mainPage.pageText}>Sensitive Data Acknowledgement</Typography>
            <Typography color={props.storefront.activeColors.mainPage.pageText}>The service does not constitute a "consumer reporting agency" as is defined in the Fair Credit Reporting Act ("FCRA") and the Data does not constitute "consumer reports" as defined in the FCRA. Accordingly, the Data may not be used as a factor in determining eligibility for credit, insurance, employment or another purpose in which a consumer report may be used under the FCRA.</Typography>
            <Typography color={props.storefront.activeColors.mainPage.pageText}>The Share Group does not make any representation or warranty about the accuracy of the information provided or the character or integrity of the person about whom You inquire. These records may contain inaccuracies, be incomplete or not be associated with the person in this report. The information provided should not be used as substitute for Your own due diligence.</Typography>
            <Typography color={props.storefront.activeColors.mainPage.pageText}>By clicking the button below, I acknowledge that I have read and understand these statements.</Typography>
            <Stack direction="row" spacing={2}>
                <Button variant="contained" color="primary" onClick={confirmButtonPressed}>I Understand</Button>
                <Button variant="contained" color="primary" onClick={cancelPressed}>Cancel</Button>
            </Stack>
        </Stack>
    )
}