import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Announcements from "../components/Announcements";
import * as React from "react";
import { useEffect } from "react";
import { Storefront } from "../models/StorefrontResponse";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

interface Props {
  isDarkMode: boolean,
  storefront: Storefront
}

const Home = (props: Props) => {
  useEffect(() => {
    // Log Pageview with Hubspot
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', '/home' + location.search]);
    _hsq.push(['trackPageView']);
  }, []);

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            Welcome to The Share Group's new web portal...your one-stop shop for managing your orders
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Announcements isDarkMode={props.isDarkMode} />
          </Paper>
          <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                marginTop:2
              }}
            >
            Click the 'Lead Store' to start your search.
          </Paper>
        </Grid>
      </Grid>
      {/* <Footer position="fixed" storefrontName={props.storefront.storeName}/> */}
    </Container>
  );
};

export default Home;
