import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import * as React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import Title from '../components/Title';
import LoadingSpinner from "../components/LoadingSpinner";
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { format, parse } from 'date-format-parse';
import { useOrders } from '../hooks/useOrders';
import ErrorFallBack from "../components/ErrorFallBack";
import { DataGridPremium, GridValueGetterParams, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid-premium';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Storefront } from "../models/StorefrontResponse";
import { styled } from '@mui/material/styles';
import OrderDetails from "../components/OrderDetails";
import Modal from '@mui/material/Modal';
import { FormControlLabel, Switch } from "@mui/material";
import { ShortOrder } from "../models/OrdersResponse";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

interface Props {
  storefront: Storefront
}

const Orders = (props: Props) => {
  const [startDate, setStartDate] = useState("default");
  const [endDate, setEndDate] = useState("");
  const [orderDetailsID, setOrderDetailsID] = useState("");
  const [pickerStartDate, setPickerStartDate] = useState<dayjs.Dayjs | null>(dayjs(getDefaultStartDate()));
  const [pickerEndDate, setPickerEndDate] = useState<dayjs.Dayjs | null>(dayjs(new Date()));
  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const { orders, loading, error } = useOrders(startDate, endDate, "", props.storefront.storeName, 0);
  const [filterWallet, setFilterWallet] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState<ShortOrder[]>([]);

  useEffect(() => {
    // Log Pageview with Hubspot
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', '/orders' + location.search]);
    _hsq.push(['trackPageView']);
  }, []);

  useEffect(() => {
    if (filterWallet) {
      setFilteredOrders(orders.filter(order => order.totalPrice != "$0.00"))
    } else {
      setFilteredOrders(orders)
    }
  }, [filterWallet, orders])

  function getDefaultStartDate() {
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);
    return sevenDaysAgo;
  }

  function getShortDate(dateString: string) {
    const dt = parse(dateString, 'YYYY-MM-DDTHH:mm:ss');
    return format(dt, "MM/DD/YY")
  }

  const handleRefreshClick = () => {
    if (pickerStartDate != null) {
      setStartDate(pickerStartDate.set('hour', 0).set('minute', 0).set('second', 0).format('YYYY-MM-DDTHH:mm:ss'))
    }

    if (pickerEndDate != null) {
      setEndDate(pickerEndDate.set('hour', 23).set('minute', 59).set('second', 59).format('YYYY-MM-DDTHH:mm:ss'))
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 147, renderCell: (params: GridRenderCellParams) => {
      return         <Link
          component="button"
          variant="body2"
          onClick={() => {
            setOrderDetailsID(params.value);
            setOpenOrderDetails(true);
          }}
          >
          {params.value}
        </Link>
    } },
    { field: 'orderDate', headerName: 'Date', width: 85, valueGetter: (params: GridValueGetterParams) => {
      return getShortDate(params.value)
    }, },
    { field: 'customerName', headerName: 'Name', width: 164 },
    { field: 'email', headerName: 'Email', width: 170 },
    { field: 'description', headerName: 'Description', width: 240 },
    { field: 'totalPrice', headerName: 'Amount', width: 80},
    { field: 'displayQuantity', headerName: '# Leads', width: 100},
    { field: 'orderSource', headerName: 'Order Source', width: 124 },
    { field: 'storefront', headerName: 'Storefront', width: 80 },
    { field: 'downloadURL', headerName: 'Download', width: 76, renderCell: (params: GridRenderCellParams) => {
      if (params.value && params.value == "na") {
        return <div></div>
      } else if (params.value && params.value != "") {
        return <Button href={`${params.value}`} download={"order.csv"} size="small">
        <DownloadIcon color="primary" />
      </Button>
      } else {
        return <div>Pending...</div>
      }
    }, },
  ]

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box width={100}>
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 512 640" enableBackground="new 0 0 512 512"><g><g>
            <polygon points="448,384 181.531,384 69.531,128 0,128 0,96 90.469,96 202.469,352 448,352   " fill="#797a7a"/></g>
            <path d="M288,464c0,26.5-21.5,48-48,48s-48-21.5-48-48s21.5-48,48-48S288,437.5,288,464z" fill="#797a7a"/>
            <path d="M448,464c0,26.5-21.5,48-48,48s-48-21.5-48-48s21.5-48,48-48S448,437.5,448,464z" fill="#797a7a"/><g>
              <path d="M400,0c-61.844,0-112,50.156-112,112s50.156,112,112,112s112-50.156,112-112S461.844,0,400,0z M456.563,145.938    l-22.625,22.625L400,134.625l-33.938,33.938l-22.625-22.625L377.375,112l-33.938-33.938l22.625-22.625L400,89.375l33.938-33.938    l22.625,22.625L422.625,112L456.563,145.938z" fill="#797a7a"/></g>
              <path d="M400,256c-62.5,0-115.281-40.25-135.156-96H160l64,160h224l36.938-92.313C461.063,245.25,431.844,256,400,256z" fill="#797a7a"/></g>
          </svg>
        </Box>
        <Box sx={{ mt: 1, color:"#797a7a" }}>No Orders</Box>
      </StyledGridOverlay>
    );
  }

  const generateExportFileName = () => {
    return "Orders"
  }

  const walletFilterChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilterWallet(checked)
  }

  const orderDetailsStyle = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: props.storefront.activeColors.addProductForm.formBackground,
    border: '1px solid #5c5c5e',
    boxShadow: 24,
    p: 1,
  };

  const handleOrderDetailsClose = () => setOpenOrderDetails(false);

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
         {/*Orders*/}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Title>Orders</Title>
            </Stack>
            <Stack>
              <Grid item xs={12}>
                <Box sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <FormControlLabel control={<Switch onChange={walletFilterChanged} />} label="Remove Wallet Orders" />
                    <DatePicker label="Start Date" value={dayjs(pickerStartDate)} onChange={date => setPickerStartDate(date)} sx={{marginBottom: "8px"}} />
                    <DatePicker label="End Date" value={dayjs(pickerEndDate)} onChange={date => setPickerEndDate(date)} sx={{marginBottom: "8px", marginLeft: "8px"}} />
                  </LocalizationProvider>
                  <IconButton size="medium" type="button" onClick={() => handleRefreshClick()} sx={{height:40, marginTop:1}} >
                    <RefreshIcon color="primary" />
                  </IconButton>
                </Box>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                    item
                    xs={12}
                  >
                  </Grid>
                  <Grid height={500}>
                    {loading
                      ? <LoadingSpinner title="Loading Orders..." textColor="#0352fc"/>
                      : error
                      ? <ErrorFallBack message={error} />
                      : 
                        <DataGridPremium
                          rows={filteredOrders}
                          columns={columns}
                          initialState={{
                            sorting: {
                              sortModel: [{ field: 'orderDate', sort: 'desc' }],
                            },
                          }}
                          slots={{
                            noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar
                          }}
                          slotProps={{ toolbar: { 
                            csvOptions: { fileName: generateExportFileName() }, 
                            excelOptions: { fileName: generateExportFileName() },
                            printOptions: { fileName: generateExportFileName() }
                        } }}
                        />
                      }
                    </Grid>
                </Paper>
              </Grid>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
      <Modal
          open={openOrderDetails}
          onClose={handleOrderDetailsClose}
      >
          <Box sx={orderDetailsStyle}>
              <OrderDetails orderID={orderDetailsID} showFullDetails close={() => {setOpenOrderDetails(false)}} />
          </Box>
      </Modal>
    </Container>
  );
};

export default Orders;