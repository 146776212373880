import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { Company, CompaniesResponse } from '../models/CompaniesResponse'

const apiURL =  APIEndpoint(EndpointType.Companies);

export const useCompanies = (roles: string[]) => {
    const [rolesState, setRoles] = useState<string[]>(roles)
    const [companies, setCompanies] = useState<Company[]>()
    const [companiesError, setError] = useState("")
    const [companiesLoading, setLoading] = useState(false)

    useEffect(() => {        
        //return
        console.log("Roles Change? ", roles == rolesState)
        setRoles(roles)
        setLoading(true)
        setError("")
        console.log("roles: ", rolesState)
        let url = apiURL
        if (roles.length > 0) {
            url = url + "?roles=" + rolesState.join(",")
        }

        httpGet(url)
            .then((data) => {
                const companiesResponse = data as CompaniesResponse;
                
                if (companiesResponse.status == "error") {
                    setError(companiesResponse.errorMessage)
                    return
                }
                if (companiesResponse.companies) {
                    if (companiesResponse.companies.length > 0) {
                        setCompanies(companiesResponse.companies);
                    }
                } else {
                    setCompanies([]);
                }
                
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false))

    }, [rolesState]);

    return { companies, companiesLoading, companiesError };
};